import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from "@angular/core";
import { TabNames } from "src/app/core/services/constants";
import { IndexDBService } from "src/app/core/services/indexDB.service";
import { Tab } from "src/app/core/services/interface";
import { MiddlwareService } from "src/app/core/services/middleware.service";
import { WebsocketService } from "src/app/core/services/websocket.service";

@Component({
  selector: "app-offcanvas-tab-settings",
  templateUrl: "./offcanvas-tab-settings.component.html",
  styleUrls: ["./offcanvas-tab-settings.component.scss"],
})
export class OffcanvasTabSettingsComponent implements OnInit, OnDestroy {
  constructor(
    private ws: WebsocketService,
    private mdw: MiddlwareService,
    public indexDBService: IndexDBService,
    private ref: ChangeDetectorRef
  ) { }

  @Input() data: Tab;
  @Input() mode: string;
  @Input() offcanvas_data: any[] = [];

  offCanvasSubscription: any;
  updateBuilderComponentSubscription: any;

  // default values
  colors;
  app_info;
  bg_color;
  text_color;
  burger_icon_color;
  app_name_color;
  other_text_color;
  channel_name: string;
  off_canvas_key = "tab";
  tab_id: string;
  title: string;
  hideoffcanvas: boolean;


  ngOnInit() {

    this.offCanvasSubscription = this.mdw.offCanvasContainer$.subscribe(
      (data) => {
        this.data = data
        this.OffCanvasData(data);
    });


  }
  OffCanvasData(data) {
    if (data) {
      if (data.off_canvas_key === "tab") {
        this.tab_id = data.tab.id;
        this.title = data.tab.title;
        this.hideoffcanvas = true;
        switch (data.tab.module) {
          case TabNames.CHANNEL:
            this.offcanvas_data = [
              {
                tab: "Basic",
                data: [
                  {
                    cat: "Basic",
                    show: false,
                    list: [{ tabInfo: true }],
                  },

                  {
                    cat: "Google Ads",
                    show: false,
                    list: [{ enableAds: true }],
                  },

                ],
              },

              {
                tab: "Advanced",
                data: [
                  {
                    cat: "Common Features",
                    show: false,
                    list: [{ channel_footer: true }, { channel_context_menu: true }],
                  },

                  {
                    cat: "Invitation",
                    show: false,
                    list: [{ enable_invitation_links: true }],
                  },

                ],
              },

            ];
            break;
          case TabNames.VIDEO:
            this.offcanvas_data = [
              {
                tab: "Basic",
                data: [
                  {
                    cat: "Basic",
                    show: false,
                    list: [{ tabInfo: true }, { video: true }],
                  },
                ],
              },
            ];
            break;
          case TabNames.FEED:
            this.offcanvas_data = [
              {
                tab: "Basic",
                data: [
                  {
                    cat: "Basic",
                    show: false,
                    list: [{ tabInfo: true }, { chatLabel: true },{ postFooter: true }, { stickyPost: true }, { postTags: true }, { chatWithAdmin: true }, { chatWithAdminIcon: true }, { contextMenu: true }],
                  },

                ],
              },
            ];
            break;
          case TabNames.CHANNELLIST:
            this.offcanvas_data = [
              {
                tab: "Basic",
                data: [
                  {
                    cat: "Basic",
                    show: false,
                    list: [{ tabInfo: true }, { enableAds: true }],
                  },

                ],
              },
            ];
            break;
          case TabNames.ONLINECHANNEL:
            this.offcanvas_data = [
              {
                tab: "Basic",
                data: [
                  {
                    cat: "Basic",
                    show: false,
                    list: [{ tabInfo: true }, { enableAds: true }],
                  },

                ],
              },
            ];
            break;
          case TabNames.CHAT:
            this.offcanvas_data = [
              {
                tab: "Basic",
                data: [
                  {
                    cat: "Basic",
                    show: false,
                    list: [{ tabInfo: true }, { enableAds: true }, { enableVideoCall: true }, { bubble_theme: true }],
                  },

                ],
              },
            ];
            break;
          case TabNames.SPEEDDIALER:
            this.offcanvas_data = [
              {
                tab: "Basic",
                data: [
                  {
                    cat: "Basic",
                    show: false,
                    list: [{ tabInfo: true }],
                  },

                ],
              },
            ];
            break;
          case TabNames.CALL_LOG:
            this.offcanvas_data = [
              {
                tab: "Basic",
                data: [
                  {
                    cat: "Basic",
                    show: false,
                    list: [{ tabInfo: true }],
                  },

                ],
              },
            ];
            break;
          case TabNames.CONTACT:
            this.offcanvas_data = [
              {
                tab: "Basic",
                data: [
                  {
                    cat: "Basic",
                    show: false,
                    list: [{ tabInfo: true }, { invitationLink: true }, { enableAds: true }],
                  },

                ],
              },
            ];
            break;
          case TabNames.OPENBOT:
            this.offcanvas_data = [
              {
                tab: "Basic",
                data: [
                  {
                    cat: "Basic",
                    show: false,
                    list: [{ tabInfo: true }, { enableSetBot: true }],
                  },

                ],
              },
            ];
            break;
          case TabNames.GROUP:
            this.offcanvas_data = [
              {
                tab: "Basic",
                data: [
                  {
                    cat: "Basic",
                    show: false,
                    list: [{ tabInfo: true }, { enableAds: true }, { enable_invitation_links: true }, {enable_public_group: true}],
                  },

                ],
              },
            ];
            break;
          case TabNames.ONLINEGROUP:
            this.offcanvas_data = [
              {
                tab: "Basic",
                data: [
                  {
                    cat: "Basic",
                    show: false,
                    list: [{ tabInfo: true }, { enableAds: true }, { enable_invitation_links: true }],
                  },

                ],
              },
            ];
            break;
          case TabNames.OPENGROUP:
            this.offcanvas_data = [
              {
                tab: "Basic",
                data: [
                  {
                    cat: "Basic",
                    show: false,
                    list: [{ tabInfo: true }, { enableSetGroup: true }],
                  },

                ],
              },
            ];
            break;
          case TabNames.ORDERLIST:
            this.offcanvas_data = [
              {
                tab: "Basic",
                data: [
                  {
                    cat: "Basic",
                    show: false,
                    list: [{ tabInfo: true }],
                  },

                ],
              },
            ];
            break;
          case TabNames.BOOKING:
            this.offcanvas_data = [
              {
                tab: "Basic",
                data: [
                  {
                    cat: "Basic",
                    show: false,
                    list: [{ tabInfo: true }, { enableAds: true }, { enable_invitation_links: true }],
                  },

                ],
              },
            ];
            break;
          case TabNames.BOOKINGLIST:
            this.offcanvas_data = [
              {
                tab: "Basic",
                data: [
                  {
                    cat: "Basic",
                    show: false,
                    list: [{ tabInfo: true }, { enableAds: true }, { enable_invitation_links: true }],
                  },

                ],
              },
            ];
            break;
          case TabNames.QUEUINGLIST:
            this.offcanvas_data = [
              {
                tab: "Basic",
                data: [
                  {
                    cat: "Basic",
                    show: false,
                    list: [{ tabInfo: true }],
                  },

                ],
              },
            ];
            break;
          case TabNames.QR_PAGE:
            this.offcanvas_data = [
              {
                tab: "Basic",
                data: [
                  {
                    cat: "Basic",
                    show: false,
                    list: [{ tabInfo: true }],
                  },

                ],
              },
            ];
            break
          case TabNames.EVENT:
            this.offcanvas_data = [
              {
                tab: "Basic",
                data: [
                  {
                    cat: "Basic",
                    show: false,
                    list: [{ tabInfo: true }, { enable_invitation_links: true }],
                  },

                ],
              },
            ];
            break
          case TabNames.EVENTLIST:
            this.offcanvas_data = [
              {
                tab: "Basic",
                data: [
                  {
                    cat: "Basic",
                    show: false,
                    list: [{ tabInfo: true }, { enable_invitation_links: true }],
                  },

                ],
              },
            ];
            break
          case TabNames.CALENDAR:
            this.offcanvas_data = [
              {
                tab: "Basic",
                data: [
                  {
                    cat: "Basic",
                    show: false,
                    list: [{ tabInfo: true }, { enable_invitation_links: true }],
                  },

                ],
              },
            ];
            break
          case TabNames.INVITATION:
            this.offcanvas_data = [
              {
                tab: "Basic",
                data: [
                  {
                    cat: "Basic",
                    show: false,
                    list: [{ tabInfo: true }],
                  },

                ],
              },
            ];
            break
          case TabNames.QR:
            this.offcanvas_data = [
              {
                tab: "Basic",
                data: [
                  {
                    cat: "Basic",
                    show: false,
                    list: [{ tabInfo: true }, { qr: true }],
                  },

                ],
              },
            ];
            break
          case TabNames.WEBVIEW:
            this.offcanvas_data = [
              {
                tab: "Basic",
                data: [
                  {
                    cat: "Basic",
                    show: false,
                    list: [{ tabInfo: true }, { webView: true }],
                  },

                ],
              },
            ];
            break
          case TabNames.WEBVIEW:
            this.offcanvas_data = [
              {
                tab: "Basic",
                data: [
                  {
                    cat: "Basic",
                    show: false,
                    list: [{ tabInfo: true }, { webView: true }],
                  },

                ],
              },
            ];
            break
          case TabNames.MAP_SEARCH:
            this.offcanvas_data = [
              {
                tab: "Basic",
                data: [
                  {
                    cat: "Basic",
                    show: false,
                    list: [{ tabInfo: true }, { mapSearch: true }],
                  },

                ],
              },
            ];
            break
          case TabNames.SEARCH:
            this.offcanvas_data = [
              {
                tab: "Basic",
                data: [
                  {
                    cat: "Basic",
                    show: false,
                    list: [{ tabInfo: true }, { search: true }],
                  },

                ],
              },
            ];
            break;
          case TabNames.STORE:
            this.offcanvas_data = [
              {
                tab: "Basic",
                data: [
                  {
                    cat: "Basic",
                    show: false,
                    list: [{ tabInfo: true }],
                  },
                ],
              },
            ];
            break;
          case TabNames.MENU:
          this.offcanvas_data = [
            {
              tab: "Basic",
              data: [
                {
                  cat: "Basic",
                  show: false,
                  list: [{ tabInfo: true }],
                },
              ],
            },
          ];
          break;
          case TabNames.POLL:
            this.offcanvas_data = [
              {
                tab: "Basic",
                data: [
                  {
                    cat: "Basic",
                    show: false,
                    list: [{ tabPollInfo: true }],
                  },
                ],
              },
            ];
            break;
            default: {
              this.hideoffcanvas = true;
            }
                 break;


        }
        if (this.hideoffcanvas && data.show){
            this.showOffcanvas();
            this.hideoffcanvas = false;
            } else {
            this.hideOffcanvas();
            }
        this.off_canvas_key = data.off_canvas_key;

      } else {
        this.hideOffcanvas();
      }
    }
  }


  drag(event: any, i: any) {
    let data = i;
    switch (this.off_canvas_key) {
      case "home":
      case "side":
      case "store":
      case "poll":
        event.dataTransfer.setData("module", data.module);
        break;
      case "menu":
      case "page":
      case "store":
      case "poll":
        event.dataTransfer.setData("button", JSON.stringify(data));
        break;
    }
  }

  onClick(item) {
    let data = item;
    switch (this.off_canvas_key) {
      case "home":
      case "side":
      case "store":
      case "poll":
        this.mdw._offCanvasResponseContainer.next({
          type: this.off_canvas_key,
          item: data.module,
        });
        break;
      case "menu":
      case "page":
      case "store":
      case "poll":
        this.mdw._offCanvasResponseContainer.next({
          type: this.off_canvas_key,
          item: JSON.stringify(data),
        });
        break;
    }
  }

  hideOffcanvas() {
    document
      .querySelector("#offcanvasScrollingTabsConf")
      .classList.remove("show");
  }

  showOffcanvas() {
    document.querySelector("#offcanvasScrollingTabsConf").classList.add("show");
  }

  ngOnDestroy(): void {
    if (this.offCanvasSubscription) {
      this.offCanvasSubscription.unsubscribe();
    }
  }
}
