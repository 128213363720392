import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { c1, c2, c7 } from "src/app/core/services/constants";
import { IndexDBService } from "src/app/core/services/indexDB.service";
import { Tab, colorSchema } from "src/app/core/services/interface";
import { MiddlwareService } from "src/app/core/services/middleware.service";
import { WebsocketService } from "src/app/core/services/websocket.service";
import { BuilderService } from "src/app/sharedservices/builder.service";

@Component({
  selector: "app-brand-bar",
  templateUrl: "./brand-bar.component.html",
  styleUrls: ["./brand-bar.component.scss"],
})
export class BrandBarComponent implements OnInit, OnDestroy {
  constructor(
    private ws: WebsocketService,
    private mdw: MiddlwareService,
    private builderService: BuilderService,
    public indexDBService: IndexDBService
  ) { }

  @Input() data: Tab;
  @Input() mode: string;
  @Input() fontResize = false;
  @Input() fontSmaller = false;

  updateBuilderComponentSubscribtion: any;

  // default values
  colors: any = {};
  app_info: any = {};
  bg_color: string;
  text_color: string;
  burger_icon_color: string;
  app_name_color: string;
  other_text_color: string;
  channel_name: string;
  isAndroid: string;
  c1_android =c1.ANDRIOD;
  c1_ios =c1.IOS;
  theme: any;
  isLarge: string;
  c7_Large: string = c7.LARGE;
  currentTabSubscription: any;
  colorsComponentSubscription: any;
  sortedTabList: any =[];
  tabList: any;
  cat: string;
  current_tab_id: any;
  makeItSmaller: boolean;
  title: string;
  isComponent: boolean;
  active_title_color: string;
  default_color: string;
  active_icon_color: string;
  isback: boolean;
  color_schema: any;
  color_schema_ios: any;
  light: boolean = true;


  ngOnInit() {


    this.updateBuilderComponentSubscribtion =
      this.mdw.updateBuilderComponentContainer$.subscribe((data) => {
        try {
          this.brandBarInitialize(data)
        } catch (error) {
          if (localStorage.getItem("ndbx")) {
            setTimeout(async () => {
              this.brandBarInitialize(data)
            }, 2000);
          } else {
            this.brandBarInitialize(data)
          }
        }

      });
      this.colorsComponentSubscription = this.mdw.colorsComponentContainer$.subscribe(
        (data) => {
          if (data  && data.color_schema  && data.color_schema_ios && data.light !=null)  {
            this.colors = data;
            this.light = data.light
            this.color_schema = this.light? this.colors.color_schema.schemes.light:this.colors.color_schema.schemes.dark ;
            this.color_schema_ios = this.light? this.colors.color_schema_ios.schemes.light:  this.colors.color_schema_ios.schemes.dark

            if (this.colors.color_schema.customColors && this.colors.color_schema.customColors['app_name']){
              this.app_name_color = this.light? this.colors.color_schema.customColors['app_name'].light.color :this.colors.color_schema.customColors['app_name'].dark.color
            }else {
              this.app_name_color = null;
            }

            this.colorInitialiaze()

          }
        })

      this.currentTabSubscription = this.mdw.currentTabContainer$.subscribe(
        (data) => {
          if (data && data["current_tab"]) {
            this.data = data;
            if (data["current_tab"].id!=="0"){
              this.title = data ["current_tab"].title;
            }else {
              this.title = ""
            }
            this.isLarge = localStorage.getItem("mode")[6];
          }
        })
  }
  brandBarInitialize(data) {
   if (data) {
     const appHome = this.indexDBService.getItem("app", "home");
     appHome.onsuccess = (event) => {
       if (appHome.result) {
         this.colors.color_schema = appHome.result.app.tabs.color_schema;
         this.colors.color_schema_ios = appHome.result.app.tabs.color_schema_ios;
         this.color_schema = this.light? this.colors.color_schema.schemes.light:this.colors.color_schema.schemes.dark ;
         this.color_schema_ios = this.light? this.colors.color_schema_ios.schemes.light:  this.colors.color_schema_ios.schemes.dark

         if (this.colors.color_schema.customColors && this.colors.color_schema.customColors['app_name']){
          this.app_name_color = this.light? this.colors.color_schema.customColors['app_name'].light.color :this.colors.color_schema.customColors['app_name'].dark.color
        }else {
          this.app_name_color = null;
        }

         const appInfo = this.indexDBService.getItem("item", "app_info");
         appInfo.onsuccess = (event) => {
           if (appInfo.result) {
             this.app_info = appInfo.result;
             this.channel_name = appInfo.result.channel_name;
           }
         }

         this.colorInitialiaze()

      }
     };
    }

  }


  colorInitialiaze(){
         // c2: full, home, side, component
      // c7: ios home - standard and large title
      this.isAndroid = localStorage.getItem("mode")[0];
      this.isLarge = localStorage.getItem("mode")[6];
      this.isComponent = localStorage.getItem("mode")[1]==c2.COMPONENT?true: false;
      this.theme = this.mdw.mobileTheme()
      this.isback = this.theme.backNavComponent

      if (this.isAndroid === c1.ANDRIOD) {
        this.bg_color = this.color_schema.m2ASurface;
        this.app_name_color= this.app_name_color? this.app_name_color : this.color_schema.m2OnSurface;
        this.burger_icon_color = this.color_schema.m2OnSurface;
        this.other_text_color = this.color_schema.m2OnSurface;
      } else if (this.isAndroid === c1.IOS) {
        this.bg_color = this.color_schema_ios.surface;
        this.app_name_color= this.app_name_color? this.app_name_color : this.color_schema.onSurface;
        this.burger_icon_color = this.color_schema_ios.onSurface;
        this.other_text_color = this.color_schema_ios.onSurface;
      }
  }
  get bgColor() {
    if (this.bg_color) {
      return {
        background: this.bg_color,
      };
    }
  }
  get otherTextColor() {
    if (this.other_text_color) {
      return {
        color: this.other_text_color,
      };
    }
  }
  get appNameColor() {
    if (this.app_name_color) {
      return {color: this.app_name_color,};
    }
  }

  get TopBarTextColor() {
     {
      return {
        color: this.color_schema_ios.primary,
      };
    }
  }

  get burgerColor() {
    if (this.burger_icon_color) {
      return {
        color: this.burger_icon_color,
      };
    }
  }

  ngOnDestroy(): void {
    if (this.updateBuilderComponentSubscribtion) {
      this.updateBuilderComponentSubscribtion.unsubscribe();
    }

    if (this.colorsComponentSubscription) {
      this.colorsComponentSubscription.unsubscribe();
    }
  }
}
