// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  ws_url: "wss://d1.nandbox.net:5080/nandbox/api/",
  http_url: "https://d1.nandbox.net:5080/nandbox/business/",
  http_url_index: "https://d1.nandbox.net:4080/nandbox/index/",
  client_id:
    "446452655386-qbhsrii2dh23jcr54k5qoc36f588f6vj.apps.googleusercontent.com",
  paypal_client_id:
    "AfpMA2EWdVxcpUWwGBjl1M0ijCyF1MKChTgM6vIzlpyVcLaLx1fhM_juQi-uj0diLUFofQS_Wg9ac9gc",
  http_upload_url: "https://d1.nandbox.net:5080/nandbox/upload/uploadedImage",
  http_upload_msg_url: "https://d1.nandbox.net:5080/nandbox/upload/",
  http_download_url: "https://d1.nandbox.net:5080/nandbox/download/",
  http_featch_url: "https://d1.nandbox.net:5080/nandbox/metadata/?url=",
  local_db: "ndbx",
  local_db_version: 2,
  map_api_key: "AIzaSyDYC0eyYdnbU9rgIKqT35Bb237WBhPkDZY",
  go_url: "https://go.ndbx.me",
  page_url: 'https://dpage.ndbx.me/',
  newpage_url: 'http://localhost:4200/hub',

  index_account_id: '4512624418834818552',
  index_key: 'LKD%sINDEXf%^9sd',
  index_msisdn: 'nandboxweb',
  smtp_api_link: 'https://go.ndbx.me/validate-smtp',
  stripeKey: 'pk_test_lbpZsa8KnyGfxQ7ekKX8UCCc00HnDbMVoz',

  dl: 'https://ddl.ndbx.me/',
  share_link: 'https://ds.ndbx.me/',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
