import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { TabNames, offcanvasColors } from "src/app/core/services/constants";
import { IndexDBService } from "src/app/core/services/indexDB.service";
import { Tab } from "src/app/core/services/interface";
import { MiddlwareService } from "src/app/core/services/middleware.service";
import { WebsocketService } from "src/app/core/services/websocket.service";

@Component({
  selector: "app-tab-sticky-post-component",
  templateUrl: "./tab-sticky-post-component.component.html",
  styleUrls: ["./tab-sticky-post-component.component.scss"],
})
export class TabStickyPostComponentComponent implements OnInit, OnDestroy {
  constructor(
    private ws: WebsocketService,
    private mdw: MiddlwareService,
    public indexDBService: IndexDBService
  ) {}

  @Input() tab_id: string;
  tab: Tab;
  mode: string;

  // default values
  imageLg: string;
  imageSm: string;
  image: string;
  icon: string;
  icon_ios: string;
  title: string;
  roundedBottom = true;
  offCanvasSubscription: any;
  enableStickyPost = false;
  textColor = "#ffffff";
  stickyBgColor = "#ff2121";
  offcanvasColors = offcanvasColors;
  newTab = false;

  ngOnInit() {
    this.offCanvasSubscription = this.mdw.offCanvasContainer$.subscribe(
      (data) => {
        if (
          data &&
          data.off_canvas_key === "tab" &&
          data.tab.id == this.tab_id &&
          data.tab.post
        ) {
          this.tab = { ...data.tab };
          this.mode =localStorage.getItem("mode");

          if (this.tab.post.sticky_text_color) {
            this.textColor = this.tab.post.sticky_text_color;
          }
          if (this.tab.post.sticky_text_color) {
            this.stickyBgColor = this.tab.post.sticky_bg;
          }
          if (this.tab.post.sticky_visible) {
            this.enableStickyPost = this.tab.post.sticky_visible ? true : false;
          }
        }
      }
    );
  }

  toggleSplash(event) {
    this.enableStickyPost = event;

    this.tab.post.sticky_visible = this.enableStickyPost ? 1 : 0;
    this.tab.post.sticky_id = this.enableStickyPost ? 1 : null;
    this.tab.post.sticky_text_color = this.textColor;
    this.tab.post.sticky_bg = this.stickyBgColor;
    // this.mdw._updateBuilderComponentContainer.next(this.tab);
    this.mdw.changeTab({tab: this.tab}, this.mode, false)
  }

  colorTextPickerChange(event) {
    this.tab.post.sticky_text_color = this.textColor;
    this.mdw.changeTab({tab: this.tab}, this.mode, false)
  }

  colorBgPickerChange(event) {
    this.tab.post.sticky_text_color = this.stickyBgColor;
    this.mdw.changeTab({tab: this.tab}, this.mode, false)
  }

  ngOnDestroy(): void {
    if (this.offCanvasSubscription) {
      this.offCanvasSubscription.unsubscribe();
    }
  }
}
