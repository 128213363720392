<div class="card slider-card mb-0" style="height: calc(100vh - 133px);">
  <div class="card-body p-0">
    <div class="row m-0 me-1 mt-2 px-2 g-2 overflow-auto">
      <ng-container *ngFor="let menu of sortedMenuList; index as item ; trackBy:menuTrackBy">
        <div
          class="col-12 col-xl-12 col-xxl-6"
          style="height: fit-content"
          [id]="menu.menu_id"
        >
          <div class="card card-bright-bg mb-0 p-1 rounded-3">
            <div class="px-1 pb-1 d-none d-xl-flex align-items-center justify-content-between">
              <div class="font-smaller text-truncate fw-bold" style="max-width: 50%">
                {{ menu?.menu_id }}
              </div>
              <div class="d-flex align-items-center">
                <i *ngIf="menu?.menu_id == defaultMenu?.menu_id" class="fas fa-check-circle text-primary font-smaller me-1"></i>
                <i *ngIf="menu?.menu_id != defaultMenu?.menu_id || current_menu.cat!='splash'"
                  class="mdi mdi-delete danger-hover-icon font-size-13 enablePointer" (click)="deleteMenuItem(menu?.menu_id)"></i>
              </div>
            </div>
            <div class="px-1 pb-1 d-flex d-xl-none align-items-center justify-content-between">
              <i [ngClass]="menu?.menu_id == defaultMenu?.menu_id ? '' : 'invisible'" class="fas fa-check-circle text-primary font-smaller me-1"></i>
              <i *ngIf="menu?.menu_id != defaultMenu?.menu_id || current_menu.cat!='splash'"
                class="mdi mdi-delete danger-hover-icon font-size-13 enablePointer" (click)="deleteMenuItem(menu?.menu_id)"></i>
            </div>
            <div class="border rounded-3 overflow-hidden"
              [ngClass]="
                menu?.menu_id == current_menu?.menu_id
                  ? 'border-primary'
                  : 'outline-variant'
              "
               (click)="selectMenu(menu?.menu_id)"
              >
              <app-mobile-view-slider
                *ngIf="menu"
                [fontSmaller]="fontSmaller"
                [inputMenuId]="menu?.menu_id"
                [inputTab]='tab'
                [colors]="colors"
              ></app-mobile-view-slider>
            </div>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
</div>

<!--
// old before changes 30/3/2024 Hazem
<div class="card-body border border-1 rounded-3" [ngClass]="
menu?.menu_id == current_menu?.menu_id
  ? 'border-primary'
  : 'border-secondary-subtle'
" (click)="selectMenu(menu?.menu_id)">
<app-mobile-view-slider *ngIf="menu" [fontSmaller]="fontSmaller"
[inputMenuId]="menu?.menu_id" [inputTab]=null></app-mobile-view-slider>
</div> -->
