<div
  *ngIf="loading"
  id="preloader"
  class="bg-transparent d-flex justify-content-center align-items-center"
>
  <div class="spinner-chase">
    <div class="chase-dot"></div>
    <div class="chase-dot"></div>
    <div class="chase-dot"></div>
    <div class="chase-dot"></div>
    <div class="chase-dot"></div>
    <div class="chase-dot"></div>
  </div>
</div>
<div class="container-fluid">
  <div class="row">
    <div class="col-lg-12">
      <div class="card m-0 shadow-none">
        <div class="card-body">
          <form [formGroup]="userListForm" (ngSubmit)="onSubmit()" class="mb-3">
            <!-- <h4 class="card-title mb-4">{{ "APIS.APIS" | translate }}</h4> -->
            <div class="row g-3">
              <div class="col-xxl-4 col-lg-6">
                <div
                  class="input-group"
                  [ngClass]="{
                    'is-invalid': submitted && f.search_value.errors
                  }"
                >
                  <select
                    class="form-select input-rounded input-focus-border"
                    style="max-width: 90px"
                    formControlName="search_type"
                    (change)="searchTypeChanged()"
                  >
                    <option value="name" selected>
                      {{ "GENERALS.NAME" | translate }}
                    </option>
                    <option value="bot_id">
                      {{ "APIS.API" | translate }}
                    </option>
                  </select>
                  <input
                    type="text"
                    class="form-control input-rounded input-focus-border rounded-form"
                    [placeholder]="
                      user_search_placeholder_map[
                        userListForm.get('search_type').value
                      ] | translate
                    "
                    (keyup)="botSearch()"
                    formControlName="search_value"
                  />
                </div>
                <div
                  *ngIf="submitted && f.search_value.errors"
                  class="invalid-feedback"
                >
                  <div *ngIf="f.search_value.errors.pattern">
                    {{ "GENERALS.PLEASEENTERVALIDID" | translate }}
                  </div>
                </div>
              </div>
              <!-- A -->
            </div>
          </form>

          <div class="row justify-content-center">
            <div class="col-xl-12">
              <div
                [ngStyle]="{ height: '60vh'}"
              >
                <ngx-simplebar id="scroll" class="h-100" #scrollRef>
                  <div class="table-responsive">
                    <table
                      class="table table-striped align-middle table-nowrap table-hover"
                    >
                      <thead class="table-light">
                        <tr>
                          <th
                            scope="col"
                            class="text-center rounded-top-4 rounded-end-0"
                          >
                            #
                          </th>
                          <th scope="col">{{ "GENERALS.NAME" | translate }}</th>
                          <th scope="col" class="text-center">
                            {{ "APIS.APIID" | translate }}
                          </th>
                          <th scope="col" class="text-center">
                            {{ "GENERALS.STATUS" | translate }}
                          </th>
                          <th scope="col" class="text-center">
                            {{ "GENERALS.STATUS" | translate }}
                          </th>
                          <th
                            scope="col"
                            class="text-center rounded-top-4 rounded-start-0"
                          >
                            {{ "GENERALS.ACTION" | translate }}
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          *ngFor="let data of page"
                          class="curser-pointer"
                          id="bots-list"
                          (click)="selectUsers($event,data)"
                        >
                          <td class="text-center">
                            <img
                              class="rounded-circle avatar-xs"
                              src="{{ data?.url }}"
                              onerror="this.onerror=null; this.src='assets/images/users/account.webp'"
                            />
                          </td>
                          <td id="name">
                            <p class="mb-0 fw-bold">{{ data.name }}</p>
                          </td>
                          <td id="bot_id" class="text-center">
                            <p class="mb-0 font-size-14 fw-bold">
                              {{ data?.user_id }}
                            </p>
                          </td>
                          <td class="text-center">
                            <p
                              class="badge font-size-12 mb-0 rounded-pill"
                              ngClass="{{ status_color_map[data.is_public] }}"
                            >
                              {{ status_map[data.is_public] | translate }}
                            </p>
                          </td>
                          <td class="text-center">
                            <p
                              class="badge font-size-12 mb-0 rounded-pill"
                              ngClass="{{
                                status_color_map[data.is_published]
                              }}"
                            >
                              {{
                                published_status_map[data.is_published]
                                  | translate
                              }}
                            </p>
                          </td>
                          <td class="text-center">
                            <div class="form-check d-flex align-items-center justify-content-center">
                              <input
                                type="checkbox"
                                id="formCheck1"
                                class="form-check-input"
                                [checked]="isSelected(data.user_id)"
                              />

                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </ngx-simplebar>
              </div>
            </div>
          </div>

          <div class="row" *ngIf="page.length == 0">
            <div class="col text-center mt-4 mb-0">
              <h6>
                {{ search_message }}
              </h6>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="modal-footer">
  <button class="btn btn-danger me-2" (click)="cancelEvent()">Cancel</button>
  <button
    class="btn btn-success"
    [disabled]="isObjEmpty()"
    (click)="insertEvent()"
  >
    Done
  </button>
</div>
