import { Component , OnInit} from '@angular/core';
import { ActivatedRoute, NavigationEnd, NavigationStart, Router } from '@angular/router';
import { WebsocketService } from './core/services/websocket.service';
import { LoaderService } from './core/services/loader.service';
import { EventService } from './core/services/event.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit  {

  refreshSubscription: any;
  appBuilderPage = false;
  routeExclude = []
  constructor(
    private router: Router,
    private ws: WebsocketService,
    private load: LoaderService,
    private es: EventService,
    private route: ActivatedRoute
  ){}

  ngOnInit() {
    // document.getElementsByTagName("html")[0].setAttribute("dir", "rtl");
    this.refreshSubscription = this.router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        if(!this.router.navigated && event.url.slice(0, 4) !== "/hub"){
          console.log("LOADER TRUE");
          this.load.isLoading.next(true);
          console.log("reconnect app");
          this.ws.reconnect(1);

          let mode = localStorage.getItem("light");
          if(mode && mode == "true"){
            this.es.broadcast("changeMode", "light");
          } else if (mode && mode == "false"){
            this.es.broadcast("changeMode", "dark");
          }
        }
        if(!this.router.navigated && event.url.includes("appbuilder")){
          this.appBuilderPage = true;
        } else {
          this.appBuilderPage = false;
        }
      }
      if (event instanceof NavigationEnd) {
        if(this.router.url.slice(0, 4) !== "/hub" && !(this.routeExclude.includes(this.router.url))){
          localStorage.setItem("returnUrl", this.router.url);
        }
        if(this.router.url.includes("appbuilder")){
          this.appBuilderPage = true;
        } else {
          this.appBuilderPage = false;
        }

      }
    });
    this.route.queryParams.subscribe((params) => {
      if(this.router.url.includes("/hub/signup?")){
        if (params && params['id']){
          localStorage.setItem("template", params['id'])
        };
      }
    });
  }
}
