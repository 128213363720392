import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { TabNames, c1, offcanvasColors } from "src/app/core/services/constants";
import { IndexDBService } from "src/app/core/services/indexDB.service";
import { Tab } from "src/app/core/services/interface";
import { MiddlwareService } from "src/app/core/services/middleware.service";
import { WebsocketService } from "src/app/core/services/websocket.service";

@Component({
  selector: "app-tab-enable-public-group-component",
  templateUrl: "./tab-enable-public-group-component.component.html",
  styleUrls: ["./tab-enable-public-group-component.component.scss"],
})
export class TabEnablePublicGroupComponentComponent
  implements OnInit, OnDestroy
{
  constructor(
    private ws: WebsocketService,
    private mdw: MiddlwareService,
    public indexDBService: IndexDBService
  ) {}

  @Input() tab_id: string;
  tab: Tab;
  mode: string;

  // default values
  imageLg: string;
  imageSm: string;
  image: string;
  icon: any;
  icon_ios: any;
  title: string;
  roundedBottom = true;
  offCanvasSubscription: any;
  currentTabSubscription: any;

  enableStickyPost = false;
  textColor = "#ffffff";
  stickyBgColor = "#ff2121";
  offcanvasColors = offcanvasColors;

  off_canvas_key: string = "tab";
  newTab = false;
  videoUrl = "";
  videoDesc = "";
  bgColor = "";
  isAndroid: boolean;
  data: any;
  tabList = {};
  sortedTabList = [];
  current_tab_id;
  component_id: string;
  field_id: string;
  enableAds = false;

  ngOnInit() {

    this.field_id = "tab_info";

    this.currentTabSubscription = this.mdw.currentTabContainer$.subscribe(
      (data) => {
        if (
          data &&
          (data["cat"] == "home" || data["cat"] == "side") &&
          data.off_canvas_key === "tab" &&
          data.currentTab &&
          this.tab_id &&
          data.currentTab.id == this.tab_id &&
          data["component_id"] != this.component_id
        ) {
          this.data = data;
          this.sortedTabList = this.data["sortedTabList"];
          this.tabList = this.data["tabList"];
          this.tab = data["tabList"][this.tab_id];
          this.tabFunction();
        }
      }
    );

    this.offCanvasSubscription = this.mdw.offCanvasContainer$.subscribe(
      (data) => {
        if (
          data &&
          data.off_canvas_key === "tab" &&
          data.tab.id == this.tab_id
        ) {
          this.TabIntialize(data);
          this.tabFunction();
        }
      }
    );
  }

  TabIntialize(data) {
    this.data = data.data;
    if (data.tab.id == this.tab_id) {
      this.tab = { ...data.tab };
      this.component_id = this.tab.id + this.field_id;
    }
    this.sortedTabList = this.data["sortedTabList"];
    this.tabList = this.data["tabList"];
    this.mode = localStorage.getItem("mode");
  }

  tabFunction() {
    if (this.tab) {
      this.isAndroid =
        localStorage.getItem("mode")[0] === c1.ANDRIOD ? true : false;
      console.log(this.tab);
      this.enableAds = this.tab.options == "0" ? false : true;
    }
  }

  toggleAds(event) {
    this.enableAds = event;
    if (this.enableAds) {
      this.tab.options = "1";
    } else {
      this.tab.options = "0";
    }

    this.updateTabData();
  }

  updateTabData() {
    //*must be there in all Tabs
    this.tabList[this.tab_id] = this.tab;

    let index = this.sortedTabList.findIndex((e) => e.id == this.tab_id);
    this.sortedTabList[index] = { ...this.tab };
    this.data["sortedTabList"] = this.sortedTabList;
    this.data["TabList"] = this.tabList;
    this.data["off_canvas_key"] = this.off_canvas_key;
    this.data["current_tab"] = this.tab;

    this.mdw._currentTabContainer.next(this.data);
    this.mdw.changeTab({ tab: this.tab }, this.mode, false);
  }

  ngOnDestroy(): void {
    if (this.offCanvasSubscription) {
      this.offCanvasSubscription.unsubscribe();
    }
  }
}
