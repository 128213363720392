import { GetAppConfig } from 'src/app/core/services/outbound';
import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable, Subject } from "rxjs";
import {
  ASSIGN_STORE,
  BuildTarget,
  MENU_BUTTON_CODE,
  MENU_BUTTON_FORM,
  MobileView,
  SPLASH_STYLE,
  TabNames,
  c1,
  c10,
  c11,
  c12,
  c13,
  c14,
  c15,
  c16,
  c2,
  c3,
  c4,
  c5,
  c6,
  c7,
  c8,
  c9,
  common_button_migration_map,
  common_components_map,
  common_tab_migration_map,
  errorMap,
  menu_button_map,
  splash_config_map,
} from "./constants";
import { colorSchema, Menu, MenuButton, MenuRow, Message, Tab } from "./interface";
import { IndexDBService } from "./indexDB.service";
import { BuilderService } from "src/app/sharedservices/builder.service";
import { argbFromHex, CorePaletteColors, CorePalette, hexFromArgb, themeFromImage, Scheme, CustomColor, themeFromSourceColor } from "@material/material-color-utilities";
import { Router } from "@angular/router";
import Swal from 'sweetalert2';
@Injectable({
  providedIn: "root",
})
export class MiddlwareService {
  constructor(
    public indexDBService: IndexDBService,
    public builderService: BuilderService,
    private router: Router
  ) {}
  id: any;
  email: any;
  token: any;
  order: any;
  chatID: any;
  templateID: any;
  user: any;
  bot: any;
  date: any;
  chatList: any;
  selectedPlan: any;
  planDetails: any;
  tempModeStatus: boolean;
  profile: any;
  tabList: any;
  returnUrl = "/";
  recharge_amount: any;
  showAdmin = false;
  menu_id: string;
  menu: any;
  balance: any;
  vappLogo: any;

  ////
  AppConfigVersion: number = 0;
  modes: any = [];
  atopBar: string = "./assets/images/mobile/topbar.webp";

  anavBarL: string = "./assets/images/mobile/navbarL.webp"; // for wider navBar
  anavBarS: string = "./assets/images/mobile/navbarS.webp";

  afull: string = "./assets/images/mobile/emptyImageF.webp";
  aLong: string = "./assets/images/mobile/emptyImageL.webp";
  aSide: string = "./assets/images/mobile/emptyImageSide.png";
  aShort: string = "./assets/images/mobile/emptyImageS.webp";
  aShortS: string = "./assets/images/mobile/emptyImageSS.webp"; // for wide nav bar

  ifull: string = "./assets/images/mobile/emptyImageF_ios.webp"; // to be replaced later on by iOS Hazem
  iLong: string = "./assets/images/mobile/emptyImageL_ios.webp";
  iShort: string = "./assets/images/mobile/emptyImageS_ios.webp";
  iLongS: string = "./assets/images/mobile/emptyImageLS_ios.webp"; // for large font
  iShortS: string = "./assets/images/mobile/emptyImageSS_ios.webp"; // for large font

  itopBar: string = "./assets/images/mobile/topbar_ios.webp";
  itopbarTitle: string = "./assets/images/mobile/topbar_title_ios.webp"; // for large font
  inavBar: string = "./assets/images/mobile/navbar_ios.webp";

  view: string;

  async changeTab(
    data,
    mode,
    view?: boolean,
    tabStop?: boolean,
    noBuilderVersionUpdate?: boolean
  ) {
    if (data && data.tab && data.tab.module) {
      switch (data.tab.module) {
        case TabNames.OPENCHAT:
        case TabNames.FEED:
        case TabNames.SEARCH:
        case TabNames.VIDEO:
        case TabNames.WEBVIEW:
        case TabNames.MENU:
        case TabNames.PAGE:
        case TabNames.QR:
        case TabNames.QR_PAGE:
        case TabNames.BOOKING:
        case TabNames.CALENDAR:
        case TabNames.CALL_LOG:
        case TabNames.CHANNELLIST:
        case TabNames.CHAT:
        case TabNames.CONTACT:
        case TabNames.MAP_SEARCH:
        case TabNames.GROUP:
        case TabNames.INVITATION:
        case TabNames.CHANNEL:
        case TabNames.OPENBOT:
        case TabNames.VIDEOAUDIO:
        case TabNames.ONLINEGROUP:
        case TabNames.ONLINECHANNEL:
        case TabNames.OPENGROUP:
        case TabNames.BOOKINGLIST:
        case TabNames.EMPTY:
        case TabNames.SPLASH:
        case TabNames.STORE:
        case TabNames.MARKET:
        case TabNames.POLL:
        case TabNames.PALETTE:
        case TabNames.SPEEDDIALER:
        case TabNames.QUERY:
        case TabNames.MYORDERS:
        case TabNames.SETTINGS:
          {
            if (
              data &&
              (data.tab.module === TabNames.MENU ||
                data.tab.module === TabNames.PAGE ||
                data.tab.module === TabNames.STORE ||
                data.tab.module === TabNames.MARKET ||
                data.tab.module === TabNames.POLL ||
                data.tab.module === TabNames.QUERY ||
                data.tab.module === TabNames.SPLASH)
            ) {

              await this.updateMenu(data);
              if (!data["menu"]) {
                data["menu"] = { ...this.menu };
              }

              if (
                !noBuilderVersionUpdate &&
                data.tab.module != TabNames.STORE &&
                data.tab.module != TabNames.MARKET &&
                data.tab.module != TabNames.POLL &&
                data.tab.module != TabNames.SPLASH &&
                (!data.tab.cat ||
                  (data.tab.cat != "store" &&
                    data.tab.cat != "market" &&
                    data.tab.cat != "poll"))
              ) {
                localStorage.setItem("version", "1");
              }
            }
            if (!tabStop) {
              if (
                (data.tab.type != TabNames.EMPTY ||
                  data.tab.module != TabNames.EMPTY) &&
                !view
              ) {
                // update the tab version

                data.tab.tab_version = this.makeRef(16);

                if (
                  !noBuilderVersionUpdate &&
                  data.tab.cat != TabNames.STORE &&
                  data.tab.cat != TabNames.MARKET &&
                  data.tab.cat != TabNames.POLL
                ) {
                  data.tab.update = 1;
                  console.log("log 300");
                  localStorage.setItem("version", "1");
                }
                const response = this.indexDBService.updateItem(
                  "tab",
                  data.tab
                );
                response.onsuccess = () => {
                  let inputData = { ...data, mode: mode };
                  this._updateBuilderComponentContainer.next(inputData);
                };
              } else {
                let inputData = { ...data, mode: mode };
                this._updateBuilderComponentContainer.next(inputData);
              }
            }
          }
          break;
      }
    }
  }

  async updateMenu(inputData) {
    // input from subscription
    delete inputData["mode"];
    // if there in tab as input return menu

    if (inputData && inputData.tab.menu_id) {
      this.menu = await this.constructMenuSync(inputData.tab.menu_id);
    }

    // All menu , row and button are exist in the data.
    else if (
      inputData &&
      inputData["menu"] &&
      inputData["menu"].menu_id &&
      inputData["row"] &&
      inputData["row"].row_id &&
      inputData["button"] &&
      inputData["button"].button_id
    ) {
      if (inputData.menu["new"]) {
        delete inputData.menu["new"];
        inputData["menu"] = { ...inputData["menu"] };
      }
      if (inputData.button["new"]) {
        delete inputData.button["new"];
        inputData["button"] = {
          ...inputData["button"],
          ...menu_button_map[inputData["menu"].menu_id],
        };
      }

      // update the menu version
      inputData["menu"].menu_version = this.makeRef(16);
      inputData["menu"].update = 1;

      const menu = this.indexDBService.updateItem("menu", inputData["menu"]);
      menu.onsuccess = async () => {
        inputData["row"].row_version = this.makeRef(16);
        inputData["row"].update = 1;

        const row = this.indexDBService.updateItem("row", inputData["row"]);
        row.onsuccess = async () => {
          inputData["button"].button_version = this.makeRef(16);
          inputData["button"].update = 1;

          const button = this.indexDBService.updateItem(
            "button",
            inputData["button"]
          );
          button.onsuccess = async () => {
            this.menu = await this.builderService.constructMenus(
              inputData.tab.menu_id
            );
          };
        };
      };
    }
    // only row and button are exist in the data.
    if (
      inputData &&
      !inputData["menu"] &&
      inputData["row"] &&
      inputData["row"].row_id &&
      inputData["button"] &&
      inputData["button"].button_id
    ) {
      if (inputData.button["new"]) {
        delete inputData.button["new"];
        inputData["button"] = {
          ...inputData["button"],
          ...menu_button_map[inputData["button"].button_code],
        };
      }

      inputData["row"].row_version = this.makeRef(16);
      inputData["row"].update = 1;

      const row = this.indexDBService.updateItem("row", inputData["row"]);
      row.onsuccess = async () => {
        inputData["button"].button_version = this.makeRef(16);
        inputData["button"].update = 1;

        const button = this.indexDBService.updateItem(
          "button",
          inputData["button"]
        );
        button.onsuccess = async () => {
          this.menu = await this.builderService.constructMenus(
            inputData.row.menu_id
          );
        };
      };
    }

    // only Menu is exist in the data.
    if (
      inputData &&
      inputData["menu"] &&
      inputData["menu"].menu_id &&
      !inputData["row"] &&
      !inputData["button"]
    ) {
      if (inputData.menu["new"]) {
        delete inputData.menu["new"];
        inputData["menu"] = { ...inputData["menu"] };
      }

      inputData["menu"].menu_version = this.makeRef(16);
      inputData["menu"].update = 1;

      const menu = this.indexDBService.updateItem("menu", inputData["menu"]);
      menu.onsuccess = async () => {
        this.menu = await this.builderService.constructMenus(
          inputData.menu.menu_id
        );
      };
    }

    // only row is exist in the data.
    if (
      inputData &&
      !inputData["menu"] &&
      inputData["row"] &&
      inputData["row"].row_id &&
      !inputData["button"]
    ) {
      inputData["row"].row_version = this.makeRef(16);
      inputData["row"].update = 1;

      const row = this.indexDBService.updateItem("row", inputData["row"]);
      row.onsuccess = async () => {
        this.menu = await this.builderService.constructMenus(
          row.result.menu_id
        );
      };
    }

    // only button is exist in the data.
    if (
      inputData &&
      !inputData["menu"] &&
      !inputData["row"] &&
      inputData["button"] &&
      inputData["button"].button_id
    ) {
      if (inputData.button["new"]) {
        delete inputData.button["new"];
        inputData["button"] = {
          ...inputData["button"],
          ...menu_button_map[inputData["button"].button_code],
        };
      }

      inputData["button"].button_version = this.makeRef(16);
      inputData["button"].update = 1;

      const button = this.indexDBService.updateItem(
        "button",
        inputData["button"]
      );
      button.onsuccess = async () => {
        const row = this.indexDBService.getItem(
          "row",
          inputData["button"].row_id
        );
        row.onsuccess = async () => {
          this.menu = await this.builderService.constructMenus(
            row.result.menu_id
          );
        };
      };
    }
  }

  /// extract the dark color from original color.////////////////////
  ColorLuminance(hex, lum) {
    // validate hex string
    hex = String(hex).replace(/[^0-9a-f]/gi, "");
    if (hex.length < 6) {
      hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
    }
    lum = lum || 0;

    // convert to decimal and change luminosity
    let rgb = "#";
    let c;
    let i;
    for (i = 0; i < 3; i++) {
      c = parseInt(hex.substr(i * 2, 2), 16);
      c = Math.round(Math.min(Math.max(0, c + c * lum), 255)).toString(16);
      rgb += ("00" + c).substr(c.length);
    }

    return rgb;
  }

  public _editChat = new BehaviorSubject<any>(undefined);
  public editChat$ = this._editChat.asObservable();

  public _editUser = new BehaviorSubject<any>(undefined);
  public editUser$ = this._editUser.asObservable();

  public _editBot = new BehaviorSubject<any>(undefined);
  public editBot$ = this._editBot.asObservable();

  public _scheduleMessageDate = new BehaviorSubject<any>(undefined);
  public scheduleMessageDate$ = this._scheduleMessageDate.asObservable();

  public _chatList = new BehaviorSubject<any>(undefined);
  public chatList$ = this._chatList.asObservable();

  public _selectedPlan = new BehaviorSubject<any>(undefined);
  public selectedPlan$ = this._selectedPlan.asObservable();

  public _editDomainContainer = new BehaviorSubject<any>(undefined);
  public editDomainContainer$ = this._editDomainContainer.asObservable();

  public _rechargeContainer = new BehaviorSubject<any>(undefined);
  public rechargeContainer$ = this._rechargeContainer.asObservable();

  public _idContainer = new BehaviorSubject<any>(undefined);
  public idContainer$ = this._idContainer.asObservable();

  public _emailContainer = new BehaviorSubject<any>(undefined);
  public emailContainer$ = this._emailContainer.asObservable();

  public _tokenContainer = new BehaviorSubject<any>(undefined);
  public tokenContainer$ = this._tokenContainer.asObservable();

  public _orderContainer = new BehaviorSubject<any>(undefined);
  public orderContainer$ = this._orderContainer.asObservable();

  public _profileContainer = new BehaviorSubject<any>(undefined);
  public profileContainer$ = this._profileContainer.asObservable();

  public _showAdminContainer = new BehaviorSubject<any>(undefined);
  public showAdminContainer$ = this._showAdminContainer.asObservable();
  public _showRootAdminContainer = new BehaviorSubject<any>(undefined);
  public showRootAdminContainer$ = this._showRootAdminContainer.asObservable();

  public _colorsComponentContainer = new BehaviorSubject<any>(undefined);
  public colorsComponentContainer$ =
    this._colorsComponentContainer.asObservable();

  public _updateBuilderComponentContainer = new BehaviorSubject<any>(undefined);
  public updateBuilderComponentContainer$ =
    this._updateBuilderComponentContainer.asObservable();

  public _updateButtonComponentContainer = new BehaviorSubject<MenuButton>(
    undefined
  );
  public updateButtonComponentContainer$ =
    this._updateButtonComponentContainer.asObservable();

  public _responseBuilderComponentContainer = new BehaviorSubject<any>(
    undefined
  );
  public responseBuilderComponentContainer$ =
    this._responseBuilderComponentContainer.asObservable();

  public _currentTabContainer = new BehaviorSubject<any>(undefined);
  public currentTabContainer$ = this._currentTabContainer.asObservable();

  public _currentMenuContainer = new BehaviorSubject<any>(undefined);
  public currentMenuContainer$ = this._currentMenuContainer.asObservable();

  public _offCanvasContainer = new BehaviorSubject<any>(undefined);
  public offCanvasContainer$ = this._offCanvasContainer.asObservable();

  public _offCanvasResponseContainer = new Subject<any>();
  public offCanvasResponseContainer$ =
    this._offCanvasResponseContainer.asObservable();

  public _selectedMessageRepliesContainer = new BehaviorSubject<Message>(null);
  public selectedMessageRepliesContainer$ =
    this._selectedMessageRepliesContainer.asObservable();




  public _refreshMessageContainer = new BehaviorSubject<any>(undefined);
  public refreshMessageContainer$ = this._refreshMessageContainer.asObservable();



  public _userSavedNotifyContainer = new BehaviorSubject<any>(null);
  public userSavedNotifyContainer$ =
    this._userSavedNotifyContainer.asObservable();

  public _getAppConfigContainer = new BehaviorSubject<any>(undefined);
  public getAppConfigContainer$ = this._getAppConfigContainer.asObservable();

  public _getAppConfigOnceContainer = new Subject<any>();
  public getAppConfigOnceContainer$ =
    this._getAppConfigOnceContainer.asObservable();

  public _vappLogoContainer = new BehaviorSubject<any>(undefined);
  public vappLogoContainer$ = this._vappLogoContainer.asObservable();

  public _activeChatContainer = new BehaviorSubject<any>(undefined);
  public activeChatContainer$ = this._activeChatContainer.asObservable();

  public _layoutRefreshContainer = new Subject<any>();
  public layoutRefreshContainer$ = this._layoutRefreshContainer.asObservable();

  public _schemeChangeContainer = new Subject<any>();
  public schemeChangeContainer$ = this._schemeChangeContainer.asObservable();

  public _storeContainer = new Subject<any>();
  public storeContainer$ = this._storeContainer.asObservable();

  public _pollContainer = new Subject<any>();
  public pollContainer$ = this._pollContainer.asObservable();

  public _getStoreMethodContainer = new Subject<any>();
  public getStoreMethodContainer$ =
    this._getStoreMethodContainer.asObservable();

  public _getPollMethodContainer = new Subject<any>();
  public getPollMethodContainer$ = this._getPollMethodContainer.asObservable();

  public _appInfoContainer = new BehaviorSubject<any>(null);
  public appInfoContainer$ = this._appInfoContainer.asObservable();

  public _planDetails = new Subject<any>();
  public planDetails$ = this._planDetails.asObservable();

  public _tempModeStatus = new Subject<any>();
  public tempModeStatus$ = this._tempModeStatus.asObservable();

  public _templateID = new Subject<any>();
  public templateID$ = this._templateID.asObservable();

  public _recallMessageContainer = new Subject<any>();
  public recallMessageContainer$ = this._recallMessageContainer.asObservable();

  public _resetAppConfigContainer = new Subject<any>();
  public resetAppConfigContainer$ = this._resetAppConfigContainer.asObservable();

  idSubscription = this.idContainer$.subscribe((received_id) => {
    this.id = received_id;
  });

  emailSubscription = this.emailContainer$.subscribe((received_email) => {
    this.email = received_email;
  });

  tokenSubscription = this.tokenContainer$.subscribe((received_token) => {
    this.token = received_token;
  });

  orderSubscription = this.orderContainer$.subscribe((received_order) => {
    this.order = received_order;
  });

  profileSubscription = this.profileContainer$.subscribe((received_profile) => {
    this.profile = received_profile;
  });

  rechargeSubscription = this.rechargeContainer$.subscribe(
    (received_amount) => {
      this.recharge_amount = received_amount;
    }
  );

  vappSubscription = this.vappLogoContainer$.subscribe((logo) => {
    this.vappLogo = logo;
    // console.log("VAPPPPP LOGO");
    // console.log(this.vappLogo);
  });

  channelSubscription = this.editChat$.subscribe(
    (chatID) => (this.chatID = chatID)
  );

  templateIDSubscription = this.templateID$.subscribe(
    (id) => (this.templateID = id)
  );

  userSubscription = this.editUser$.subscribe((user) => (this.user = user));
  botSubscription = this.editBot$.subscribe((bot) => (this.bot = bot));
  dateSubscription = this.scheduleMessageDate$.subscribe(
    (date) => (this.date = date)
  );
  chatListSubscription = this.chatList$.subscribe(
    (chatList) => (this.chatList = chatList)
  );

  selectedPlanSubscription = this.selectedPlan$.subscribe(
    (plan) => (this.selectedPlan = plan)
  );



  planDetailsSubscription = this.planDetails$.subscribe(
    (data) => (this.planDetails = {
      plan_id: data && data.plan_id ? data.plan_id : null,
      cancel_end: data && data.cancel_end ? data.cancel_end : null,
      trial_end_date: data && data.trial_end_date ? data.trial_end_date : null,
      expire: data && data.expire ? data.expire : null
    })
  );

  tempModeStatusSubscription = this.tempModeStatus$.subscribe(
    (data) => (this.tempModeStatus = data)
  );

  getChatID() {
    return this.chatID;
  }

  getDate() {
    return this.date;
  }

  getUser() {
    return this.user;
  }

  getBot() {
    return this.bot;
  }

  getchatList() {
    return this.chatList;
  }

  getSelectedPlan() {
    return this.selectedPlan;
  }

  getPlanDetails() {
    return this.planDetails;
  }

  getTempModeStatus() {
    return this.tempModeStatus;
  }

  getID() {
    return this.id;
  }

  getEmail() {
    return this.email;
  }

  getToken() {
    return this.token;
  }

  getOrder() {
    return this.order;
  }

  getProfile() {
    return this.profile;
  }

  getRecharge() {
    return this.recharge_amount;
  }

  mapError(code: number) {
    return errorMap[code];
  }

  getTemplateID(){
    return this.templateID
  }

  clear() {
    this.id = null;
    this.email = null;
    this.token = null;
    this.order = null;
    this.profile = null;
    this.returnUrl = "/";
  }

  makeRef(length: number) {
    let result = "";
    const characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    const charactersLength = characters.length;
    let counter = 0;
    while (counter < length) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
      counter += 1;
    }
    return result;
  }

  makRefNumber(length: number) {
    let result = "";
    const numbers = "0123456789";
    const numbersLength = numbers.length;
    let counter = 0;
    while (counter < length) {
      result += numbers.charAt(Math.floor(Math.random() * numbersLength));
      counter += 1;
    }
    return Number(result);
  }

  message(msg): Observable<void> {
    return msg;
  }

  localCommonComponentsMap(module) {
    return common_components_map[module];
  }

  isValidHexaCode(str) {
    // Regex to check valid
    // hexadecimalColor_code
    let regex = new RegExp(/^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/);

    // if str
    // is empty return false
    if (str == null) {
      return "false";
    }

    // Return true if the str
    // matched the ReGex
    if (regex.test(str) == true) {
      return true;
    } else {
      return false;
    }
  }

  customColor(color, source?) {
    let customColor: CustomColor = {
      name: "test",
      value: argbFromHex(color),
      blend: true,
    };
    let theme = themeFromSourceColor(argbFromHex(source), [customColor]);
    // let customScheme = {light: theme.customColors[0].light, dark: theme.customColors[0].dark};
    let scheme: any;
    if (theme) {
      scheme = {
        light: hexFromArgb(theme.customColors[0].light.color),
        dark: hexFromArgb(theme.customColors[0].dark.color),
      };
    } else {
      scheme = { light: color, dark: color };
    }
    return scheme;
  }

  customColorFull(color, source?) {
    let customColor: CustomColor = {
      name: "test",
      value: argbFromHex(color),
      blend: true,
    };
    let theme = themeFromSourceColor(argbFromHex(source), [customColor]);
    // let customScheme = {light: theme.customColors[0].light, dark: theme.customColors[0].dark};
    let scheme: any;
    if (theme) {
      scheme = {
        light: {
          customColor: hexFromArgb(theme.customColors[0].light.color),
          onCustomColor: hexFromArgb(theme.customColors[0].light.onColor),
          customColorContainer: hexFromArgb(
            theme.customColors[0].light.colorContainer
          ),
          onCustomColorContainer: hexFromArgb(
            theme.customColors[0].light.onColorContainer
          ),
        },

        dark: {
          customColor: hexFromArgb(theme.customColors[0].dark.color),
          onCustomColor: hexFromArgb(theme.customColors[0].dark.onColor),
          customColorContainer: hexFromArgb(
            theme.customColors[0].dark.colorContainer
          ),
          onCustomColorContainer: hexFromArgb(
            theme.customColors[0].dark.onColorContainer
          ),
        },
      };
    }

    return scheme;
  }

  async saveBuilderDB(data) {
    if (data) {
      if (data.appConfig && data.appConfig.app && data.appConfig.app.version) {
        this.AppConfigVersion = data.appConfig.app.version.value? data.appConfig.app.version.value: 0;
      } else {
        this.AppConfigVersion = 0;
      }
      if (data.genAI) {
        let config;
        config = this.mapAIConfig(data.data);
        this.indexDBService.deleteTable("tab");
        this.indexDBService.deleteTable("menu");
        this.indexDBService.deleteTable("row");
        this.indexDBService.deleteTable("button");
        this.indexDBService.deleteTable("item");
        this.indexDBService.deleteTable("app");
        localStorage.setItem("version", "0");
        await this.saveOnlineChannelConfig(config.onlineChannelConfig);
        await this.saveOnlineAppConfig(config.onlineAppConfig, false);
        await this.saveAppConfig(config.appConfig);
      }

      let dBVersion: any = {};
      dBVersion = await this.indexDBService.getItemOnsucss("item", "version");
      if ((!dBVersion ||   !dBVersion.value || dBVersion.value < this.AppConfigVersion) || data.template_id ) {
        console.log("template ID", data.template_id);
        console.log("database Value", dBVersion);

        if (data.template_id &&  dBVersion && dBVersion.value){
                let swalWithBootstrapButtons = Swal.mixin({
                  customClass: { confirmButton: "btn btn-success", cancelButton: "btn btn-danger ms-2", },
                  buttonsStyling: false,
                });
                  swalWithBootstrapButtons
                  .fire({
                    title: "Overwirte the current App configuration?",
                    text: "Please save current App configuration!",
                    icon: "warning",
                    confirmButtonText: "Yes, delete it!",
                    cancelButtonText: "No, cancel!",
                    showCancelButton: true,
                  })
                  .then(async (result) => {
                    if (result.value) {

                      if (data.appConfig.app.system && data.appConfig.app.system.app_info){
                        data.appConfig.app.system['app_info'] = await this.getUpdatedCurrentAppInfo(data.appConfig.app.system.app_info)
                      }else {
                        data.appConfig.app.system['app_info'] = await this.getUpdatedCurrentAppInfo(null)
                      }

                      await this.deleteAppConfig()
                      await this.saveAppConfiguration(data)
                      localStorage.setItem("version", "1")

                      this._getAppConfigOnceContainer.next({template_id: data.template_id});
                      this._getAppConfigContainer.next(true);


                    }
                  });
                  localStorage.removeItem("template")
                }else {
                     await this.saveAppConfiguration(data)
                     localStorage.setItem("version", "0")

                     console.log("I am updating normal");

                }
      }
      this._getAppConfigOnceContainer.next({template_id: data.template_id});
      this._getAppConfigContainer.next(true);
    }
  }



  async getUpdatedCurrentAppInfo(newAppInfo){
    const appInfo = this.indexDBService.getItem("item", "app_info");
    appInfo.onsuccess = async (event) => {
      if (appInfo.result){
         return await this.updateCurrentAppInfo (newAppInfo, appInfo.result)
      }
    }
  }


  async updateCurrentAppInfo(newAppInfo, currentAppInfo){
      if (newAppInfo!= null){
        currentAppInfo['call_enabled']=newAppInfo['call_enabled']
        currentAppInfo['demoVideo']=newAppInfo['demoVideo']
        currentAppInfo['demo_video']=newAppInfo['demo_video']
        currentAppInfo['login_type']=newAppInfo['login_type']
        currentAppInfo['video_enabled']=newAppInfo['video_enabled']
        currentAppInfo['same_tone']=newAppInfo['same_tone']

      if (currentAppInfo.logo_color.length <2 ){
        currentAppInfo['logo_color']=newAppInfo['logo_color']
        currentAppInfo['logo_white']=newAppInfo['logo_white']
        currentAppInfo['image']=newAppInfo['image']
        currentAppInfo['image_id']=newAppInfo['image_id']
        currentAppInfo['dominant_color']=newAppInfo['dominant_color']
        currentAppInfo['color_logo_bg']=newAppInfo['color_logo_bg']
      }
      if(!currentAppInfo.channel_name){
        currentAppInfo['channel_name']=newAppInfo['channel_name']
      }

      }

     return currentAppInfo
  }


  async deleteAppConfig(){
    this.indexDBService.deleteTable("tab");
    this.indexDBService.deleteTable("menu");
    this.indexDBService.deleteTable("row");
    this.indexDBService.deleteTable("button");
    this.indexDBService.deleteTable("item");
    this.indexDBService.deleteTable("app");
  }
  async saveAppConfiguration(data){
    if (data.appConfig) {
      data.appConfig.app["version"] = { value: this.AppConfigVersion };
    }
    if (this.AppConfigVersion == 0 && data.onlineChannelConfigStaging) {
      console.log("staging");
      await this.saveOnlineChannelConfig(data.onlineChannelConfigStaging);
    } else {
      console.log("migratted");
      await this.saveOnlineChannelConfig(data.onlineChannelConfig);
    }
    if (this.AppConfigVersion == 0 && data.onlineAppConfig) {
      await this.saveOnlineAppConfig(data.onlineAppConfig, true);
    } else {
      console.log("migratted");
      await this.saveOnlineAppConfig(data.onlineAppConfig, false);
    }
    await this.saveAppConfig(data.appConfig);
    localStorage.setItem("version", "0");
  }

  async saveOnlineChannelConfig(data) {
    let dbData: any = {};
    dbData = data;

    if (data && data.app) {
      // get the default store regardless if you have the store or not
      this._getStoreMethodContainer.next(null);
      this.menusInsertion(data.app.menus, true);
      dbData = this.appStyle(data.app.tabs, dbData);
      this.tabsInsertion(data.app.tabs, "tabs", "home");

      // add id
      if (!dbData.id) {
        dbData.id = "home";
      }
      // clean up
      if (dbData && dbData.app && dbData.app.side_menu) {
        delete dbData.app.side_menu;
      }

      if (dbData && dbData.app && dbData.app.tabs && !dbData.app.tabs.default) {
        dbData.app.tabs.default = { feed: { chat_label: "0" } };
      }
      delete dbData.app.menus;
      delete dbData.app.tabs.tabs;
      dbData.app["menus"] = [];
      dbData.app.tabs["tabs"] = [];
      await this.indexDBService.insertItem("app", dbData);
    } else {
      // no data
      let newData: any;
      newData = {
        id: "home",
        app: {
          menus: [],
          tabs: { tabs: [], default: { feed: { chat_label: "0" } } },
        },
      };
      newData = this.appStyle(newData.app.tabs, newData);
      await this.indexDBService.insertItem("app", newData);
    }
  }
  menusInsertion(menus, online?, menu_group?, cat?, navigate?: boolean) {
    if (menus) {
      let menuOrder = 0;
      // menus insertions
      menus.forEach((menu) => {
        if (!menu.cat) {
          menu.cat = "menu";
        }
        if (!menu.menu_version) {
          menu.menu_version = this.makeRef(16);
        }

        if (!menu.menu_order) {
          menu.menu_order = menuOrder;
        }
        if (menu.cat == "store" && menu.menu_group && online) {
          this._getStoreMethodContainer.next(menu.menu_group);
        }

        // no update needed
        menu.update = 0;

        menuOrder++;
        let rowOrder = 0;
        menu.rows.forEach((row) => {
          if (!row.menu_id) {
            row.menu_id = menu.menu_id;
          }
          if (!row.row_version) {
            row.row_version = this.makeRef(16);
          }
          if (!row.row_id) {
            row.row_id = menu.menu_id + rowOrder;
          }
          if (!row.row_order) {
            row.row_order = rowOrder++;
          }
          // no update needed
          row.update = 0;

          let btnOrder = 0;
          row.buttons.forEach((btn) => {
            if (!btn.row_id) {
              btn.row_id = row.row_id;
            }
            if (!btn.button_order) {
              btn.button_order = btnOrder++;
            }
            if (!btn.button_verion) {
              btn.button_version = this.makeRef(16);
            }

            if (!btn.button_code && btn.button_form) {
              btn.button_code =
                common_button_migration_map[btn.button_form].button_code;
            }

            if (!btn.info && btn.button_code) {
              btn.info = menu_button_map[btn.button_code].info;
            }

            if (btn.button_code && !btn.button_style_ios) {
              btn.button_style = menu_button_map[btn.button_code].button_style;
              btn.button_style_ios =
                menu_button_map[btn.button_code].button_style_ios;
            }

            // no update needed
            btn.update = 0;

            if (btn.button_code) {
              this.indexDBService.insertItem("button", btn);
            }
          });
          delete row.buttons;
          this.indexDBService.insertItem("row", row);
        });
        delete menu.rows;
        this.indexDBService.insertItem("menu", menu);
      });
    }
  }
  appStyle(tabs, dbData) {
    if (tabs) {
      if (!tabs.color_schema) {
        dbData.app.tabs.color_schema = tabs.active_title_color
          ? { source: tabs.active_title_color }
          : { source: "#386A1F" };
        let colors: any = {};
        colors.primary = dbData.app.tabs.color_schema.source;
        dbData.app.tabs.color_schema = this.createColorsTheme(
          "many",
          colors,
          null,
          c1.ANDRIOD
        );
      }
      if (!tabs.color_schema_ios) {
        dbData.app.tabs.color_schema_ios = { source: "#007aff" };
        let colors_ios: any = {};
        colors_ios.primary = "#007aff";
        colors_ios.secondary = "#767778";
        colors_ios.tertiary = "#bedbf8";
        dbData.app.tabs.color_schema_ios = this.createColorsTheme(
          "many",
          colors_ios,
          null,
          c1.IOS
        );
      }
      // console.log("SENT COLORS");

      let lightValue: boolean;
      if (tabs.color_schema || tabs.color_schema_ios) {
        if (localStorage.getItem("mode")[15] == null) {
          lightValue = localStorage.getItem("light") == "true" ? true : false;
          if (localStorage.getItem("light") == "true") {
            let modes: any;
            modes = [{ id: c16.ID, value: c16.LIGHT }];
            this.changeMobileTheme(modes);
          } else {
            let modes: any;
            modes = [{ id: c16.ID, value: c16.DARK }];
            this.changeMobileTheme(modes);
          }
        } else {
          lightValue =
            localStorage.getItem("mode")[15] == c16.LIGHT ? true : false;
        }
        this._colorsComponentContainer.next({
          color_schema: tabs.color_schema,
          color_schema_ios: tabs.color_schema_ios,
          light: lightValue,
        });
      }

      if (dbData.app.tabs.active_icon_color) {
        delete dbData.app.tabs.active_icon_color;
      }

      if (dbData.app.tabs.active_icon_color_ios) {
        delete dbData.app.tabs.active_icon_color_ios;
      }

      if (dbData.app.tabs.active_title_color) {
        delete dbData.app.tabs.active_title_color;
      }

      if (dbData.app.tabs.active_title_color_ios) {
        delete dbData.app.tabs.active_title_color_ios;
      }

      if (dbData.app.tabs.bg) {
        delete dbData.app.tabs.bg;
      }

      if (dbData.app.tabs.bg_ios) {
        delete dbData.app.tabs.bg_ios;
      }

      if (dbData.app.tabs.default_color) {
        delete dbData.app.tabs.default_color;
      }

      if (dbData.app.tabs.default_color_ios) {
        delete dbData.app.tabs.default_color_ios;
      }

      // tab style migration
      if (tabs.tab_style && !tabs.mode) {
        this.modes = [];
        switch (tabs.tab_style) {
          case 0:
            // top wide
            this.modes = [
              { id: c4.ID, value: c4.TOP },
              { id: c5.ID, value: c5.WIDE },
            ];
            break;

          case 1:
            // bottom wide
            this.modes = [
              { id: c4.ID, value: c4.BOTTOM },
              { id: c5.ID, value: c5.WIDE },
            ];
            break;

          case 2:
            // Top Standard
            this.modes = [
              { id: c4.ID, value: c4.TOP },
              { id: c5.ID, value: c5.STANDARD },
            ];
            break;

          case 3:
            // bottom Standard
            this.modes = [
              { id: c4.ID, value: c4.BOTTOM },
              { id: c5.ID, value: c5.STANDARD },
            ];
            break;
        }

        this.changeMobileTheme(this.modes);
      }
      // tab style migration for IOS
      if (tabs.tab_style_ios) {
        this.modes = [];

        switch (tabs.tab_style_ios) {
          case 0:
            // Wide
            this.modes = [{ id: c10.ID, value: c10.WIDE }];
            break;

          case 1:
            // Standard
            this.modes = [{ id: c10.ID, value: c10.STANDARD }];
            break;
        }
        this.changeMobileTheme(this.modes);
      } else {
        // console.log("log 3");
        this.modes = [{ id: c10.ID, value: c10.WIDE }];
        this.changeMobileTheme(this.modes);
      }

      // tab style migration for IOS
      if (tabs.tab_style_ios) {
        // console.log("log 4");
        this.modes = [];
        switch (tabs.tab_style_ios) {
          case 0:
            // Wide
            this.modes = [{ id: c10.ID, value: c10.WIDE }];
            break;

          case 1:
            // Standard
            this.modes = [{ id: c10.ID, value: c10.STANDARD }];
            break;
        }
        this.changeMobileTheme(this.modes);
      } else {
        // console.log("log 5");
        this.modes = [{ id: c10.ID, value: c10.WIDE }];
        this.changeMobileTheme(this.modes);
      }

      // Large and Small font for iOS
      if (tabs.large_tile) {
        // console.log("log 6");
        this.modes = [];
        switch (tabs.large_title) {
          case 0:
            // Small Title
            this.modes = [{ id: c7.ID, value: c7.STANDARD }];
            break;

          case 1:
            // Standard
            this.modes = [{ id: c7.ID, value: c7.LARGE }];
            break;
        }
        this.changeMobileTheme(this.modes);
      } else {
        this.modes = [{ id: c7.ID, value: c7.STANDARD }];
        this.changeMobileTheme(this.modes);
      }
    }
    return dbData;
  }
  tabsInsertion(tabs, key, cat) {
    if (tabs && tabs[key]) {
      let tab_order: any = 0;
      // set default if not default for all tabs.

      tabs[key].forEach((tab) => {
        if (!tab.cat) {
          if (tab.layout && tab.layout === "button") {
            tab.cat = "next";
          } else {
            tab.cat = cat;
          }
        }

        if (!tab.id) {
          tab.id = this.makeRef(16);
        }

        if (tab.title.default) {
          tab.title = tab.title.default;
          tab.title_ios = tab.title.default;
        }

        if (!tab.tab_version) {
          tab.tab_version = this.makeRef(16);
        }

        if (tab.tab_update) {
          delete tab.tab_update;
        }

        // no update needed
        tab.update = 0;

        if (!tab.tab_order) {
          tab.tab_order = tab_order;
        }
        tab_order++;
        if (this.AppConfigVersion == 0) {
          // migration  from version 0
          // setting the right icons
          tab.icon = common_tab_migration_map[tab.type].icon;
          tab.icon_ios = common_tab_migration_map[tab.type].icon_ios;
          if (common_tab_migration_map[tab.type].type) {
            tab.module = common_tab_migration_map[tab.type].type;
            tab.type = common_tab_migration_map[tab.type].type;
          }

          if (tab.type == TabNames.FEED && tab.footer && tab.footer.action) {
            //Hazem to revert
            // for (let i = 0; i < tab.footer.action.length; i++) {
            //   switch (tab.footer.action[i].link) {
            //     case "like":
            //       tab.footer.action[i].icon =
            //         common_tab_migration_map[tab.type].like_icon;
            //       break;
            //     case "reply":
            //       tab.footer.action[i].icon =
            //         common_tab_migration_map[
            //           tab.type
            //         ].reply_icon;
            //       break;
            //     case "view":
            //       tab.footer.action[i].icon =
            //         common_tab_migration_map[tab.type].view_icon;
            //       break;
            //     case "share":
            //       tab.footer.action[i].icon =
            //         common_tab_migration_map[
            //           tab.type
            //         ].share_icon;
            //       break;
            //     case "like_highlight":
            //       tab.footer.action[i].icon =
            //         common_tab_migration_map[
            //           tab.type
            //         ].liked_icon;
            //       break;
            //   }
            // }
          }
          if (tab.sub_menus) {
            tab.sub_menus.forEach((menu_id) => {
              const response = this.indexDBService.getItem("menu", menu_id);
              response.onsuccess = async () => {
                let menu = response.result;
                if (!menu.menu_group) {
                  menu.menu_group = tab.id;
                  this.indexDBService.updateItem("menu", menu);
                }
              };
            });
          }

          if (tab.menu_id) {
            const response = this.indexDBService.getItem("menu", tab.menu_id);
            response.onsuccess = async () => {
              let menu = response.result;
              menu.menu_group = tab.id;
              this.indexDBService.updateItem("menu", menu);
            };
          }
        }
        if (!tab.module) {
          tab.module = tab.type; // temp solution to add module from type
        }
        this.indexDBService.insertItem("tab", tab);
      });
    }
  }

  createColorsTheme(type, baseColors, HTMLImageElement?, IOS?: c1) {
    if (type == "image") {
      let colors: colorSchema = {
        schemes: { light: {}, dark: {} },
        palettes: {
          primary: { keyColor: { id: null } },
          secondary: { keyColor: { id: null } },
          tertiary: { keyColor: { id: null } },
          error: { keyColor: { id: null } },
          neutral: { keyColor: { id: null } },
          neutralVariant: { keyColor: { id: null } },
        },
      };
      let theme: any = { schemes: { light: { props: {} } } };
      themeFromImage(HTMLImageElement).then(async (Theme) => {
        theme = Theme;
        Object.keys(theme.schemes.light.props).forEach((key) => {
          colors.schemes.light[key] = hexFromArgb(
            theme.schemes.light.props[key]
          );
        });

        Object.keys(theme.schemes.dark.props).forEach((key) => {
          colors.schemes.dark[key] = hexFromArgb(theme.schemes.dark.props[key]);
        });
        colors.source = hexFromArgb(theme.source);

        let palette: any;
        palette = theme.palettes;
        colors.palettes.primary.keyColor.id = hexFromArgb(
          palette.primary["keyColor"]["argb"]
        );
        colors.palettes.secondary.keyColor.id = hexFromArgb(
          palette.secondary["keyColor"]["argb"]
        );
        colors.palettes.tertiary.keyColor.id = hexFromArgb(
          palette.tertiary["keyColor"]["argb"]
        );
        colors.palettes.error.keyColor.id = hexFromArgb(
          palette.error["keyColor"]["argb"]
        );
        colors.palettes.neutral.keyColor.id = hexFromArgb(
          palette.neutral["keyColor"]["argb"]
        );
        colors.palettes.neutralVariant.keyColor.id = hexFromArgb(
          palette.neutralVariant["keyColor"]["argb"]
        );
        let updatedColros = this.additionalColors(palette, colors, "image");
        // console.log("this.imageTheme", this.imageTheme);
        return updatedColros;
      });
    }

    if (baseColors && baseColors.primary) {
      if (type == "many") {
        let colors: colorSchema = {
          schemes: { light: {}, dark: {} },
          palettes: {
            primary: { keyColor: { id: null } },
            secondary: { keyColor: { id: null } },
            tertiary: { keyColor: { id: null } },
            error: { keyColor: { id: null } },
            neutral: { keyColor: { id: null } },
            neutralVariant: { keyColor: { id: null } },
          },
        };

        let theme: any = {};
        let CorePaletteColors: CorePaletteColors = {
          primary: argbFromHex(baseColors.primary),
          secondary: baseColors.secondary
            ? argbFromHex(baseColors.secondary)
            : null,
          tertiary: baseColors.tertiary
            ? argbFromHex(baseColors.tertiary)
            : null,
        };
        let palette: CorePalette;
        palette = CorePalette.contentFromColors(CorePaletteColors);
        theme.light = Scheme.lightFromCorePalette(palette);
        theme.dark = Scheme.darkFromCorePalette(palette);

        Object.keys(theme.light.props).forEach((key) => {
          colors.schemes.light[key] = hexFromArgb(theme.light.props[key]);
        });

        Object.keys(theme.dark.props).forEach((key) => {
          colors.schemes.dark[key] = hexFromArgb(theme.dark.props[key]);
        });

        colors.source = baseColors.primary;
        colors.palettes.primary.keyColor.id = hexFromArgb(
          palette.a1["keyColor"]["argb"]
        );
        colors.palettes.secondary.keyColor.id = hexFromArgb(
          palette.a2["keyColor"]["argb"]
        );
        colors.palettes.tertiary.keyColor.id = hexFromArgb(
          palette.a3["keyColor"]["argb"]
        );
        colors.palettes.error.keyColor.id = hexFromArgb(
          palette.error["keyColor"]["argb"]
        );
        colors.palettes.neutral.keyColor.id = hexFromArgb(
          palette.n1["keyColor"]["argb"]
        );
        colors.palettes.neutralVariant.keyColor.id = hexFromArgb(
          palette.n2["keyColor"]["argb"]
        );
        let updatedColros = this.additionalColors(palette, colors);

        // console.log(" updated color ", updatedColros)
        return updatedColros;
      }
    }
  }

  additionalColors(palette, colors, image?) {
    let item = image ? "primary" : "n1";
    // https://github.com/flutter/flutter/issues/137679

    colors.schemes.light.surfaceDim = hexFromArgb(palette[item].tone(87));
    colors.schemes.dark.surfaceDim = hexFromArgb(palette[item].tone(6));

    colors.schemes.light.surfaceBright = hexFromArgb(palette[item].tone(98));
    colors.schemes.dark.surfaceBright = hexFromArgb(palette[item].tone(24));

    colors.schemes.light.surfaceContainerLowest = hexFromArgb(
      palette[item].tone(100)
    );
    colors.schemes.dark.surfaceContainerLowest = hexFromArgb(
      palette[item].tone(4)
    );

    colors.schemes.light.surfaceContainerLow = hexFromArgb(
      palette[item].tone(96)
    );
    colors.schemes.dark.surfaceContainerLow = hexFromArgb(
      palette[item].tone(10)
    );

    colors.schemes.light.surfaceContainer = hexFromArgb(palette[item].tone(94));
    colors.schemes.dark.surfaceContainer = hexFromArgb(palette[item].tone(12));

    colors.schemes.light.surfaceContainerHigh = hexFromArgb(
      palette[item].tone(92)
    );
    colors.schemes.dark.surfaceContainerHigh = hexFromArgb(
      palette[item].tone(17)
    );

    colors.schemes.light.surfaceContainerHighest = hexFromArgb(
      palette[item].tone(90)
    );
    colors.schemes.dark.surfaceContainerHighest = hexFromArgb(
      palette[item].tone(22)
    );

    colors.schemes.light.surface = hexFromArgb(palette[item].tone(98));
    colors.schemes.dark.surface = hexFromArgb(palette[item].tone(6));

    // set initially LauncherBackground as White
    if (!colors.schemes.light.icLauncherBackground) {
      colors.schemes.light.icLauncherBackground = "#ffffff";
      colors.schemes.dark.icLauncherBackground = "#ffffff";
    }

    //  delete colors.schemes.light.background   // use surface instead.
    //  delete colors.schemes.light.onBackground
    //  delete colors.schemes.light.surfaceVariant
    //  delete colors.schemes.dark.background   // use surface instead.
    //  delete colors.schemes.dark.onBackground
    //  delete colors.schemes.dark.surfaceVariant
    return colors;
  }
  changeMobileTheme(modes, tab?) {
    // let modes =
    // [
    //   {id:c1.ID,value:c1.ANDRIOD},
    //   {id:c2.ID,value:c2.FULL},
    //   {id:c3.ID,value:c3.SINGLE},
    //   {id:c4.ID,value:c4.TOP},
    //   {id:c5.ID,value:c5.WIDE},
    //   {id:c6.ID,value:c6.BOTTOM},
    //   {id:c7.ID,value:c7.STANDARD},
    //   {id:c8.ID,value:c8.SIDE},
    //
    // ]

    // this.mdw.changeMobileTheme(modes);

    let str = localStorage.getItem("mode");
    if (str && str.length != 16) {
      localStorage.setItem("mode", "ahmtwbsttw3a3p0l");
      str = "ahmtwbsttw3a3p0l";
    }

    // mode code as following xxxxxx
    // xxxxx as c1 c2 c3 c4 c5
    // c1: android and ios
    // c2: full, home, side, component
    // c3: single, notab, manytab
    // c4: android home- top, bottom
    // c5: android home- wide and narraw
    // c6: ios home - side menu top, bottom
    // c7: ios home - standard and large title
    // c8: android no side(false) and side (true)
    // c9: ios no side(false) and side (true)
    // c10: ios home- wide and narraw(standard)
    // c11: android style m2 (2) or m3(3)
    // c12: android apply on home& side (a) only home(h) or only side (s)
    // c13: ios style m2 (2) or m3(3)
    // c14: sender buble color "primary" (p), secondary (s), tertiary (t)
    // c15: receiver buble color lowest(0), low(1), high(2)
    // c16: light (l) and dark (d) mode

    let c1_value: string;
    let c2_value: string;
    let c3_value: string;
    let c4_value: string;
    let c5_value: string;
    let c6_value: string;
    let c7_value: string;
    let c8_value: string;
    let c9_value: string;
    let c10_value: string;
    let c11_value: string;
    let c12_value: string;
    let c13_value: string;
    let c14_value: string;
    let c15_value: string;
    let c16_value: string;

    var regc1 = /(?<=^.{0})./gi;
    var regc2 = /(?<=^.{1})./gi;
    var regc3 = /(?<=^.{2})./gi;
    var regc4 = /(?<=^.{3})./gi;
    var regc5 = /(?<=^.{4})./gi;
    var regc6 = /(?<=^.{5})./gi;
    var regc7 = /(?<=^.{6})./gi;
    var regc8 = /(?<=^.{7})./gi;
    var regc9 = /(?<=^.{8})./gi;
    var regc10 = /(?<=^.{9})./gi;
    var regc11 = /(?<=^.{10})./gi;
    var regc12 = /(?<=^.{11})./gi;
    var regc13 = /(?<=^.{12})./gi;
    var regc14 = /(?<=^.{13})./gi;
    var regc15 = /(?<=^.{14})./gi;
    var regc16 = /(?<=^.{15})./gi;

    modes.forEach((mode) => {
      switch (mode.id) {
        case "c1":
          if (mode.value == c1.ANDRIOD) {
            c1_value = c1.ANDRIOD;
          } else {
            c1_value = c1.IOS;
          }
          str = str.replace(regc1, c1_value);
          break;
        case "c2":
          switch (mode.value) {
            case c2.FULL:
              c2_value = c2.FULL;
              break;
            case c2.HOME:
              c2_value = c2.HOME;
              break;
            case c2.SIDE:
              c2_value = c2.SIDE;
              break;
            case c2.COMPONENT:
              c2_value = c2.COMPONENT;
              break;
          }
          str = str.replace(regc2, c2_value);
          break;

        case "c3":
          switch (mode.value) {
            case c3.SINGLE:
              c3_value = c3.SINGLE;
              break;
            case c3.NOTAB:
              c3_value = c3.NOTAB;
              break;
            case c3.MANYTAB:
              c3_value = c3.MANYTAB;
              break;
          }
          str = str.replace(regc3, c3_value);
          break;

        case "c4":
          if (mode.value == c4.TOP) {
            c4_value = c4.TOP;
          } else {
            c4_value = c4.BOTTOM;
          }
          str = str.replace(regc4, c4_value);
          break;

        case "c5":
          if (mode.value == c5.WIDE) {
            c5_value = c5.WIDE;
          } else {
            c5_value = c5.STANDARD;
          }
          str = str.replace(regc5, c5_value);
          break;

        case "c6":
          if (mode.value == c6.TOP) {
            c6_value = c6.TOP;
          } else {
            c6_value = c6.BOTTOM;
          }
          str = str.replace(regc6, c6_value);
          break;

        case "c7":
          if (mode.value == c7.LARGE) {
            c7_value = c7.LARGE;
          } else {
            c7_value = c7.STANDARD;
          }
          str = str.replace(regc7, c7_value);
          break;

        case "c8":
          if (mode.value == c8.NOSIDE) {
            c8_value = c8.NOSIDE;
          } else {
            c8_value = c8.SIDE;
          }
          str = str.replace(regc8, c8_value);
          break;

        case "c9":
          if (mode.value == c9.NOSIDE) {
            c9_value = c9.NOSIDE;
          } else {
            c9_value = c9.SIDE;
          }
          str = str.replace(regc9, c9_value);
          break;

        case "c10":
          if (mode.value == c10.STANDARD) {
            c10_value = c10.STANDARD;
          } else {
            c10_value = c10.WIDE;
          }
          str = str.replace(regc10, c10_value);
          break;

        case "c11":
          if (mode.value == c11.M3) {
            c11_value = c11.M3;
          } else {
            c11_value = c11.M2;
          }
          str = str.replace(regc11, c11_value);
          break;

        case "c12":
          switch (mode.value) {
            case c12.ALL:
              c12_value = c12.ALL;
              break;
            case c12.HOME:
              c12_value = c12.HOME;
              break;
            case c12.SIDE:
              c12_value = c12.SIDE;
              break;
          }
          str = str.replace(regc12, c12_value);
          break;

        case "c13":
          if (mode.value == c13.M3) {
            c13_value = c13.M3;
          } else {
            c13_value = c13.M2;
          }
          str = str.replace(regc13, c13_value);
          break;

        case "c14":
          switch (mode.value) {
            case c14.PRIMARY:
              c14_value = c14.PRIMARY;
              break;
            case c14.SECONDARY:
              c14_value = c14.SECONDARY;
              break;
            case c14.TERTIARY:
              c14_value = c14.TERTIARY;
              break;
          }
          str = str.replace(regc14, c14_value);
          break;

        case "c15":
          switch (mode.value) {
            case c15.LOWEST:
              c15_value = c15.LOWEST;
              break;
            case c15.LOW:
              c15_value = c15.LOW;
              break;
            case c15.HIGH:
              c15_value = c15.HIGH;
              break;
          }
          str = str.replace(regc15, c15_value);
          break;

        case "c16":
          if (mode.value == c16.LIGHT) {
            c16_value = c16.LIGHT;
          } else {
            c16_value = c16.DARK;
          }
          str = str.replace(regc16, c16_value);
          break;
      }
    });

    localStorage.setItem("mode", str);

    if (tab) {
      this.mobileTheme(tab);
      let data = { ...{ tab: tab } };
      this._updateBuilderComponentContainer.next(data);
    }
  }
  mobileTheme(tab?: any) {
    let mode = localStorage.getItem("mode");
    let data: any = {};

    if (mode && mode.length != 16) {
      localStorage.setItem("mode", "ahmtwbsttw3a3p0l");
      mode = "ahmtwbsttw3a3p0l";
    }

    switch (mode[0]) {
      case c1.ANDRIOD:
        {
          switch (mode[1]) {
            case c2.FULL:
              {
                //
                data["emptyImage"] = this.afull;
                data["view"] = MobileView.a_full;
                data["roundBottom"] = true;
                data["sidemode"] = false;
                if (tab) {
                  data["image"] =
                    common_components_map[tab.module].android_url_f;
                }
              }
              break;
            case c2.HOME:
            case c2.SIDE:
              //
              {
                if (mode[7] == c8.NOSIDE) {
                  data["hideside"] = true;
                } else {
                  data["hideside"] = false;
                }
                data["sidemode"] = mode[1] == c2.SIDE ? true : false;
                data["emptyImageNavDrawerBar"] = this.aSide;

                switch (mode[2]) {
                  case c3.SINGLE:
                    // single tab
                    {
                      data["emptyImage"] = this.aLong;
                      data["emptyImageTop"] = this.atopBar;
                      data["view"] = MobileView.a_main_tab;
                      data["roundBottom"] = true;
                      if (tab) {
                        data["image"] =
                          common_components_map[tab.module].android_url_l;
                      }
                    }
                    break;

                  case c3.NOTAB:
                    // no tab
                    {
                      data["emptyImage"] = this.aLong;
                      data["emptyImageTop"] = this.atopBar;
                      data["view"] = MobileView.a_main_notab;
                      data["roundBottom"] = true;
                      if (tab) {
                        data["image"] =
                          common_components_map[tab.module].android_url_l;
                      }
                    }
                    break;

                  case c3.MANYTAB:
                    // many tabs
                    switch (mode[3]) {
                      case c4.TOP:
                        // Top wide
                        if (mode[4] == c5.WIDE) {
                          data["emptyImage"] = this.aShortS;
                          data["emptyImageTop"] = this.atopBar;
                          data["emptyImageNavBar"] = this.anavBarL;
                          data["view"] = MobileView.a_main_many_top_wide;
                          data["roundBottom"] = false;
                          if (tab) {
                            data["image"] =
                              common_components_map[tab.module].android_url_s;
                          }
                        } else {
                          // Top Standard
                          data["emptyImage"] = this.aShort;
                          data["emptyImageTop"] = this.atopBar;
                          data["emptyImageNavBar"] = this.anavBarS;
                          data["view"] = MobileView.a_main_many_top_std;
                          data["roundBottom"] = true;
                          if (tab) {
                            data["image"] =
                              common_components_map[tab.module].android_url_s;
                          }
                        }

                        break;
                      case c4.BOTTOM:
                        // Bottom-wide
                        if (mode[4] == c5.WIDE) {
                          data["emptyImage"] = this.aShortS;
                          data["emptyImageTop"] = this.atopBar;
                          data["emptyImageNavBar"] = this.anavBarL;
                          data["view"] = MobileView.a_main_many_bottom_wide;
                          data["roundBottom"] = false;
                          if (tab) {
                            data["image"] =
                              common_components_map[tab.module].android_url_s;
                          }
                        } else {
                          //Bottom Standard
                          data["emptyImage"] = this.aShort;
                          data["emptyImageTop"] = this.atopBar;
                          data["emptyImageNavBar"] = this.anavBarS;
                          data["view"] = MobileView.a_main_many_bottom_std;
                          data["roundBottom"] = false;
                          if (tab) {
                            data["image"] =
                              common_components_map[tab.module].android_url_s;
                          }
                        }
                        break;
                    }
                    break;
                }
              }

              break;
            case c2.COMPONENT:
              // Component
              {
                data["emptyImage"] = this.aLong;
                data["emptyImageTop"] = this.atopBar;
                data["view"] = MobileView.a_component;
                data["backNavComponent"] = "ture";
                data["roundBottom"] = true;
                data["sidemode"] = false;
                if (tab) {
                  data["image"] =
                    common_components_map[tab.module].android_url_l;
                }
              }
              break;
          }
        }
        break;
      case c1.IOS:
        {
          switch (mode[1]) {
            case c2.FULL:
              //
              data["emptyImage"] = this.ifull;
              data["view"] = MobileView.i_full;
              data["roundBottom"] = true;
              data["sidemode"] = false;
              if (tab) {
                data["image"] = common_components_map[tab.module].ios_url_f;
              }
              break;
            case c2.HOME:
            case c2.SIDE:
              if (mode[7] == c9.NOSIDE) {
                data["hideside"] = true;
              } else {
                data["hideside"] = false;
              }

              data["sidemode"] = mode[1] == c2.SIDE ? true : false;

              switch (mode[2]) {
                case c3.SINGLE:
                  // single tab
                  {
                    if (mode[6] == c7.STANDARD) {
                      data["emptyImage"] = this.iLong;
                      data["emptyImageTop"] = this.itopBar;
                      data["emptyImageTitle"] = null;
                      data["emptyImageNavBar"] = null;
                      data["view"] = MobileView.i_main_tab;
                      data["backNavComponent"] = "ture";
                      data["roundBottom"] = true;
                      if (tab) {
                        data["image"] =
                          common_components_map[tab.module].ios_url_l;
                      }
                    } else {
                      data["emptyImage"] = this.iLongS;
                      data["emptyImageTop"] = this.itopBar;
                      data["emptyImageTitle"] = this.itopbarTitle;
                      data["emptyImageNavBar"] = null;
                      data["view"] = MobileView.i_main_tab;
                      data["backNavComponent"] = "ture";
                      data["roundBottom"] = true;
                      if (tab) {
                        data["image"] =
                          common_components_map[tab.module].ios_url_l;
                      }
                    }
                  }
                  break;
                case c3.NOTAB:
                  // no tab
                  {
                    data["emptyImage"] = this.iLong;
                    data["emptyImageTop"] = this.itopBar;
                    data["emptyImageTitle"] = null;
                    data["view"] = MobileView.i_main_notab;
                    data["backNavComponent"] = "ture";
                    data["roundBottom"] = true;
                    if (tab) {
                      data["image"] =
                        common_components_map[tab.module].ios_url_l;
                    }
                  }
                  break;
                case c3.MANYTAB:
                  // many tabs
                  switch (mode[5]) {
                    // c6: ios home side menu- top, bottom

                    case c6.BOTTOM:
                      if (mode[6] == c7.STANDARD) {
                        data["emptyImage"] = this.iShort;
                        data["emptyImageTop"] = this.itopBar;
                        data["emptyImageTitle"] = null;
                        data["emptyImageNavBar"] = this.inavBar;
                        data["view"] = MobileView.i_main_bottom_small;
                        data["roundBottom"] = false;
                        if (tab) {
                          data["image"] =
                            common_components_map[tab.module].ios_url_s;
                        }
                      } else {
                        data["emptyImage"] = this.iShortS;
                        data["emptyImageTop"] = this.itopBar;
                        data["emptyImageTitle"] = this.itopbarTitle;
                        data["emptyImageNavBar"] = this.inavBar;
                        data["view"] = MobileView.i_main_bottom_large;
                        data["roundBottom"] = false;
                        if (tab) {
                          data["image"] =
                            common_components_map[tab.module].ios_url_s;
                        }
                      }

                      break;

                    case c6.TOP:
                      if (mode[6] == c7.STANDARD) {
                        data["emptyImage"] = this.iShort;
                        data["emptyImageTop"] = this.itopBar;
                        data["emptyImageTitle"] = null;
                        data["emptyImageNavBar"] = this.inavBar;
                        data["view"] = MobileView.i_main_top_small;
                        data["backNavComponent"] = "ture";
                        data["roundBottom"] = false;
                        if (tab) {
                          data["image"] =
                            common_components_map[tab.module].ios_url_s;
                        }
                      } else {
                        data["emptyImage"] = this.iShortS;
                        data["emptyImageTop"] = this.itopBar;
                        data["emptyImageTitle"] = this.itopbarTitle;
                        data["emptyImageNavBar"] = this.inavBar;
                        data["view"] = MobileView.i_main_top_large;
                        data["backNavComponent"] = "ture";
                        data["roundBottom"] = false;
                        if (tab) {
                          data["image"] =
                            common_components_map[tab.module].ios_url_s;
                        }
                      }

                      break;
                  }
                  break;
              }

              break;

            case c2.COMPONENT:
              //
              {
                data["emptyImage"] = this.iLong;
                data["emptyImageTop"] = this.itopBar;
                data["emptyImageTitle"] = null;
                data["emptyImageNavBar"] = null;
                data["view"] = MobileView.i_component;
                data["backNavComponent"] = "ture";
                data["roundBottom"] = true;
                data["sidemode"] = false;

                if (tab) {
                  data["image"] = common_components_map[tab.module].ios_url_l;
                }
              }
              break;
          }
        }
        break;
    }
    return data;
  }
  async saveOnlineAppConfig(data, migration) {
    let dbData: any = {};
    dbData = data;
    if (data && data.app) {
      if (data.app.tabs && data.app.tabs.tabs) {
        this.tabsInsertion(dbData.app.tabs, "tabs", "side");
      }
      // add
      if (!dbData.id) {
        dbData.id = "side";
      }
      // clean up
      if (dbData && dbData.app && dbData.app.side_menu) {
        if (migration) {
          this.tabsInsertion(dbData.app.side_menu, "menus", "side");
        }
        delete dbData.app.side_menu;
      }

      if (dbData && dbData.app && dbData.app.tabs && dbData.app.tabs.tabs) {
        delete dbData.app.tabs.tabs;
      }

      if (dbData && dbData.app && dbData.app.app_info) {
        delete dbData.app.app_info;
      }

      if (dbData && dbData.app && dbData.app.tab) {
        dbData.app["tabs"] = { tabs: [] };
      }

      await this.indexDBService.insertItem("app", dbData);
    } else {
      // no data
      let newData: any;
      newData = { id: "side", app: { menus: [], tabs: { tabs: [] } } };
      await this.indexDBService.insertItem("app", newData);
    }
  }
  async saveAppConfig(data) {
    if (data && data.app) {
      await this.saveItem(data, "settings");
      await this.saveItem(data, "forms");
      await this.saveItem(data, "channel_default");
      await this.saveItem(data, "version");

      if (!data.app.system.chat_label) {
        data.app.system["chat_label"] = {
          labels: [{ id: "0", name: "Default" }],
        };
      }
      await this.saveSystemItem(data, "chat_label");

      // save AppConfig.system item
      //await this.saveSystemItem(data, "color");

      if (!data.app.login_type) {
        data.app["login_type"] = "NO_LOGIN";
      }
      await this.saveSystemAppInfoItem(data, "app_info");
      // save AppConfig.system.app_info item

      await this.saveAppInfoItem(data, "logo_color");
      // await this.saveAppInfoItem(data, "logo_color_ios");
      await this.saveAppInfoItem(data, "logo_white");

      await this.saveItem(data, "splash");
    } else {
      let newData: any;
      newData = {
        app: {
          system: {
            app_info: {
              id: "app_info",
              channel_name: "newApp",
              logo_color: { id: "logo_color" },
              logo_white: { id: "logo_white" },
              login_type: "NO_LOGIN",
            },
            chat_label: {
              id: "chat_label",
              labels: [{ id: "0", name: "Default" }],
            },
          },
          version: { value: 0, id: "version" },
        },
      };

      await this.saveSystemAppInfoItem(newData, "app_info");
      await this.saveAppInfoItem(newData, "logo_color");
      await this.saveAppInfoItem(newData, "logo_white");

      await this.saveSystemItem(newData, "chat_label");
      await this.saveItem(newData, "version");
    }

    if (
      data &&
      data.app &&
      data.app.splash &&
      data.app.splash.id &&
      data.app.splash.tab_id &&
      this.AppConfigVersion > 0
    ) {
      //new
      let screens = data.app.splash.screens;
      let tab: any = { param: { splash: {} } };
      tab["param"]["splash"] = data.app.splash;
      tab.id = data.app.splash.tab_id;
      tab.cat = "splash";
      tab.module = "splash";
      tab.type = "splash";
      tab.order = 0;
      tab.tab_version = data.app.splash.version;
      tab.menu_id = data.app.splash.menu_id;

      delete tab.version;
      delete tab.screens;
      await this.indexDBService.insertItem("tab", tab);
      ///////////  add the screens ///////////////////////
      screens.forEach((screen) => {
        if (screen) {
          let menu: any = { cat: "splash" };
          menu.cat = "splash";
          menu.menu_id = screen.menu_id;
          menu.menu_ref = screen.menu_id;
          menu.menu_version = screen.menu_id;
          menu.menu_group = tab.id;
          menu.menu_order = screen.order;

          let row: any = { row_id: "0" };
          row.row_id = menu.menu_id;
          row.menu_id = menu.menu_id;
          row.row_version = menu.menu_id;
          row.row_order = 0;
          let button: any = {
            button_id: "",
            info: {},
            button_images: {
              "01": { url: null, imageSet: [] },
              default: { url: null, imageSet: [] },
            },
          };

          // button = structuredClone(menu_button_map[data.app.splash.style]);
          button.button_id = screen.id;
          button.button_callback = screen.id;
          button.button_version = screen.version;
          button.button_code = data.app.splash.style;
          button.button_form = "splash";
          button.button_type = "splash";
          button.button_style = data.app.splash.style;
          button.button_order = 0;
          button.button_label = screen.title;
          button.button_sublabel = screen.desc;
          button.button_img_url = screen.image;
          button.button_img_urlSet = screen.image_set;
          if (data.app.splash.style === "01") {
            button.button_images["01"].url = screen.image;
            button.button_images["01"].imageSet = screen.image_set;
          } else {
            button.button_images["default"].url = screen.image;
            button.button_images["default"].imageSet = screen.image_set;
          }
          button.button_bgimage = screen.bg_image;
          button.button_bgimageSet = screen.bg_image_set;
          button.button_bgstart = screen.start_color;
          button.button_bgend = screen.end_color;
          button.row_id = row.row_id;

          let button_code = "splash_" + button.button_code;
          button.info = menu_button_map[button_code].info;

          this.indexDBService.insertItem("menu", menu);
          this.indexDBService.insertItem("row", row);
          this.indexDBService.insertItem("button", button);
        }
      });
    } else {
      //old do nothing.
      this.createNewSplash(); //temp commeted out
    }
  }

  async saveItem(data, item) {
    let dbData: any = {};
    if (data.app[item]) {
      dbData = data.app[item];
      if (!dbData.id) {
        dbData.id = item;
      }
      await this.indexDBService.insertItem("item", dbData);
    }
  }

  async saveSystemItem(data, item) {
    let dbData: any = {};
    if (data.app.system && data.app.system[item]) {
      dbData = data.app.system[item];
      if (!dbData.id) {
        dbData.id = item;
      }
      await this.indexDBService.insertItem("item", dbData);
    }
  }

  async saveSystemAppInfoItem(data, item) {
    let dbData: any = {};
    if (data.app.system && data.app.system[item]) {
      dbData = data.app.system[item];
      if (!dbData.id) {
        dbData.id = item;
      }
      if (!dbData.logo_color) {
        dbData.logo_color = {};
      }
      if (!dbData.logo_white) {
        dbData.logo_white = {};
      }

      await this.indexDBService.insertItem("item", dbData);
    }
  }

  async saveAppInfoItem(data, item) {
    let dbData: any = {};
    if (
      data.app.system &&
      data.app.system.app_info &&
      data.app.system.app_info[item]
    ) {
      dbData = data.app.system.app_info[item];
      if (!dbData.id) {
        dbData.id = item;
      }
      await this.indexDBService.insertItem("item", dbData);
    }
  }

  createNewSplash() {
    /// create new tab
    // let id = this.mdw.makeRef(15);
    let id = "splash"; // same id to prevent duplicate splash tab. Only one tab for splash should exist.
    let style = "01";
    let tabList = {};
    let current_tab: { cat: null; module: null };
    let sortedTabList = [];
    let menuList = {};
    let sortedMenuList = [];
    let menu: Menu;
    let row: MenuRow;
    let button: MenuButton;
    let max_buttons: number = 6;

    let defaultRow: MenuRow = {
      row_id: "0",
      row_order: 0,
      menu_id: "0",
      buttons: [],
    };

    let defaultButton: MenuButton = {
      button_id: "0",
      row_id: "0",
      button_callback: "0",
      button_style: "0",
      button_style_ios: "0",
      button_form: "splash",
      button_code: "splash",
      button_order: 0,
      info: { min_grid: 60 },
    };

    tabList[id] = structuredClone(common_components_map["splash"]);
    tabList[id].id = id;
    // this.tabList[id].param.splash= structuredClone(splash_config_map[style].tab_part);
    tabList[id].param.splash.style = style;
    sortedTabList.push(tabList[id]);
    current_tab = tabList[id];

    /// create a new menu
    if (current_tab.cat == "splash") {
      // let menu_id = "m_" + this.makeRef(15);
      let menu_id = "m_6XIox0e0fWF2oFy";

      tabList[id]["menu_id"] = menu_id;

      let newMenu: Menu = {
        menu_id: menu_id,
        menu_ref: menu_id,
        menu_version: menu_id,
        cat: "splash",
        menu_group: id,
        menu_order: 0,
      };
      menu = newMenu;
      /// create new row and button
      // let row_id = "r_" + this.makeRef(15);
      // let button_id = "b_" + this.makeRef(15);

      let row_id = "r_6XIox0e0fWF2oFy";
      let button_id = "b_6XIox0e0fWF2oFy";

      // add row
      let newRow = { ...structuredClone(defaultRow) };
      newRow.row_id = row_id;
      newRow.row_version = row_id;
      newRow.row_order = 0;
      newRow.menu_id = menu_id;
      newRow.row_lock = true;

      row = newRow;

      let button_style = "splash_" + style;
      // add button
      let newButton: any = {};
      newButton = structuredClone(menu_button_map[button_style]);

      // get it per template when switching //
      newButton.button_img_url = null;
      newButton.button_img_urlSet = null;
      newButton.button_images = { "01": { url: null }, default: { url: null } };
      newButton.button_bgimage = null;
      newButton.button_bgimageSet = null;
      newButton.button_bgstart = null;
      newButton.button_bgend = null;
      newButton.button_label = null;
      /////////////////////////////////////

      newButton.button_id = button_id;
      newButton.button_version = button_id;
      newButton.row_id = row_id;
      newButton.button_callback = button_id;
      newButton.button_order = 0;

      button = newButton;

      /// update tab
      let tabdata = {
        tab: current_tab,
        mode: localStorage.getItem("mode"),
      };
      this.changeTab(tabdata, localStorage.getItem("mode"), false);

      let Menudata = {
        tab: { module: "splash" },
        menu: newMenu,
        row: newRow,
        button: newButton,
      };

      this.changeTab(Menudata, localStorage.getItem("mode"), true);

      /////////////

      sortedMenuList.push(menu);
      menuList[menu.menu_id] = menu;

      let menuData: any = {
        rowList: {},
        sortedRowList: [],
        buttonList: {},
        sortedButtonList: [],
      };

      menuData["rowList"][row_id] = { ...newRow };
      menuData["sortedRowList"] = [
        ...menuData["sortedRowList"],
        menuData["rowList"][row_id],
      ];
      menuData["buttonList"][button_id] = { ...newButton };
      menuData["sortedButtonList"][row_id] = [];
      menuData["sortedButtonList"][row_id] = [
        ...menuData["sortedButtonList"][row_id],
        newButton,
      ];
      menuData["currentButton"] = { ...newButton };

      menuData["tab"] = current_tab;
      menuData["defaultRow"] = defaultRow;
      menuData["defaultButton"] = defaultButton;
      menuData["max_buttons"] = max_buttons;
      menuData["menu"] = menu;
      menuData["module"] = current_tab.module;
      menuData["mode"] = localStorage.getItem("mode");

      menuData["menuList"] = menuList;
      menuData["sortedMenuList"] = sortedMenuList;

      this._currentMenuContainer.next(menuData); // to update menupages
    }
  }
  async sendAppConfig() {
    await this.saveMobileViewMode();

    let onlineAppConfig = await this.constructOnlineAppConfig();
    let onlineChannelConfig = await this.constructOnlineChannelConfig(onlineAppConfig.app.tabs.tabs);

    // console.log('onlineAppConfig =>', onlineAppConfig)
    let appConfig = await this.constructAppConfig();

    //  console.log('appConfig =>', appConfig)
    // let menus: any = await this.constructMenus("home");

    // copy in the side configuration as temp solution Hazem
    onlineChannelConfig.app.tabs['default']['channel'] = appConfig.app.channel_default;
    delete appConfig.app.channel_default

    let data: any = {};
    data.appConfig = appConfig;
    data.onlineAppConfig = onlineAppConfig;
    data.onlineChannelConfig = onlineChannelConfig;


    if (appConfig && appConfig.app && appConfig.app.version) {
      this.indexDBService.updateItem("item", appConfig.app["version"]);
    }
    return data;
  }

  async saveMobileViewMode() {
    // c1: android and ios
    // c2: full, home, side, component
    // c3: single, notab, manytab
    // c4: android home- top, bottom
    // c5: android home- wide and narraw
    // c6: ios home - side menu top, bottom
    // c7: ios home - standard and large title
    // c8: android no side(false) and side (true)
    // c9: ios no side(false) and side (true)
    // c10: ios home- wide and narraw

    localStorage.getItem("mode");
    await this.appHomeModeUpdate("mode", localStorage.getItem("mode"));
  }
  async appHomeModeUpdate(item, value) {
    return await this.indexDBService
      .getItemOnsucss("app", "home")
      .then(async (appHome) => {
        if (appHome) {
          let appHomeData: any = {};
          appHomeData = appHome;
          appHomeData.app.tabs[item] = value;
          if (value[3] == c4.TOP && value[4] == c5.WIDE) {
            appHomeData.app.tabs["tab_style"] = 0;
          }
          if (value[3] == c4.BOTTOM && value[4] == c5.WIDE) {
            appHomeData.app.tabs["tab_style"] = 1;
          }
          if (value[3] == c4.TOP && value[4] == c5.STANDARD) {
            appHomeData.app.tabs["tab_style"] = 2;
          }
          if (value[3] == c4.BOTTOM && value[4] == c5.STANDARD) {
            appHomeData.app.tabs["tab_style"] = 3;
          }
          if (value[9] == c10.WIDE) {
            appHomeData.app.tabs["tab_style_ios"] = 0;
          }
          if (value[9] == c10.STANDARD) {
            appHomeData.app.tabs["tab_style_ios"] = 1;
          }
          if (value[6] == c7.LARGE) {
            appHomeData.app.tabs["large_title"] = 1;
          } else {
            appHomeData.app.tabs["large_title"] = 0;
          }
          this.indexDBService.updateItem("app", appHomeData);
        }
        return true;
      });
  }

  async constructOnlineChannelConfig(sideTabList) {
    let data: any = {};
    return await this.indexDBService
      .getItemOnsucss("app", "home")
      .then(async (response) => {
        if (response) {
          data = response;
          // check for tabs
          if (data.app) {
            if (data.app.tabs && data.app.tabs.tabs) {
              return await this.indexDBService
                .getItemListOnsucss("tab", "cat", "home")
                .then(async (tabResponse) => {
                  data.app.tabs.tabs = tabResponse;
                  return await this.indexDBService
                    .getItemListOnsucss("tab", "cat", "next")
                    .then(async (tapMenu: Tab[]) => {
                      data.app.tabs.tabs = [...data.app.tabs.tabs, ...tapMenu];
                      let tempList = this.sortTabs(data.app.tabs.tabs);
                      data.app.tabs.tabs = [...tempList];
                      const storeTabsList = [...sideTabList, ...tempList];
                      data.app.menus = await this.constructMenusALLSync(
                        "menu",
                        "app",
                        storeTabsList
                      );

                      //save
                      this.constructAppStoreMenu(storeTabsList, "store");
                      this.constructAppStoreMenu(storeTabsList, "market");

                      return data;
                    });
                });
            } else {
              return data;
            }
          }
        }
      });
  }

  async constructOnlineAppConfig() {
    let data: any = { app: { tabs: { tabs: [{}] } } };
    return await this.indexDBService
      .getItemOnsucss("app", "side")
      .then(async (response) => {
        if (response) {
          data = response;
          // check for tabs
          if (data.app) {
            return await this.indexDBService
              .getItemListOnsucss("tab", "cat", "side")
              .then(async (tabResponse) => {
                data.app.tabs = {};
                data.app.tabs.tabs = tabResponse;
                let tempList = this.sortTabs(data.app.tabs.tabs);
                data.app.tabs.tabs = [...tempList];
                return data;
              });
          }
        }
      });
  }

  async constructAppConfig() {
    ///Saving Splash first //////
    await this.constructSplash();
    /////////////////////////////
    let data: any = {};
    data.app = {};
    data.app.system = {};

    return await this.indexDBService
      .getItemOnsucss("item", "version")
      .then(async (versionResponse) => {
        if (versionResponse) {
          data.app.version = versionResponse;
          data.app.version.value++;
        }
        return await this.indexDBService
          .getItemOnsucss("item", "channel_default")
          .then(async (channelDefault) => {
            if (channelDefault) {
              data.app.channel_default = channelDefault;
            }
            return await this.indexDBService
              .getItemOnsucss("item", "settings")
              .then(async (settingsResponse) => {
                if (settingsResponse) {
                  data.app.settings = settingsResponse;
                }
                return await this.indexDBService
                  .getItemOnsucss("item", "splash")
                  .then(async (splashResponse) => {
                    if (splashResponse) {
                      data.app.splash = splashResponse;
                    }
                    return await this.indexDBService
                      .getItemOnsucss("item", "color")
                      .then(async (colorResponse) => {
                        if (colorResponse) {
                          data.app.system.color = colorResponse;
                        }

                        return await this.indexDBService
                          .getItemOnsucss("item", "chat_label")
                          .then(async (chatLabelResponse) => {
                            if (chatLabelResponse) {
                              data.app.system.chat_label = chatLabelResponse;
                            }

                            return await this.indexDBService
                              .getItemOnsucss("item", "app_info")
                              .then(async (response) => {
                                if (response) {
                                  data.app.system.app_info = response;
                                  // check for tabs
                                  return await this.indexDBService
                                    .getItemOnsucss("item", "logo_color")
                                    .then(async (logoColor) => {
                                      if (logoColor) {
                                        data.app.system.app_info.logo_color =
                                          logoColor;
                                      }
                                      return await this.indexDBService
                                        .getItemOnsucss(
                                          "item",
                                          "logo_color_ios"
                                        )
                                        .then(async (logoColorIos) => {
                                          if (logoColorIos) {
                                            data.app.system.app_info.logo_color_ios =
                                              logoColorIos;
                                          }
                                          return await this.indexDBService
                                            .getItemOnsucss(
                                              "item",
                                              "logo_white"
                                            )
                                            .then(async (logoWhite) => {
                                              if (logoWhite) {
                                                data.app.system.app_info.logo_white =
                                                  logoWhite;
                                              }
                                              return data;
                                            });
                                        });
                                    });
                                } else {
                                  return data;
                                }
                              });
                          });
                      });
                  });
              });
          });
      });
  }

  async constructSplash() {
    let splash: any = { screens: [] };

    return await this.indexDBService
      .getItemListOnsucss("tab", "cat", "splash")
      .then(async (tabResponse) => {
        if (tabResponse && tabResponse[0] != null) {
          let tab = tabResponse[0];
          // splash = tab.param.splash;
          splash = this.getAlltabSplashItem(
            tab.param.splash,
            tab.param.splash.style
          );
          splash.style = tab.param.splash.style;
          splash.version = tab.tab_version;
          splash.id = "splash";
          splash.menu_id = tab.menu_id;
          splash.tab_id = tab.id;

          let menus = await this.constructMenusALLSync("menu", "splash");
          if (menus) {
            let tempList = this.sortMenus(menus);
            menus = tempList;
            let screens = [];
            menus.forEach((menu) => {
              if (menu && menu.rows) {
                menu.rows.forEach((row) => {
                  if (row && row.buttons) {
                    row.buttons.forEach((button) => {
                      if (button) {
                        let splashScreen = {};
                        splashScreen["order"] = menu.menu_order;
                        splashScreen["menu_id"] = menu.menu_id;
                        splashScreen["code"] = tab.param.splash.style;
                        splashScreen["id"] = button.button_id;

                        // data start
                        splashScreen["title"] = this.readSplashItem(
                          button,
                          "button_label",
                          splash.style
                        );
                        splashScreen["desc"] = this.readSplashItem(
                          button,
                          "button_sublabel",
                          splash.style
                        );

                        splashScreen["image"] = this.readSplashItem(
                          button,
                          "images",
                          splash.style
                        ).url;
                        splashScreen["image_set"] = this.readSplashItem(
                          button,
                          "images",
                          splash.style
                        ).imageSet;

                        splashScreen["bg_image"] = this.readSplashItem(
                          button,
                          "images",
                          splash.style
                        ).bgImage;
                        splashScreen["bg_image_set"] = this.readSplashItem(
                          button,
                          "images",
                          splash.style
                        ).bgImageSet;

                        splashScreen["start_color"] = this.readSplashItem(
                          button,
                          "button_bgstart",
                          splash.style
                        );
                        splashScreen["end_color"] = this.readSplashItem(
                          button,
                          "button_bgend",
                          splash.style
                        );

                        // data end
                        splashScreen["version"] = button.button_version;
                        screens.push(splashScreen);
                      }
                    });
                  }
                });
              }
            });
            splash.screens = screens;
          }
          const response = this.indexDBService.updateItem("item", splash);
          response.onsuccess = (event) => {
            if (response.result) {
              return splash;
            }
          };
        }
      });
  }

  getAlltabSplashItem(splash, style) {
    let data: any = {};
    data["title"] = this.getTabSplashItem(splash, "title", style);
    data["button_title"] = this.getTabSplashItem(splash, "button_title", style);
    data["button_bgcolor"] = this.getTabSplashItem(
      splash,
      "button_bgcolor",
      style
    );
    data["button_text_color"] = this.getTabSplashItem(
      splash,
      "button_text_color",
      style
    );
    data["tnc_text_color"] = this.getTabSplashItem(
      splash,
      "tnc_text_color",
      style
    );
    data["tnc_link_color"] = this.getTabSplashItem(
      splash,
      "tnc_link_color",
      style
    );
    if (style == "02" || style == "06" || style == "07") {
      data["tnc_bgcolor"] = null;
    } else {
      data["tnc_bgcolor"] = this.getTabSplashItem(splash, "tnc_bgcolor", style);
    }

    return data;
  }

  getTabSplashItem(splash, item, style) {
    if (item == "tnc_bgcolor") {
      return splash[item] !== null
        ? style == "02"
          ? null
          : splash[item]
        : splash_config_map[style].tab_part[item];
    } else {
      return splash[item] !== null
        ? splash[item]
        : splash_config_map[style].tab_part[item];
    }
  }

  readSplashItem(button, item, style) {
    if (style) {
      if (item === "button_bgstart") {
        return button.button_bgstart !== null
          ? button.button_bgstart
          : splash_config_map[style].button_part.start_color;
      }

      if (item === "button_bgend") {
        return button.button_bgend !== null
          ? button.button_bgend
          : splash_config_map[style].button_part.end_color;
      }

      if (item === "button_label") {
        return button.button_label !== null
          ? button.button_label
          : splash_config_map[style].button_part.title;
      }

      if (item === "button_sublabel") {
        return button.button_sublabel !== null
          ? button.button_sublabel
          : splash_config_map[style].button_part.desc;
      }

      if (item === "images") {
        let images: any = {};

        switch (style) {
          //we will carry everything except image and bgimage
          case SPLASH_STYLE.STYLE_01:
            images["url"] =
              button.button_images["01"].url !== null
                ? button.button_images["01"].url
                : splash_config_map[style].button_part.images["01"].url;
            images["imageSet"] =
              button.button_images["01"].url !== null
                ? button.button_images["01"].imageSet
                : splash_config_map[style].button_part.images["01"].imageSet;
            images["bgImage"] = null;
            images["bgImageSet"] = null;

            return images;
            break;

          case SPLASH_STYLE.STYLE_03:
            images["url"] = null;
            images["imageSet"] = null;
            images["bgImage"] =
              button.button_bgimage !== null
                ? button.button_bgimage
                : splash_config_map[style].button_part.bg_image;
            images["bgImageSet"] =
              button.button_bgimageSet !== null
                ? button.button_bgimageSet
                : splash_config_map[style].button_part.bg_imageSet;

            return images;
            break;

          default:
            images["url"] =
              button.button_images["default"].url !== null
                ? button.button_images["default"].url
                : splash_config_map[style].button_part.images[style].url;
            images["imageSet"] =
              button.button_images["default"].url !== null
                ? button.button_images["default"].imageSet
                : splash_config_map[style].button_part.images[style].imageSet;
            images["bgImage"] = null;
            images["bgImageSet"] = null;
            return images;
            break;
        }
      }
    }
  }

  async constructMenusALLSync(table, cat, tabList?) {
     return await this.indexDBService
      .getItemListOnsucss(table, null, null)
      .then(async (menuResponse: any) => {
        let menus = menuResponse;
        let newMenus = [];
        // filter all tabs to get tab for stores and get their menu Group and only include those menus into the configuraiton.
        let stores = [];
        let workflows = [];
        if (tabList) {
          for (let i = 0; i < tabList.length; i++) {
            switch (tabList[i].module) {
              case "menu":
                workflows.push(tabList[i].id);
                break;
              case "store":
                stores.push(tabList[i].menu_group);
                break;

             case "market":
             stores.push(tabList[i].menu_group);
              break;
            }
          }
        }
        for (let i = 0; i < menus.length; i++) {
          switch (cat) {
            case "app":
              //all
              if (menus[i].cat != "splash" && menus[i].cat != "poll") {
                if (menus[i].cat == "menu" && workflows.length > 0) {
                  for (let j = 0; j < workflows.length; j++) {
                    if (menus[i].menu_group == workflows[j]) {
                      menus[i].rows = await this.constructMenuRowsSync(
                        menus[i].menu_id,
                        true
                      );
                      newMenus.push(menus[i]);
                    }
                  }
                } else if ((menus[i].cat == "store" ||menus[i].cat == "market")&& stores.length > 0) {
                  for (let j = 0; j < stores.length; j++) {
                    if (menus[i].menu_group == stores[j]) {
                      (menus[i].rows = await this.constructMenuRowsSync(
                        menus[i].menu_id
                      )),
                        true;
                      newMenus.push(menus[i]);
                    }
                  }
                }
              }
              break;
            case "splash":
              if (menus[i].cat == "splash") {
                menus[i].rows = await this.constructMenuRowsSync(
                  menus[i].menu_id
                );
                newMenus.push(menus[i]);
              }
              break;

            case "store":
              //only store
              if (menus[i].cat == "store") {
                menus[i].rows = await this.constructMenuRowsSync(
                  menus[i].menu_id,
                  true
                );
                newMenus.push(menus[i]);
              }
              break;
              case "market":
                //only store
                if (menus[i].cat == "store") {
                  menus[i].rows = await this.constructMenuRowsSync(
                    menus[i].menu_id,
                    true
                  );
                  newMenus.push(menus[i]);
                }
                break;

            case "poll":
              //only poll
              if (menus[i].cat == "poll") {
                menus[i].rows = await this.constructMenuRowsSync(
                  menus[i].menu_id,
                  true
                );
                newMenus.push(menus[i]);
              }
              break;
          }
        }

        return newMenus;
      });
  }

  async constructMenus(query) {
    return await this.indexDBService
      .getItemListOnsucss("menu", "cat", query)
      .then(async (menuResponse: any) => {
        let menus = [];
        menuResponse.forEach(async (menu) => {
          let newMenu = menu;
          newMenu.rows = await this.constructMenuRows(menu.menu_id);
          menus.push(newMenu);
        });
        return await menus;
      });
  }
  async constructAppStoreMenu(ApptabList, category?) {
    return await this.indexDBService
      .getItemListOnsucss("tab", "cat", "category")
      .then(async (tabResponse) => {
        if (tabResponse) {
          let stores = [];
          let tabStoreList: any;
          let AppStoreTabIdList = [];
          tabStoreList = tabResponse;
          for (let i = 0; i < ApptabList.length; i++) {
            if (ApptabList[i].module == "category") {
              AppStoreTabIdList.push(ApptabList[i].menu_group);
            }
          }
          for (let i = 0; i < tabStoreList.length; i++) {
            if (AppStoreTabIdList.includes(tabResponse[i].id)) {
              let tab = {};
              tab = tabResponse[i];
              // tab params required
              tab = this.getAlltabStoreItem(tabResponse[i]);
              let shop = await this.constructMenusbyMenuGroupSync(
                tabResponse[i].menu_group
              );
              tab["shop"] = shop;
              stores.push(tab);
            }
          }
          this._storeContainer.next(stores);
        }
      });
  }

  async constructStore(store_id) {
    return await this.indexDBService
      .getItemOnsucss("tab", store_id)
      .then(async (tabResponse) => {
        if (tabResponse) {
          let stores = [];
          let tab = {};
          tab = tabResponse;
          // tab params required
          tab = this.getAlltabStoreItem(tab);
          let shop = await this.constructMenusbyMenuGroupSync(store_id);
          tab["shop"] = shop;
          stores.push(tab);
          this._storeContainer.next(stores);
        }
      });
  }

  getAlltabStoreItem(tab) {
    console.log(tab);
    let data: any = {};
    data["id"] = tab.menu_group;
    data["menu_id"] = tab.menu_id;

    console.log("$$$$$$$$$$$$$tab.cat$$$$$$$$$$$$$$$$$", tab.cat);
    data["is_default"] = localStorage.getItem(tab.cat)
      ? tab.id == localStorage.getItem(tab.cat)
        ? 1
        : 0
      : 1;

    data["name"] = tab.title;
    data["description"] = tab.desc;
    data["image"] = [{ url: tab.image_url }];
    data["version"] = tab.tab_version;
    data["category"] = tab.cat;
    if (tab.style) {
      data["style"] = tab.style;
    }

    return data;
  }

  async constructPoll(store_id) {
    return await this.indexDBService
      .getItemOnsucss("tab", store_id)
      .then(async (tabResponse) => {
        if (tabResponse) {
          let polls = [];
          let tab = {};
          tab = tabResponse;
          // tab params required
          tab = this.getAlltabStoreItem(tab);
          let menus = await this.constructMenusbyMenuGroupSync(store_id);
          tab["menus"] = menus;
          polls.push(tab);
          this._pollContainer.next(polls);
        }
      });
  }

  getAlltabPollItem(tab) {
    let data: any = {};
    data["id"] = tab.menu_group;
    data["menu_id"] = tab.menu_id;
    data["name"] = tab.title;
    data["description"] = tab.desc;
    data["image"] = [{ url: tab.image_url }];
    data["version"] = tab.tab_version;
    if (tab.style) {
      data["style"] = tab.style;
    }

    return data;
  }

  async constructMenusbyMenuGroupSync(menu_group) {
    return await this.indexDBService
      .getItemListOnsucss("menu", "grp", menu_group)
      .then(async (menuResponse: any) => {
        let menus = menuResponse;
        let newMenus = [];
        for (let i = 0; i < menus.length; i++) {
          menus[i].rows = await this.constructMenuRowsSync(menus[i].menu_id);
          newMenus.push(menus[i]);
        }
        return newMenus;
      });
  }

  async constructMenuRows(menuId) {
    return await this.indexDBService
      .getItemListOnsucss("row", "menu", menuId)
      .then(async (rowResponse: any) => {
        let rows = await this.sortRows(rowResponse);

        rows.forEach(async (row) => {
          let newRow = row;
          newRow.buttons = await this.constructMenuRowButtons(row.row_id);
        });
        return rows;
      });
  }
  //////////////////////////

  async constructMenuSync(menuId, clean?: boolean) {
    // console.log("menuId", menuId);
    return await this.indexDBService
      .getItemOnsucss("menu", menuId)
      .then(async (menuResponse: any) => {
        let menu = menuResponse;
        if (menu) {
          menu.rows = await this.constructMenuRowsSync(menu.menu_id, clean);
        }
        return await menu;
      });
  }

  async constructMenuRowsSync(menuId, clean?: boolean) {
    return await this.indexDBService
      .getItemListOnsucss("row", "menu", menuId)
      .then(async (rowResponse: any) => {
        let rows = await this.sortRows(rowResponse);
        let newRows = [];
        for (let i = 0; i < rows.length; i++) {
          rows[i].buttons = await this.constructMenuRowButtons(
            rows[i].row_id,
            clean
          );
          newRows.push(rows[i]);
        }
        return newRows;
      });
  }

  async constructMenuRowButtons(rowId, clean?: boolean) {
    return await this.indexDBService
      .getItemListOnsucss("button", "row", rowId)
      .then(async (buttonResponse: any) => {
        let buttons = await this.sortButtons(buttonResponse);
        if (clean) {
          for (let i = 0; i < buttons.length; i++) {
            delete buttons[i].info;
          }
        }

        return buttons;
      });
  }

  sortRows(rows: any[]) {
    return rows.sort((a, b) => Number(a.row_order) - Number(b.row_order));
  }
  sortButtons(buttons: any[]) {
    return buttons.sort(
      (a, b) => Number(a.button_order) - Number(b.button_order)
    );
  }

  sortMenus(menus: Menu[]) {
    return menus.sort((a, b) => Number(a.menu_order) - Number(b.menu_order));

    // Hazem needs to populate the menu_order in order for this function to work.
  }

  sortTabs(tabs: Tab[]) {
    return tabs.sort((a, b) => Number(a.tab_order) - Number(b.tab_order));
  }

  mapAIConfig(data) {
    let config = {};
    config["version"] = data.version;

    let appConfig = data.settings;
    appConfig.app.system.app_info["channel_name"] =
      appConfig.app.system.app_info.app_name;
    delete appConfig.app.system.app_info.app_name;
    config["appConfig"] = appConfig;

    let onlineAppConfig = data.side;
    onlineAppConfig.id = onlineAppConfig.app.id;
    delete onlineAppConfig.app.id;
    onlineAppConfig.app["tabs"] = onlineAppConfig.app.config;
    delete onlineAppConfig.app.config;
    onlineAppConfig.app.tabs["tabs"] = onlineAppConfig.app.tabs.components;
    delete onlineAppConfig.app.tabs.components;
    config["onlineAppConfig"] = onlineAppConfig;

    let onlineChannelConfig = data.home;
    onlineChannelConfig.id = onlineChannelConfig.app.id;
    delete onlineChannelConfig.app.id;
    onlineChannelConfig.app["tabs"] = onlineChannelConfig.app.config;
    delete onlineChannelConfig.app.config;
    onlineChannelConfig.app.tabs["tabs"] =
      onlineChannelConfig.app.tabs.components;
    delete onlineChannelConfig.app.tabs.components;
    onlineChannelConfig.app.tabs.tabs.forEach((tab) => {
      tab["tab_order"] = tab.order;
      delete tab.order;
    });
    delete onlineChannelConfig.app.tabs.tabs.order;
    let colorSource = {
      primary: onlineChannelConfig.app.tabs.color_schema.source,
    };
    onlineChannelConfig.app.tabs.color_schema =
      this.createColorThemeFromColor(colorSource);
    let colorSourceIOS = { primary: "#007aff" };
    onlineChannelConfig.app.tabs.color_schema_ios =
      this.createColorThemeFromColor(colorSourceIOS);
    config["onlineChannelConfig"] = onlineChannelConfig;

    return config;
  }

  createColorThemeFromColor(baseColors) {
    if (baseColors && baseColors.primary) {
      let colors: colorSchema = {
        style: { lightStatusBar: "1" },
        schemes: { light: {}, dark: {} },
        palettes: {
          primary: { keyColor: { id: null } },
          secondary: { keyColor: { id: null } },
          tertiary: { keyColor: { id: null } },
          error: { keyColor: { id: null } },
          neutral: { keyColor: { id: null } },
          neutralVariant: { keyColor: { id: null } },
        },
      };

      let theme: any = {};
      let CorePaletteColors: CorePaletteColors = {
        primary: argbFromHex(baseColors.primary),
        secondary: baseColors.secondary
          ? argbFromHex(baseColors.secondary)
          : null,
        tertiary: baseColors.tertiary ? argbFromHex(baseColors.tertiary) : null,
      };
      let palette: CorePalette;
      palette = CorePalette.contentFromColors(CorePaletteColors);
      theme.light = Scheme.lightFromCorePalette(palette);
      theme.dark = Scheme.darkFromCorePalette(palette);

      Object.keys(theme.light.props).forEach((key) => {
        colors.schemes.light[key] = hexFromArgb(theme.light.props[key]);
      });

      Object.keys(theme.dark.props).forEach((key) => {
        colors.schemes.dark[key] = hexFromArgb(theme.dark.props[key]);
      });

      // console.log("palette", palette);
      colors.source = baseColors.primary; // check if this primary

      let palette_temp: any = {
        primary: {},
        secondary: {},
        tertiary: {},
        error: {},
        neutral: {},
        neutralVariant: {},
      };

      palette_temp.primary = palette.a1;
      palette_temp.primary.keyColor.id = hexFromArgb(
        palette.a1["keyColor"]["argb"]
      );
      colors.palettes.primary = palette_temp.primary;

      palette_temp.secondary = palette.a2;
      palette_temp.secondary.keyColor.id = hexFromArgb(
        palette.a2["keyColor"]["argb"]
      );
      colors.palettes.secondary = palette_temp.secondary;

      palette_temp.tertiary = palette.a3;
      palette_temp.tertiary.keyColor.id = hexFromArgb(
        palette.a3["keyColor"]["argb"]
      );
      colors.palettes.tertiary = palette_temp.tertiary;

      palette_temp.error = palette.error;
      palette_temp.error.keyColor.id = hexFromArgb(
        palette.error["keyColor"]["argb"]
      );
      colors.palettes.error = palette_temp.error;

      palette_temp.neutral = palette.n1;
      palette_temp.neutral.keyColor.id = hexFromArgb(
        palette.n1["keyColor"]["argb"]
      );
      colors.palettes.neutral = palette_temp.neutral;

      palette_temp.neutralVariant = palette.n2;
      palette_temp.neutralVariant.keyColor.id = hexFromArgb(
        palette.n2["keyColor"]["argb"]
      );
      colors.palettes.neutralVariant = palette_temp.neutralVariant;

      let updatedColros: colorSchema;
      let newColors: colorSchema;
      updatedColros = this.additionalColors(palette, colors);
      newColors = this.builderService.extraMaterialColor(
        palette,
        updatedColros,
        false,
        "all"
      );

      // console.log("newColors", newColors);
      return newColors;
    }
  }

  async saveStore(
    store,
    category,
    navigate: boolean,
    noBuilderVersionUpdate: boolean
  ) {
    if (!category) {
      category = "store";
    }
    let mode = localStorage.getItem("mode");
    let storeTab: any = {};
    storeTab["id"] = store.id;
    storeTab["tab_version"] = store.version;
    storeTab["module"] = category;
    storeTab["type"] = TabNames.MENU;
    storeTab["cat"] = category;
    storeTab["menu_group"] = store.id;
    storeTab["menu_id"] = store.menu_id;
    storeTab["image_url"] = store.image ? store.image[0].url : null;
    storeTab["title"] = store.name;
    storeTab["desc"] = store.description;

    if (store && store.is_default == 1) {
      localStorage.setItem(category, store.id);
      localStorage.setItem(category + "menu", store.menu_id);
    }

    if (category == "poll") {
      await this.menusInsertion(
        store.menu,
        false,
        store.id,
        category,
        navigate
      );
    } else {
      await this.menusInsertion(
        store.shop,
        false,
        store.id,
        category,
        navigate
      );
    }
    this.changeTab(
      { tab: storeTab, navigate: navigate },
      mode,
      false,
      false,
      noBuilderVersionUpdate
    );
  }
  // savePoll(poll){
  //   let mode = localStorage.getItem("mode");
  //     let pollTab: any = {};
  //     pollTab['id'] = poll.id;
  //     pollTab['tab_version'] = poll.version;
  //     pollTab['module'] = TabNames.POLL;
  //     pollTab['type'] = TabNames.MENU;
  //     pollTab['cat'] = TabNames.POLL;
  //     pollTab['menu_group'] = poll.id;
  //     pollTab['menu_id'] = poll.menu_id;
  //     pollTab['title'] = poll.name;
  //     this.changeTab({ tab: pollTab }, mode, false);
  //     this.menusInsertion(poll.menu, false)
  // }

  assignStoreToTab(navigationTab?, operator?: number, existingStore?, type?) {
    let storemenu = type + "menu";

    if (!type) {
      type = "store";
    }
    let id = this.makeRef(16);
    let storeTabId = this.makRefNumber(16).toString();

    let defaultStore = localStorage.getItem(type)
      ? localStorage.getItem(type)
      : null;
    let DefaultStoreMenu_id = localStorage.getItem(storemenu)
      ? localStorage.getItem(storemenu)
      : null;
    let mode = localStorage.getItem("mode");
    let data: any = {};
    let storeTab: any = {};

    switch (operator) {
      case ASSIGN_STORE.DEFAULT:
        navigationTab["menu_id"] = DefaultStoreMenu_id;
        navigationTab["menu_group"] = defaultStore;
        navigationTab["param"] = { category: type };
        this.changeTab(
          { tab: navigationTab },
          localStorage.getItem("mode"),
          false
        );

        break;

      case ASSIGN_STORE.EXISTING:
        this.saveStore(existingStore, type, false, false);
        navigationTab["menu_id"] = existingStore.menu_id;
        navigationTab["menu_group"] = existingStore.id;
        navigationTab["param"] = { category: type, id: existingStore.id };

        this.changeTab(
          { tab: navigationTab },
          localStorage.getItem("mode"),
          false
        );
        break;

      case ASSIGN_STORE.NEW:
        storeTab = this.createNewStore(type);
        navigationTab["menu_id"] = storeTab.menu_id;
        navigationTab["menu_group"] = storeTab.id;
        navigationTab["param"] = { category: type, id: storeTab.id };
        this.changeTab(
          { tab: navigationTab },
          localStorage.getItem("mode"),
          false
        );
        break;
    }

    return navigationTab;
  }

  createNewStore(category?) {
    if (!category) {
      category = TabNames.STORE;
    }
    let id = this.makeRef(16);
    let storeTabId = this.makRefNumber(16).toString();

    let defaultStore = localStorage.getItem(category)
      ? localStorage.getItem(category)
      : null;
    let DefaultStoreMenu_id = localStorage.getItem(category + "menu")
      ? localStorage.getItem(category + "menu")
      : null;
    let mode = localStorage.getItem("mode");
    let current_tab = {};
    current_tab = structuredClone(common_components_map[category]);

    let menu_id = this.makeRef(15);
    current_tab["menu_id"] = menu_id;

    let menu: Menu = {
      menu_id: menu_id,
      menu_ref: menu_id,
      menu_version: menu_id,
      cat: category,
      menu_group: storeTabId,
      menu_order: 0,
    };

    this.indexDBService.addItem("menu", menu);
    // new tab for store only
    let storeTab: any = {};
    storeTab["id"] = storeTabId;
    storeTab["tab_version"] = storeTabId;
    storeTab["module"] = category;
    storeTab["type"] = TabNames.MENU;
    storeTab["cat"] = category;
    storeTab["menu_group"] = storeTabId;
    storeTab["menu_id"] = menu_id;
    storeTab["title"] = "New "+ category + this.makeRef(6);
    storeTab["image_url"] = common_components_map[category].image_url;

    if (!defaultStore) {
      storeTab["is_default"] = 1;
      localStorage.setItem(category, storeTabId);
      localStorage.setItem(category + "menu", menu_id);
    } else {
      storeTab["is_default"] = 0;
    }

    this.changeTab({ tab: storeTab, navigate: true }, mode, false, false, true);

    return storeTab;
  }

  createPoll() {
    let id = this.makeRef(16);
    let pollTabId = this.makRefNumber(16).toString();

    // let defaultStore =  localStorage.getItem("store")? localStorage.getItem("store"): null
    // let DefaultStoreMenu_id = localStorage.getItem("storemenu")? localStorage.getItem("storemenu"): null
    let mode = localStorage.getItem("mode");
    let current_tab = {};
    current_tab = structuredClone(common_components_map[TabNames.POLL]);

    let menu_id = this.makeRef(15);
    current_tab["menu_id"] = menu_id;

    let menu: Menu = {
      menu_id: menu_id,
      menu_ref: menu_id,
      menu_version: menu_id,
      cat: TabNames.POLL,
      menu_group: pollTabId,
      menu_order: 0,
    };

    this.indexDBService.addItem("menu", menu);

    // new tab for store only
    let pollTab: any = {};
    pollTab["id"] = pollTabId;
    pollTab["tab_version"] = pollTabId;
    pollTab["module"] = TabNames.POLL;
    pollTab["type"] = TabNames.MENU;
    pollTab["cat"] = TabNames.POLL;
    pollTab["menu_group"] = pollTabId;
    pollTab["menu_id"] = menu_id;
    pollTab["title"] = "New poll_" + this.makeRef(6);

    this.changeTab({ tab: pollTab, navigate: true }, mode, false, false, true);

    return pollTab;
  }

  async deleteMenu(menu_id) {
    let menu = await this.constructMenuSync(menu_id);
    if (menu) {
      menu.rows.forEach((row) => {
        row.buttons.forEach((button) => {
          if (button.row_id == row.row_id) {
            const deletereq = this.indexDBService.deleteItemBykey(
              "button",
              button.button_id
            );
          }
        });
        const deletereq = this.indexDBService.deleteItemBykey(
          "row",
          row.row_id
        );
      });
      const deletereq = this.indexDBService.deleteItemBykey("menu", menu_id);
    }
  }






  validateMenus(menus, target, config?){
    let newMenus =[]
    menus.forEach(menu => {
      newMenus.push( this.validateMenu(menu,target, config))
    });
    return newMenus
  }

  validateMenu(menu, target, config?){
    let routingLink : string;
    if (menu.validate) {
      menu.validate.validators.forEach((item) => {
        item.route = "/appbuilder/navigation/" + menu.menu_group + "/"
        routingLink = item.route
      });
      menu['checklist']= this.validate(menu, menu.validate, target, config);
    }
    menu.rows= this.validateRows( menu.rows, routingLink, target, config)
    return menu
  }

  validateRows(rows, routingLink, target, config?){
    let newRows =[]
    rows.forEach(row => {
      newRows.push( this.validateRow(row, routingLink , target, config))
    });
    return newRows
  }
  validateRow(row, routingLink, target, config?){
    if (row.validate) {
      row.validate.validators.forEach((item) => {
        item.route = routingLink
      });
      row['checklist']= this.validate(row, row.validate, target, config);
    }
    row.buttons= this.validateButtons( row.buttons, routingLink, target, config)
    return row
  }

  validateButtons(buttons, routingLink, target, config?){
    let newButtons =[]
    buttons.forEach(button => {
      newButtons.push( this.validateButton(button, routingLink , target, config))

    });
    return newButtons
  }

  validateButton(button, routingLink, target, config?){
    if (button.validate) {
      let route = routingLink + button.id;
      button.validate.validators.forEach((item) => {
        item.route = route;
      });
      button['checklist']= this.validate(button, button.validate, target, config);
    }
    return button
  }

  validateTabs(tabs, target, config?) {
    let newTabs =[]
    tabs.forEach(tab => {
      newTabs.push(this.validateTab(tab, target, config))
    });

    return newTabs
  }


  validateTab(tab, target, config?) {
    if (tab.validate) {
      let r1: string;
      r1= this.routingLinkTab(tab.cat)
      let route = r1 + tab.id;
      tab.validate.validators.forEach((item) => {
        item.route = route;
      });
      tab['checklist']= this.validate(tab, tab.validate, target,config);
    }
    return tab
  };

  validate(json, validators, target, config?) {
    let checkList = [];
    let index = 0;
    validators.forEach((item) => {
      let rs = 0;
      item.validators.forEach((validate) => {
        rs += this.validateItem(json[item.id], validate, config);
      });
      let result = item.target[target] ? rs : 0;
      checkList[index] = {
        id: item.id,
        name: item.name,
        errorCode: result,
        error: item.error,
        link: item.route,
        setting: item.setting,
      };
      index++;
    });
    return checkList;
  }

  validateItem(item, validate, config?) {
    let rs = 1;
    if (validate.id) {
      switch (validate.id) {
        case "not":
          if (item != validate.value) {
            return (rs = 0);
          }
          break;

         case "doesNotContain":
        if(!item.includes(validate.value)){
          return (rs = 0);
        }
         break;

         case "contain":
        if(item.includes(validate.value)){
          return (rs = 0);
        }
         break;

         case "logo":
        if(item.image.id && item.image_id.includes(config.app_id)){
          return (rs = 0);
        }
         break;

        case "legnth":
          if (item.length == validate.value) {
            return (rs = 0);
          }
          break;

        case "url":
          try {
            new URL(item);
            rs = 0;
          } catch (e) {
            rs = 1;
          }
          break;

        case "status":
          if (item.status == "completed") {
            return (rs = 0);
          }
          break;

        case "notEmpty":
          if (!item.isEmpty) {
            return (rs = 0);
          }
          break;

          case "next":
            if (item != null && !item.isEmpty ) {
              let rs=0
              item.forEach(obj => {
                switch (obj.type){
                  case 'tab' :
                    let tabs =[]
                    tabs = config.OnlineChannelConfig.app.tabs.tabs
                    if (tabs.find(t => t.id === obj.id).length !=0 ){
                    rs += 1;
                    }

                  break;

                  case 'menu':
                    let menus =[]
                    menus = config.OnlineChannelConfig.app.menus
                    if (menus.find(m => m.menu_id ===  obj.id).length !=0 ){
                      rs += 1;
                    }
                  break

                  case  'url':
                    try {
                      new URL(obj.id);
                      rs += 0;
                    } catch (e) {
                      rs += 1;
                    }
                  break;
                }
              });
              return rs
            }
            break;
      }
    }
    return rs;
  }


  routingLinkTab(cat){
    let r1: string;
    switch (cat) {
      case "home":
        r1 = "/appbuilder/navigation/";
        break;

      case "side":
        r1 = "/appbuilder/side/";
        break;

      case "next":
        r1 = "/appbuilder/navigation/workflow/";
        break;

      case "store":
        r1 = "/stores/store/";
        break;

      case "market":
        r1 = "/marketplace/marketplace/";
        break;

      case "poll":
        r1 = "polls/poll";
        break;
    }
    return r1
  }
}

