import { ChangeDetectorRef, Component, Input, OnInit, ViewChild } from "@angular/core";
import { WebsocketService } from "src/app/core/services/websocket.service";
import { IndexDBService } from "src/app/core/services/indexDB.service";
import { TabNames, MobileView, common_components_map, c1, c16 } from "src/app/core/services/constants";
import { Menu, Tab } from "src/app/core/services/interface";
import { cl } from "@fullcalendar/core/internal-common";
import { BuilderService } from "src/app/sharedservices/builder.service";
import { MiddlwareService } from "src/app/core/services/middleware.service";
import { csLocale } from "ngx-bootstrap/chronos";
import { Bold } from "@ckeditor/ckeditor5-basic-styles";




@Component({
  selector: "app-mobile-view",
  templateUrl: "./mobile-view.component.html",
  styleUrls: ["./mobile-view.component.scss"],
})


export class MobileViewComponent implements OnInit {
  updateBuilderComponentSubscription: any;
  colorsComponentSubscription: any;
  getAppConfigSubscription: any;

  tabNames = TabNames;
  mode;
  mobileViewModeMap;
  inputData;
  image: string;
  emptyImage: string;
  tab_bgimage: string;
  start_color: string;
  end_color: string;
  bg_color: string;
  sidemode: boolean;
  theme: any = {};
  isAndroid: string;
  color_schema: any;
  color_schema_ios: any;
  light: boolean = true;
  colors: any = {};
  outline: string;



  view: string;

  @Input() inputTab: Tab;
  @Input() splashTab: Tab;
  @Input() inputMenuId: string;
  @Input() menuGroup: any;

  mobileView = MobileView;

  fontResize = true;

  constructor(
    private ws: WebsocketService,
    public mdw: MiddlwareService,
    private builderService: BuilderService,
    public indexDBService: IndexDBService,
    public ref: ChangeDetectorRef,

  ) { }

  @ViewChild('tabset', { static: true }) tabset: any;


  ngOnInit() {

    this.getAppConfigSubscription = this.mdw.getAppConfigContainer$.subscribe((data) => {
      if (data) {
        this.initialize();
      }
    });


    this.updateBuilderComponentSubscription = this.mdw.updateBuilderComponentContainer$.subscribe((data) => {
      if (data && data.tab) {
        this.inputData = data.tab
        this.theme = this.mdw.mobileTheme(data.tab);

        if(localStorage.getItem("mode")[0]==c1.ANDRIOD){
          this.tabset.tabs[0].active = true
        }else {
          this.tabset.tabs[1].active = true
        }

      }
    });

    this.colorsComponentSubscription = this.mdw.colorsComponentContainer$.subscribe((data) => {
      if (data && data.color_schema  && data.color_schema_ios && data.light !=null) {
        this.colors = data;
        this.light = data.light
        this.color_schema = this.light? this.colors.color_schema.schemes.light:this.colors.color_schema.schemes.dark ;
        this.color_schema_ios = this.light? this.colors.color_schema_ios.schemes.light:  this.colors.color_schema_ios.schemes.dark
        this.initialize();
      }
    })
  }

  initialize(){
    if(localStorage.getItem("mode")[0]==c1.ANDRIOD){
      this.tabset.tabs[0].active = true
    }else {
      this.tabset.tabs[1].active = true
    }
    this.isAndroid = localStorage.getItem("mode")[0];

    const appHome = this.indexDBService.getItem("app", "home");
    appHome.onsuccess = (event) => {
      if (appHome.result) {
        this.colors.color_schema = appHome.result.app.tabs.color_schema;
        this.colors.color_schema_ios = appHome.result.app.tabs.color_schema_ios;

        if (this.colors.color_schema){
          this.color_schema = this.light? this.colors.color_schema.schemes.light:this.colors.color_schema.schemes.dark ;
        }

        if (this.colors.color_schema_ios){
          this.color_schema_ios = this.light? this.colors.color_schema_ios.schemes.light:  this.colors.color_schema_ios.schemes.dark
        }

        this.colors['light'] = localStorage.getItem("mode")[15]==c16.LIGHT? true:false

        if (this.isAndroid === c1.ANDRIOD) {
          this.bg_color = this.color_schema.m2CSurface;
          this.outline = this.color_schema.outlineVariant
        } else if (this.isAndroid === c1.IOS) {
          this.bg_color = this.color_schema_ios.surface;
          this.outline = this.color_schema_ios.outlineVariant
        }
        this.tabBaground(this.inputTab)
      }
    }
  }

  tabBaground(tab) {
    if (tab&& tab.tab_bg && tab.tab_bg.image) {
      this.tab_bgimage = tab.tab_bg.image;
    } else if (tab&& tab.tab_bg) {
      this.start_color = tab.tab_bg.start
        ? tab.tab_bg.start
        : null;
      this.end_color = tab.tab_bg.end
        ? tab.tab_bg.end
        : null;

      if (!this.end_color && this.start_color) {
        this.bg_color = this.start_color;
      } else if (!this.start_color && this.end_color) {
        this.bg_color = this.end_color;
      }
    } else {
      this.tab_bgimage = null;
      this.start_color = null;
      this.end_color = null;
    }
  }



  get setTabBg() {
    if (this.tab_bgimage) {
      return {
        "background-image": `url(${this.tab_bgimage})`,
        "background-position": "center",
        "background-repeat": "no-repeat",
        "background-size": "cover",
      };
    } else if (this.start_color && this.end_color) {
      return {
        "background-image": `linear-gradient(90deg, ${this.start_color}, ${this.end_color})`,
      };
    } else if (this.bg_color) {
      return { background: this.bg_color };
    }
    return { background: "unset" };
  }

  onChangeView(event) {
    let modes: any;
    if (event.id=="ios") {
      modes = [{id:c1.ID, value:c1.IOS}];
    } else {
      modes = [{id:c1.ID, value:c1.ANDRIOD}];
    }
    this.mdw.changeMobileTheme(modes);
    let data = {...{tab:this.inputData, offcanvasRefersh:true}};
    this.mdw._updateBuilderComponentContainer.next(data);
    this.mdw._schemeChangeContainer.next(true);
  }


  ngOnDestroy(): void {
    // console.log("destroy");
    if (this.updateBuilderComponentSubscription) {

      this.updateBuilderComponentSubscription.unsubscribe();
    }

    if (this.colorsComponentSubscription) {
      this.colorsComponentSubscription.unsubscribe();
    }

    if (this.getAppConfigSubscription) {
      this.getAppConfigSubscription.unsubscribe();
    }
  }

}

