<!-- <apx-chart
  class="apex-charts"
  dir="ltr"
  [series]="[percent]"
  [colors]="parColors"
  [chart]="salesAnalyticsDonutChart.chart"
  [plotOptions]="salesAnalyticsDonutChart.plotOptions"
  [dataLabels]="salesAnalyticsDonutChart.dataLabels"
>
</apx-chart> -->

<div class="progress animated-progess" role="progressbar" [attr.aria-valuenow]="[percent]" aria-valuemin="0" aria-valuemax="100" style="height: 5px">
  <div class="progress-bar" [style.width.%]="percent" [style.background-color]="parColors[0]"></div>
</div>
