<div class="card m-0 bg-transparent">
  <div class="card-body">
    <ng-container *ngIf="!config?.isCollection">
      <div class="row m-0 mb-3">
        <div class="col-6">
          <button
            class="btn btn-primary"
            (click)="openscreenselector(selectorScreen)"
          >
            Select
          </button>
        </div>
        <div class="col-6">
          <button class="btn btn-primary" (click)="nextMenu(button)">
            {{ button && button.nexttab_id? "Edit" : "Create New" }}
          </button>
        </div>
      </div>
      <div class="row m-0 no-gutters align-items-center">
        <div class="col-6 d-flex justify-content-center">
          <div class="card-body border border-1 rounded-3 border-primary">
            <app-mobile-view-tab
              *ngIf="isTab"
              [inputTab]="nextTab"
              [fontSmaller]="true"
            >
            </app-mobile-view-tab>
            <app-mobile-view-slider
              *ngIf="!isTab"
              [fontSmaller]="true"
              [inputMenuId]="menuId"
              [colors]="colors"
            >
            </app-mobile-view-slider>
          </div>
        </div>
        <div class="col-6"></div>
      </div>
    </ng-container>


    <div class="card offcanvas-primary m-0 rounded-3" *ngIf="config?.isCollection">
      <div class="row m-0 no-gutters align-items-center">
        <div class="col-md-4">
          <img
            *ngIf="imageUrl"
            alt="Card image"
            class="card-img img-fluid"
            [src]="imageUrl"
          />
        </div>
        <div class="col-md-8">
          <div class="card-body">
            <a href="javascript: void(0);" class="card-link" (click)="openscreenselector(selectorScreen)">
              Select
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>


<ng-template #selectorScreen let-modal>
  <div class="modal-header">
    <h5 class="modal-title" id="selector">Choose Screen</h5>
    <button
      type="button"
      class="btn-close"
      data-bs-dismiss="modal"
      aria-label="Close"
      (click)="modalRef?.hide()"
    ></button>
  </div>
  <div class="modal-body">
    <app-screen-selector
      *ngIf="!config?.isCollection"
      (selectedItemNotify)="onSelectScreen($event)"
    ></app-screen-selector>

    <app-collections-selector
      *ngIf="config?.isCollection"
      [mode]="'selector'"
      [single]="true"
      [selectedCollections]="selectedCollectionsValues"
      [className]="className"
      (selectedNotifier)="selectedCollections($event)"
      (cancelNotifier)="modalRef?.hide()"
    ></app-collections-selector>
  </div>
</ng-template>
