<div class="card slider-card mb-0" style="height: calc(100vh - 133px);">
  <div class="card-body p-0">
    <div class="row m-0 me-1 mt-2 px-2 g-2 overflow-auto">
      <ng-container *ngFor="let tab of sortedTabList; index as item">
        <div
          *ngIf="tab.id != '0'"
          class="col-12 col-xl-12 col-xxl-6"
          style="height: fit-content"
          [id]="tab.id"
        >
          <div class="card card-bright-bg mb-0 p-1 rounded-3">
            <div class="px-1 pb-1 d-none d-xl-flex align-items-center justify-content-between">
              <div class="font-smaller text-truncate fw-bold" style="max-width: 50%">
                {{ tab?.title }}
              </div>
              <div class="d-flex align-items-center">
                <i *ngIf="tab.id == current_tab.id" class="fas fa-check-circle text-primary font-smaller me-1"></i>
                <i class="mdi mdi-delete danger-hover-icon font-size-13 enablePointer" (click)="deleteTab(tab?.id)"></i>
              </div>
            </div>
            <div class="px-1 pb-1 d-flex d-xl-none align-items-center justify-content-between">
              <i class="fas fa-check-circle text-primary font-smaller me-1" [ngClass]="tab.id == current_tab.id ? '' : 'invisible'"></i>
              <i class="mdi mdi-delete danger-hover-icon font-size-13 enablePointer" (click)="deleteTab(tab?.id)"></i>
            </div>
            <div class="border rounded-3 overflow-hidden"
              [ngClass]="
                tab?.id == current_tab.id
                  ? 'border-primary'
                  : 'outline-variant'
              "
              (click)="selectTab(tab?.id)"
            >
              <app-mobile-view-slider
                *ngIf="tab"
                [fontSmaller]="fontSmaller"
                [inputTab]="tab"
              ></app-mobile-view-slider>
            </div>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
</div>
