
<div *ngIf="menu">
  <div class="row m-0" *ngFor="let row of menu?.rows ; trackBy:rowTrackBy" >
    <div  class="col d-flex justify-content-center flex-column" *ngFor="let button of row?.buttons ; trackBy:buttonTrackBy" [ngClass]="isfull? 'p-0': fontSmaller? 'ps-1 py-1 pe-1':'p-1'" >

      <app-button-splash [fontSmaller]="fontSmaller"  [fontResize]="fontResize" *ngIf="button?.button_form === button_form?.SPLASH" [button]="button" [menuGroup]="menu.menu_group" [splashTab]="splashTab"  ></app-button-splash>

      <app-button-card  [fontSmaller]="fontSmaller"  [fontResize]="fontResize" *ngIf="button?.button_form === button_form?.CARD" [button]="button" [row_id]="row?.row_id"  [colors]="colors" [no_buttons]="row.buttons.length"></app-button-card>
      <app-button-collection  [fontSmaller]="fontSmaller"  [fontResize]="fontResize" *ngIf="button?.button_form === button_form?.COLLECTION" [button]="button" [row_id]="row?.row_id"  [colors]="colors" [no_buttons]="row.buttons.length"></app-button-collection>
      <app-button-dropdown [fontSmaller]="fontSmaller" [fontResize]="fontResize" *ngIf="button?.button_form === button_form?.DROPDOWN" [button]="button"  [row_id]="row?.row_id"  [colors]="colors" [fixedTop]="fixedTop" [no_buttons]="row.buttons.length"></app-button-dropdown>
      <app-button-separator [fontSmaller]="fontSmaller" [fontResize]="fontResize" *ngIf="button?.button_form === button_form?.SEPARATOR" [button]="button"  [row_id]="row?.row_id"  [colors]="colors" [no_buttons]="row.buttons.length"> </app-button-separator>
      <app-button-submit  [fontSmaller]="fontSmaller" [fontResize]="fontResize" *ngIf="button?.button_form === button_form?.BUTTON" [button]="button"  [row_id]="row?.row_id"  [colors]="colors" [no_buttons]="row.buttons.length" (click)="onClick(button)"></app-button-submit>
      <app-button-output [fontSmaller]="fontSmaller" [fontResize]="fontResize" *ngIf="button?.button_form === button_form?.OUTPUT" [button]="button"  [row_id]="row?.row_id"  [colors]="colors" [no_buttons]="row.buttons.length"></app-button-output>
      <app-button-input [fontSmaller]="fontSmaller" [fontResize]="fontResize" *ngIf="button?.button_form === button_form?.INPUT" [button]="button"  [row_id]="row?.row_id"  [colors]="colors" [no_buttons]="row.buttons.length"></app-button-input>
      <app-button-single-choice [fontSmaller]="fontSmaller" [fontResize]="fontResize" *ngIf="button?.button_form === button_form?.SINGLECHOICE" [button]="button" [mobViewBuilder]="mobViewBuilder"  [row_id]="row?.row_id"  [colors]="colors" [no_buttons]="row.buttons.length"></app-button-single-choice>
      <app-button-multi-choice [fontSmaller]="fontSmaller" [fontResize]="fontResize" *ngIf="button?.button_form === button_form?.MULTICHOICE" [button]="button" [mobViewBuilder]="mobViewBuilder"  [row_id]="row?.row_id"  [colors]="colors" [no_buttons]="row.buttons.length"></app-button-multi-choice>
      <app-button-toggle [fontSmaller]="fontSmaller" [fontResize]="fontResize" *ngIf="button?.button_form === button_form?.TOGGLE" [button]="button" [mobViewBuilder]="mobViewBuilder"  [row_id]="row?.row_id"  [colors]="colors" [no_buttons]="row.buttons.length"></app-button-toggle>


      <app-button-pagetext [fontSmaller]="fontSmaller" [fontResize]="fontResize" *ngIf="button?.button_form === button_form?.PAGETEXT && mobViewButton" [button]="button"></app-button-pagetext>
      <app-button-pagetext-view  [fontSmaller]="fontSmaller" [fontResize]="fontResize" *ngIf="button?.button_form === button_form?.PAGETEXT && !mobViewButton" [button]="button"></app-button-pagetext-view>
      <app-button-pageslider  [fontSmaller]="fontSmaller" [fontResize]="fontResize" *ngIf="button?.button_form === button_form?.PAGESLIDER" [button]="button"></app-button-pageslider>
      <app-button-pagesliderlabel  [fontSmaller]="fontSmaller" [fontResize]="fontResize" *ngIf="button?.button_form === button_form?.PAGESLIDERLABEL" [button]="button"></app-button-pagesliderlabel>
      <app-button-pagegallery  [fontSmaller]="fontSmaller" [fontResize]="fontResize" *ngIf="button?.button_form === button_form?.PAGEGALLERY" [button]="button"></app-button-pagegallery>
      <app-button-pagesingleimage  [fontSmaller]="fontSmaller" [fontResize]="fontResize" *ngIf="button?.button_form === button_form?.PAGESINGLEIMAGE" [button]="button"></app-button-pagesingleimage>
      <app-button-pageheader  [fontSmaller]="fontSmaller" [fontResize]="fontResize" *ngIf="button?.button_form === button_form?.PAGEHEADER" [button]="button"></app-button-pageheader>
      <app-button-pagedivider  [fontSmaller]="fontSmaller" [fontResize]="fontResize" *ngIf="button?.button_form === button_form?.PAGEDIVIDER" [button]="button"></app-button-pagedivider>
      <app-button-pagevideo  [fontSmaller]="fontSmaller" [fontResize]="fontResize" *ngIf="button?.button_form === button_form?.PAGEVIDEO" [button]="button"></app-button-pagevideo>
      <app-button-pageqrcode  [fontSmaller]="fontSmaller" [fontResize]="fontResize" *ngIf="button?.button_form === button_form?.PAGEQR" [button]="button" [mobViewBuilder]="mobViewBuilder"></app-button-pageqrcode>
      <app-button-pagelocation  [fontSmaller]="fontSmaller" [fontResize]="fontResize" *ngIf="button?.button_form === button_form?.PAGELOCATION" [button]="button"></app-button-pagelocation>
      <app-button-pagepdfviewer  [fontSmaller]="fontSmaller" [fontResize]="fontResize" *ngIf="button?.button_form === button_form?.PAGEPDFVIEWER" [button]="button"></app-button-pagepdfviewer>
      <app-button-pagesocialbar  [fontSmaller]="fontSmaller" [fontResize]="fontResize" *ngIf="button?.button_form === button_form?.PAGESOCIALBAR" [button]="button"></app-button-pagesocialbar>

    </div>
  </div>

</div>


