import { ModalModule } from "ngx-bootstrap/modal";
import { ChangeDetectorRef, Component, Input, OnInit } from "@angular/core";
import { WebsocketService } from "src/app/core/services/websocket.service";
import { IndexDBService } from "src/app/core/services/indexDB.service";
import { TabNames } from "src/app/core/services/constants";
import { MiddlwareService } from "src/app/core/services/middleware.service";
import {
  Menu,
  MenuButton,
  MenuRow,
  Tab,

} from "src/app/core/services/interface";
import { BuilderService } from "src/app/sharedservices/builder.service";
import { Router } from "@angular/router";

@Component({
  selector: "app-menu-slider",
  templateUrl: "./menu-slider.component.html",
  styleUrls: ["./menu-slider.component.scss"],
})
export class MenuSliderComponent implements OnInit {
  currentMenuSubscription: any;
  tabNames = TabNames;
  mode;
  mobileViewModeMap;
  inputData;
  image: string;
  tab_image: string;
  start_color: string;
  end_color: string;
  bg_color: string;
  @Input() inputMenu: any;
  fontSmaller = true;
  current_tab: Tab;
  current_menu: Menu;
  data: any;
  max_buttons = 6;
  menu_id: string;
  menu: Menu;
  rowList = {};
  sortedRowList = [];
  buttonList = {};
  sortedButtonList = {};
  menuList = {};
  sortedMenuList = [];
  module = "menu";
  tab: any;
  view: boolean;
  defaultMenu: any;
  noDelete: boolean;

  // grid = [ {row_id:, row_order: buttons:[ {button_id, button_form: , button_style, }] ];
  defaultRow: MenuRow = {
    row_id: "0",
    row_order: 0,
    menu_id: "menu4",
    buttons: [],
  };
  newMenu: boolean;

  defaultButton: MenuButton = {
    button_id: "0",
    row_id: "0",
    button_callback: "0",
    button_style: "0",
    button_style_ios: "0",
    button_form: "empty",
    button_code: "placeholder",
    button_order: 0,
    info: { min_grid: 10 },

  };
/// colors to be common into the tab section///
light: boolean = true;
colors: any;
color_schema: any;
color_schema_ios: any;
  constructor(
    private ws: WebsocketService,
    private mdw: MiddlwareService,
    public indexDBService: IndexDBService,
    public ref: ChangeDetectorRef,
    public builderService: BuilderService,
    private router: Router,

  ) {}

  ngOnInit() {

    this.currentMenuSubscription = this.mdw.currentMenuContainer$.subscribe(
      (data) => {

        if (data && data["sortedMenuList"] && this.inputMenu && !data["next"] && data["menu"].menu_group == this.inputMenu.menu_group) {

          this.current_menu = data["menu"];
          this.inputData = data;
          this.menu = this.current_menu;

          this.menuList = this.inputData["menuList"];
          this.sortedMenuList = this.inputData["sortedMenuList"];
          this.rowList = this.inputData["rowList"];
          this.sortedRowList = [...this.inputData["sortedRowList"]];
          this.buttonList = this.inputData["buttonList"];
          this.sortedButtonList = this.inputData["sortedButtonList"];
          this.defaultRow = this.inputData["defaultRow"];
          this.defaultButton = this.inputData["defaultButton"];
          this.max_buttons = this.inputData["max_buttons"];
          this.module = this.inputData["module"];
          this.mode = localStorage.getItem("mode");
          this.tab = this.inputData["tab"];
          this.defaultMenu = this.sortedMenuList[0];

          if (data['colors']){
            this.colors = data["colors"];
            this.light = data["light"];
        }
      }}
    );

  }

  async deleteMenuItem(id) {

    if (id) {
      this.view = true;
      // multiple menus
      // re-set as default if deleted is a default.
      if (this.sortedMenuList.length > 1) {

        // remove menu from sortedmenuList
        let index = this.sortedMenuList.findIndex((e) => e.menu_id == id);
        this.sortedMenuList.splice(index, 1);

        // set current menu. The current menu is not necessary to be the default menu.
        // Defualt menu is the menu where the tab points to
        // set first menu in the list as current menu.

        this.current_menu = this.sortedMenuList[0];
        this.current_menu.menu_id = this.sortedMenuList[0].menu_id; //set next menu as current.
        this.menu = this.current_menu;
        delete this.menuList[id];

        /// reorder the sortedMenuList
        let tempList = this.sortedMenuList;
        let menuOrder = 1

        tempList.forEach((menu) => {
          if (menu.menu_id == this.menu.menu_id){
              menu.menu_order = 0;
              this.menuList[menu.menu_id].menu_order = 0;
          }else {
            menu.menu_order = menuOrder;
            this.menuList[menu.menu_id].menu_order = menuOrder;
            menuOrder++;
          }
            const inputData = { ...menu };
            const response = this.indexDBService.updateItem("menu", inputData);

          });

          this.sortedMenuList= this.sortMenus(tempList);

        //  if deleted is the default, set the current menu as default. Note it is always the index =0 is the default as defination
        if (index == 0) {
          this.tab.menu_id = this.menu.menu_id;
          this.view = false;
        }
        delete this.current_menu["tab"];
        // actual delete the menu from database.
        await this.deleteMenu(id);

        // get the new menu.
        let menu = await this.mdw.constructMenuSync(
            this.current_menu.menu_id
          );
          this.extractMenu(menu);
          delete this.current_menu["rows"];

      } else {
        // single menu only
        await this.deleteRows(id);
        this.sortedMenuList[0].rows = []; //set next menu as default.
        this.current_menu.rows = [];
        this.rowList = {};
        this.buttonList = {};
        this.sortedRowList = [];
        this.sortedButtonList = {};
      }

       let menuData = {};
      menuData["menu"] = this.menu;
      menuData["sortedMenuList"] = this.sortedMenuList;
      menuData["menuList"] = this.menuList;

      menuData["rowList"] = this.rowList;
      menuData["sortedRowList"] = this.sortedRowList;
      menuData["buttonList"] = this.buttonList;
      menuData["sortedButtonList"] = this.sortedButtonList;

      menuData["defaultRow"] = this.defaultRow;
      menuData["defaultButton"] = this.defaultButton;
      menuData["max_buttons"] = this.max_buttons;
      menuData["module"] = this.module;
      menuData["mode"] = this.mode;
      menuData["tab"] = this.tab;
      menuData["colors"] = this.colors
      menuData["light"] = this.light;


      // this.mdw._currentTabContainer.next(data); // to draw the slider

      this.mdw.changeTab(
        { tab: this.tab, menu: this.menu },
        this.module,
        this.view
      );

      // let tabData = {current_tab:this.tab, menu:this.menu}
    // this.mdw._currentTabContainer.next(tabData)
      this.mdw._currentMenuContainer.next(menuData); // to update menupages
    }

  }

  extractMenu(menu: any) {
    this.rowList = {};
    menu.rows.forEach((row: any) => {
      this.rowList[row.row_id] = row;
    });

    this.buttonList = {};
    this.sortedRowList = [];
    this.sortedButtonList = {};
    this.sortedRowList = this.sortRows(Object.values(this.rowList));

    this.sortedRowList.forEach((row: any) => {
      if (row.buttons) {
        row.buttons.forEach((button: any) => {
          this.buttonList[button.button_id] = button;
          this.buttonList[button.button_id].row_id = row.row_id;
          this.sortedButtonList[row.row_id] = (
            this.sortedButtonList[row.row_id] || []
          ).concat([button]);
        });
      }
    });

    Object.values(this.sortedButtonList).forEach((row: any) => {
      if (row.length > 1) {
        this.sortedButtonList[row[0].row_id] = this.sortButtons(
          this.sortedButtonList[row[0].row_id]
        );
      }
    });
  }
  // delete menu should be a service function. and moved out from here  Hazem
  async deleteRows(menu_id) {
    let menu = await this.mdw.constructMenuSync(menu_id);
    menu.rows.forEach((row) => {
      row.buttons.forEach((button) => {
        if (button.row_id == row.row_id) {


          if (button&& button.next && button.next[0] && button.next[0].type=="tab" && button.next[0].id){
            console.log("this current Button", button);
                const deleteTab = this.indexDBService.deleteItemBykey(
              "tab",
              button.next[0].id
            );
          }


          const deletereq = this.indexDBService.deleteItemBykey(
            "button",
            button.button_id
          );
        }
      });
      const deletereq = this.indexDBService.deleteItemBykey("row", row.row_id);
    });
  }
  async deleteMenu(menu_id) {
    let menu = await this.constructMenus(menu_id);
    menu.rows.forEach((row) => {
      row.buttons.forEach((button) => {
        if (button.row_id == row.row_id) {

          if (button&& button.next && button.next[0] && button.next[0].type=="tab" && button.next[0].id){
            console.log("this current Button", button);
                const deleteTab = this.indexDBService.deleteItemBykey(
              "tab",
              button.next[0].id
            );
          }

          const deletereq = this.indexDBService.deleteItemBykey(
            "button",
            button.button_id
          );
        }
      });
      const deletereq = this.indexDBService.deleteItemBykey("row", row.row_id);
    });
    const deletereq = this.indexDBService.deleteItemBykey("menu", menu_id);
  }

  async constructMenus(query) {
    return await this.indexDBService
      .getItemOnsucss("menu", query)
      .then(async (menuResponse: any) => {
        let menu = menuResponse;
        menu.rows = await this.constructMenuRows(menu.menu_id);
        return menu;
      });
  }

  async constructMenuRows(menuId) {
    return await this.indexDBService
      .getItemListOnsucss("row", "menu", menuId)
      .then(async (rowResponse: any) => {
        let rows = await this.sortRows(rowResponse);

        for (let i = 0; i < rows.length; i++) {
          let newRow = rows[i];
          newRow.buttons = await this.constructMenuRowButtons(rows[i].row_id);
        }
        return rows;
      });
  }

  async constructMenuRowButtons(rowId) {
    return await this.indexDBService
      .getItemListOnsucss("button", "row", rowId)
      .then(async (buttonResponse: any) => {
        let buttons = await this.sortButtons(buttonResponse);
        return buttons;
      });
  }

  sortRows(rows: any[]) {
    return rows.sort((a, b) => Number(a.row_order) - Number(b.row_order));
  }
  sortButtons(buttons: any[]) {
    return buttons.sort(
      (a, b) => Number(a.button_order) - Number(b.button_order)
    );
  }

  sortMenus(menus: Menu[]) {
    return menus.sort((a, b) => Number(a.menu_order) - Number(b.menu_order));

    // Hazem needs to populate the menu_order in order for this function to work.
  }

  async selectMenu(menu_id) {
    this.newMenu = false;

    let index = this.sortedMenuList.findIndex((e) => e.menu_id == menu_id);
    this.current_menu = this.sortedMenuList[index];

    if( this.current_menu.menu_id != this.menu.menu_id){
     this.newMenu=true;
    }

    this.menu = this.current_menu;
    let menuData: any = {};

    menuData["menu"] = this.menu;
    menuData["sortedMenuList"] = this.sortedMenuList;
    menuData["menuList"] = this.menuList;


    if (this.newMenu){
      let menu = await this.mdw.constructMenuSync(
        this.current_menu.menu_id
      );
      this.extractMenu(menu);
      delete this.current_menu["rows"];

      }
    menuData["rowList"] = this.rowList;
    menuData["sortedRowList"] = this.sortedRowList;
    menuData["buttonList"] = this.buttonList;
    menuData["sortedButtonList"] = this.sortedButtonList;
    menuData["defaultRow"] = this.defaultRow;
    menuData["defaultButton"] = this.defaultButton;
    menuData["max_buttons"] = this.max_buttons;
    menuData["module"] = this.module;
    menuData["mode"] = this.mode;
    menuData["tab"] = this.tab;
    menuData["colors"] = this.colors
    menuData["light"] = this.light;

    this.mdw.changeTab(
      {
        tab: { module: this.module, menu_id: this.menu.menu_id },
        menu: this.menu,
      },
      this.module,
      true
    );

    let tabData = { current_tab: this.tab, menu: this.menu };
    // this.mdw._currentTabContainer.next(tabData);

this.mdw._currentMenuContainer.next(menuData);


if (this.menu.cat=="splash" && !this.router.url.includes("/" + 'splash') ){
      this.router.navigate([
      "appbuilder/"+window.location.pathname.split("/").pop()+"/splash/" + this.menu.menu_group,]);


}

  }

  ngOnDestroy(): void {
    // console.log("destroy");
    if (this.currentMenuSubscription) {
      this.currentMenuSubscription.unsubscribe();
    }
  }
  menuTrackBy(index, menu){

    return menu.menu_version;
 }

}
