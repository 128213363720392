import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { WebsocketService } from '../services/websocket.service';

@Injectable({ providedIn: "root" })
export class AuthGuard implements CanActivate {
  constructor(
    private router: Router,
    private ws: WebsocketService
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const currentUser = localStorage.getItem("auth");

    // console.log(currentUser);
    if (currentUser === "true") {
      // logged in so return true
      return true;
    }
    // not logged in so redirect to login page with the return url
    else {
      this.router.navigate(["/hub/login"], { queryParams: { returnUrl: state.url } });
      return false;
    }
  }
}
