import { Injectable } from "@angular/core";
import { MessageTypes } from "../core/services/constants";
import { NgxMasonryComponent } from "ngx-masonry";
import Swal from "sweetalert2";

@Injectable({
  providedIn: "root",
})
export class CommonService {
  masonryComponent: NgxMasonryComponent;
  constructor() {}
  isFileSizeValid(type: string, size: number): boolean {
    if (
      ((type === MessageTypes.VIDEO || type === MessageTypes.DOCUMENT) &&
        size >= 52428800) ||
      (type !== MessageTypes.VIDEO &&
        type !== MessageTypes.DOCUMENT &&
        size >= 20971520)
    ) {
      return false;
    }
    return true;
  }

  resizeMedia(width: number, height: number) {
    const min = 100;
    const max = 280;

    const aspect = width / height;
    if (height < width) {
      if (height < min) {
        height = min;
        width = height * aspect;
      }
    } else if (width < min) {
      width = min;
      height = width / aspect;
    }

    if (height > width) {
      if (height > max) {
        height = max;
        width = height * aspect;
      }
    } else if (width > max) {
      width = max;
      height = width / aspect;
    }

    return [width, height];
  }

  dateFormat(date: string) {
    const now = new Date(date);
    // Get individual components
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, "0"); // Months are zero-based
    const day = String(now.getDate()).padStart(2, "0");

    // Format as YYYY-MM-DD
    const formattedDate = `${year}-${month}-${day}`;

    return formattedDate;
  }

  isMimeTypeValid(msgType: string, mimeType: string): boolean {
    if (!mimeType) {
      return false;
    } else if (msgType !== MessageTypes.DOCUMENT) {
      return (
        [
          "image/bmp",
          "image/jpeg",
          "image/png",
          "image/gif",
          "audio/mid",
          "audio/midi",
          "audio/mp4",
          "audio/mpeg",
          "audio/ogg",
          "audio/basic",
          "audio/mp3",
          "video/mp4",
          "video/mpeg",
          "video/ogg",
        ].indexOf(mimeType) !== -1
      );
    } else if (msgType === MessageTypes.DOCUMENT) {
      return (
        [
          "text/plain",
          "application/rtf",
          "application/pdf",
          "application/msword",
          "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
          "application/zip",
          "application/x-tar",
          "application/x-7z-compressed",
          "application/x-zip-compressed",
          "application/x-rar-compressed",
          "application/vnd.android.package-archive",
        ].indexOf(mimeType) !== -1
      );
    }
  }

  fileChecker(file, type) {
    console.log(file);
    if (!file.name) {
      Swal.fire("Upload failed", "File name is missing.", "error");
      return false;
    } else if (file.size <= 0) {
      Swal.fire("Upload failed", "File is empty.", "error");
      return false;
    } else if (!this.isMimeTypeValid(type, file.type)) {
      Swal.fire("Upload failed", "Unsupported file type.", "error");
      return false;
    } else if (!this.isFileSizeValid(type, file.size)) {
      if (type === MessageTypes.VIDEO) {
        Swal.fire("Upload failed", "File size exceeds 50MB.", "error");
      } else {
        Swal.fire("Upload failed", "File size exceeds 20MB.", "error");
      }
      return false;
    } else {
      return true;
    }
  }
}
