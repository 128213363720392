import iso from "src/assets/iso.json";
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from "@angular/core";
import { WebsocketService } from "src/app/core/services/websocket.service";
import { Router } from "@angular/router";
import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import { MiddlwareService } from "src/app/core/services/middleware.service";
import { ListUsers } from "src/app/core/services/outbound";
import {
  device_os_map,
  user_role_color_map,
  user_role_map,
  user_search_placeholder_map,
  user_status_color_map,
  user_status_map,
} from "src/app/core/services/constants";
import { CommonService } from "src/app/sharedservices/common.service";

@Component({
  selector: "app-users-selector",
  templateUrl: "./users-selector.component.html",
  styleUrls: ["./users-selector.component.scss"],
  providers: [],
})

/**
 * Contacts domain-list component
 */
export class UsersSelectorComponent
  implements OnInit, AfterViewInit, OnDestroy
{
  @Input() mode = "list";
  @Input() single = false;
  @Input() chatID: string;
  @Input() selectedCalendars = [];
  @Output() selectedNotifier = new EventEmitter<any>();
  @Output() cancelNotifier = new EventEmitter<boolean>(false);
  // bread crumb items
  breadCrumbItems: Array<{}>;
  page: Array<any> = [];
  eop = false;
  pageSize = 24;
  currentPage = 0;
  userList: {};
  userListSubscription: any;
  monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "June",
    "July",
    "Aug",
    "Sept",
    "Oct",
    "Nov",
    "Dec",
  ];
  status_map = {};
  status_color_map = {};
  user_search_placeholder_map = {};
  user_role_map = {};
  user_role_color_map = {};
  payload = {};
  userListForm: UntypedFormGroup;
  submitted = false;
  dupe = false;
  criterionCount = 0;
  component_ref: any;
  search_message = "";
  countries = [];
  loading = false;
  verifySubscription: any;
  my_id: any;

  selectedUsersList = {};
  device_os_map = {};

  constructor(
    private ws: WebsocketService,
    private router: Router,
    private formBuilder: UntypedFormBuilder,
    private mdw: MiddlwareService,
    private commonService: CommonService,
    private ref: ChangeDetectorRef
  ) {}

  ngOnInit() {
    // console.log(this.router.url);
    this.chatID = this.chatID ? this.chatID : localStorage.getItem("chat_id");
    this.countries = iso.map((a) => a.name);
    this.status_map = user_status_map;
    this.status_color_map = user_status_color_map;
    this.user_role_map = user_role_map;
    this.user_role_color_map = user_role_color_map;
    this.device_os_map = device_os_map;
    this.user_search_placeholder_map = user_search_placeholder_map;

    // console.log(this.countries);
    this.component_ref = this.mdw.makeRef(16);
    this.currentPage = 1;
    this.userList = {};
    // console.log(Object.keys(this.userList).length);

    this.userListForm = this.formBuilder.group({
      member_id: new UntypedFormControl(null),
      search_value: new UntypedFormControl(null),
      status: new UntypedFormControl(""),
      role_id: new UntypedFormControl(""),
      date: new UntypedFormControl(null),
      search_type: new UntypedFormControl("name"),
    });
    this.userListSubscription = this.ws.listUsersContainer$.subscribe(
      (listUsersData) => {
        if (listUsersData.ref === this.component_ref) {
          if (listUsersData) {
            this.loading = false;
            this.currentPage = listUsersData.page_number;
            if (listUsersData.members.length == 0 && this.currentPage == 1) {
              this.search_message = "No results matched your search criteria";
            }

            listUsersData.members.forEach((user: any) => {
              user.created_date = new Date(user.created_date);
              user.created_date =
                user.created_date.getDate() +
                " " +
                this.monthNames[user.created_date.getMonth()] +
                ", " +
                user.created_date.getFullYear();

              this.userList[String(user.group_id) + String(user.member_id)] =
                user;
            });

            this.page = [];
            for (let i = 0; i < this.pageSize; i++) {
              if (
                Object.keys(this.userList)[
                  i + (this.currentPage - 1) * this.pageSize
                ]
              ) {
                this.page.push(
                  this.userList[
                    Object.keys(this.userList)[
                      i + (this.currentPage - 1) * this.pageSize
                    ]
                  ]
                );
              }
            }

            document
              .getElementById("users_pagination_selector_back")
              .classList.remove("d-none");
            document
              .getElementById("users_pagination_selector_back")
              .classList.add("enablePointer");
            document
              .getElementById("users_pagination_selector_next")
              .classList.remove("d-none");
            document
              .getElementById("users_pagination_selector_next")
              .classList.add("enablePointer");

            this.eop = false;
            if (listUsersData.eop) {
              this.eop = true;
              document
                .getElementById("users_pagination_selector_next")
                .classList.add("d-none");
              document
                .getElementById("users_pagination_selector_next")
                .classList.remove("enablePointer");
            } else {
              document
                .getElementById("users_pagination_selector_next")
                .classList.remove("d-none");
              document
                .getElementById("users_pagination_selector_next")
                .classList.add("enablePointer");
            }
            if (this.currentPage == 1) {
              document
                .getElementById("users_pagination_selector_back")
                .classList.add("d-none");
              document
                .getElementById("users_pagination_selector_back")
                .classList.remove("enablePointer");
            } else {
              document
                .getElementById("users_pagination_selector_back")
                .classList.remove("d-none");
              document
                .getElementById("users_pagination_selector_back")
                .classList.add("enablePointer");
            }
            if (this.currentPage == 1 && listUsersData.eop) {
              document
                .getElementById("usersShowPageNumberSelector")
                .classList.add("invisible");
            } else {
              document
                .getElementById("usersShowPageNumberSelector")
                .classList.remove("invisible");
            }
            this.ref.detectChanges();
          }
        }
      }
    );

    this.verifySubscription = this.ws.verifyContainer$.subscribe(
      (verifyData) => {
        // console.log("verifyData"+ verifyData);
        if (verifyData) {
          this.my_id = this.mdw.getID();
          this.loading = true;
          this.ws.processMethod(
            new ListUsers(
              this.currentPage - 1,
              this.chatID,
              this.component_ref,
              this.payload["member_id"],
              this.payload["member_list"],
              this.payload["name"],
              this.payload["msisdn"],
              this.payload["status"],
              this.payload["role_id"],
              this.payload["date"]
            )
          );
        }
      }
    );

    if (this.selectedCalendars && this.selectedCalendars.length > 0) {
      for (let i = 0; this.selectedCalendars.length > i; i++) {
        this.selectedUsersList[this.selectedCalendars[i].id] =
          this.selectedCalendars[i];
      }
    }
  }

  ngOnDestroy(): void {
    // console.log("destroy");
    if (this.userListSubscription) {
      this.userListSubscription.unsubscribe();
    }
    if (this.verifySubscription) {
      this.verifySubscription.unsubscribe();
    }
  }

  ngAfterViewInit(): void {
    document
      .getElementById("users_pagination_selector_back")
      .classList.add("d-none");
    document
      .getElementById("users_pagination_selector_back")
      .classList.remove("enablePointer");
    document
      .getElementById("users_pagination_selector_next")
      .classList.add("d-none");
    document
      .getElementById("users_pagination_selector_next")
      .classList.remove("enablePointer");
  }

  openDetails(data: any) {
    this.router.navigate([
      "/" +
        data.account_id +
        "/" +
        data.ref_domain +
        "/" +
        data.service.product,
    ]);
  }

  // convenience getter for easy access to form fields
  get f() {
    return this.userListForm.controls;
  }

  onSubmit() {
    this.submitted = true;
    if (this.userListForm.valid) {
      this.loading = true;
      let id = this.f.member_id.value;
      let member_id =
        this.f.search_type.value === "member_id"
          ? this.f.search_value.value
          : null;
      let name =
        this.f.search_type.value === "name" ? this.f.search_value.value : null;
      let msisdn =
        this.f.search_type.value === "msisdn"
          ? this.f.search_value.value
          : null;
      let status = this.f.status.value;
      let role_id = this.f.role_id.value;
      let date = this.f.date.value
        ? this.commonService.dateFormat(this.f.date.value)
        : null;
      this.search(member_id, name, msisdn, status, role_id, date);
    } else {
      return;
    }
  }

  search(
    id: any,
    name: any,
    msisdn: any,
    status: any,
    role_id: any,
    date: any
  ) {
    this.currentPage = 0;
    this.userList = {};
    this.payload = {};
    this.search_message = "";

    this.payload = {
      page_size: this.pageSize,
      page_number: 0,
      group_id: localStorage.getItem("chat_id"),
    };

    if (id != null && id !== "") {
      this.payload["member_id"] = id;
    }
    if (name != null && name !== "") {
      this.payload["name"] = name;
    }
    if (msisdn != null && msisdn !== "") {
      this.payload["msisdn"] = msisdn;
    }
    if (status != null && status !== "") {
      this.payload["status"] = status;
    }
    if (role_id != null && role_id !== "") {
      this.payload["role_id"] = Number(role_id);
    }
    if (date != null && date !== "") {
      this.payload["date"] = date;
    }

    this.ws.processMethod(
      new ListUsers(
        0,
        this.chatID,
        this.component_ref,
        this.payload["member_id"],
        this.payload["member_list"],
        this.payload["name"],
        this.payload["msisdn"],
        this.payload["status"],
        this.payload["role_id"],
        this.payload["date"]
      )
    );
  }

  resetSearch() {
    this.currentPage = 0;
    this.userList = {};
    this.search_message = "";

    this.payload["page_size"] = this.pageSize;
    this.payload["page_number"] = this.currentPage;
    this.payload["group_id"] = this.chatID;

    this.ws.processMethod(
      new ListUsers(
        this.currentPage,
        this.chatID,
        this.component_ref,
        this.payload["member_id"],
        this.payload["member_list"],
        this.payload["name"],
        this.payload["msisdn"],
        this.payload["status"],
        this.payload["role_id"],
        this.payload["date"]
      )
    );
  }

  nextPage() {
    // console.log("request");
    this.payload["page_size"] = this.pageSize;
    this.payload["page_number"] = this.currentPage;
    this.payload["group_id"] = this.chatID;

    this.ws.processMethod(
      new ListUsers(
        this.currentPage,
        this.chatID,
        this.component_ref,
        this.payload["member_id"],
        this.payload["member_list"],
        this.payload["name"],
        this.payload["msisdn"],
        this.payload["status"],
        this.payload["role_id"],
        this.payload["date"]
      )
    );
  }

  backPage() {
    if (this.currentPage != 1) {
      this.currentPage--;
      this.page = [];
      for (let i = 0; i < this.pageSize; i++) {
        if (
          Object.keys(this.userList)[i + (this.currentPage - 1) * this.pageSize]
        ) {
          this.page.push(
            this.userList[
              Object.keys(this.userList)[
                i + (this.currentPage - 1) * this.pageSize
              ]
            ]
          );
        }
      }
      if (this.currentPage == 1) {
        document
          .getElementById("users_pagination_selector_back")
          .classList.add("d-none");
        document
          .getElementById("users_pagination_selector_back")
          .classList.remove("enablePointer");
      }
      if (
        this.currentPage <
        Object.keys(this.userList).length / this.pageSize
      ) {
        document
          .getElementById("users_pagination_selector_next")
          .classList.remove("d-none");
        document
          .getElementById("users_pagination_selector_next")
          .classList.add("enablePointer");
      }
    }
  }

  selectUsers(data) {
    if (this.selectedUsersList[data.member_id]) {
      delete this.selectedUsersList[data.member_id];
    } else {
      this.selectedUsersList[data.member_id] = data;
    }
  }

  insertEvent() {
    const selected = Object.values(this.selectedUsersList);
    this.selectedNotifier.emit(selected);
  }

  cancelEvent() {
    this.cancelNotifier.emit(true);
  }

  isObjEmpty() {
    return Object.keys(this.selectedUsersList).length === 0;
  }

  isSelected(id: string) {
    return this.selectedUsersList[id] ? true : false;
  }

  searchTypeChanged() {
    if (this.userListForm.value.search_type === "member_id") {
      this.userListForm
        .get("search_value")
        .setValidators([Validators.pattern(/^[0-9]*$/)]);
    } else {
      this.userListForm.get("search_value").clearValidators();
    }
    this.userListForm.get("search_value").updateValueAndValidity();
  }

  getCountryName(alpha: string) {
    return iso.find((e) => e.alpha === alpha)?.name;
  }
}
