<header id="page-topbar">
  <div class="navbar-header">
    <div class="d-flex">
      <!--  LOGO  -->
      <div class="navbar-brand-box logo-box overflow-x-hidden ps-3">
        <a routerLink="/" class="logo logo-dark">
          <span class="logo-sm">
            <img src="assets/images/logo-icon.png" />
          </span>
          <span class="logo-lg">
            <img src="assets/images/logo-dark.png" />
          </span>
        </a>

        <a routerLink="/" class="logo logo-light">
          <span class="logo-sm">
            <img src="assets/images/logo-icon.png" />
          </span>
          <span class="logo-lg">
            <img src="assets/images/logo-dark.png" />
          </span>
        </a>
      </div>

      <button
        type="button"
        class="btn btn-sm px-3 font-size-16 header-item"
        id="vertical-menu-btn"
        (click)="toggleMobileMenu($event)"
      >
        <i class="fa fa-fw fa-bars"></i>
      </button>
    </div>
    <div class="d-flex align-items-center">
      <h2 *ngIf="admin && !super">Administrator</h2>
      <h2 *ngIf="admin && super">Super Administrator</h2>
      <h2 *ngIf="reconnectNotice" class="mb-0">{{reconnectNotice}}</h2>
      <h2 *ngIf="tempModeStatus" class="mb-0">Template Mode</h2>
    </div>
    <div class="d-flex">
      <!-- <div class="dropdown d-inline-block" dropdown>
        <button type="button" class="btn header-item" id="page-header-user-dropdown" dropdownToggle>
          <img *ngIf="flagvalue !== undefined" src="{{flagvalue}}" alt="Header Language" height="16">
          <img *ngIf="flagvalue === undefined" src="{{valueset}}" alt="Header Language" height="16">
        </button>
        <div class="dropdown-menu dropdown-menu-end" *dropdownMenu>
          <a href="javascript:void(0);" class="dropdown-item notify-item" *ngFor="let item of listLang"
            (click)="setLanguage(item.text, item.lang, item.flag)" [ngClass]="{'active': cookieValue === item.lang}">
            <img src="{{item.flag}}" alt="user-image" class="me-1" height="12"> <span
              class="align-middle">{{item.text}}</span>
          </a>
        </div>
      </div> -->
      <div
        *ngIf="balance != null"
        class="dropdown d-flex align-items-center ms-1"
      >
        <button
          type="button"
          class="btn border border-secondary"
          (click)="routeRecharge()"
        >
          <h6 class="mb-0 fw-600">USD ${{ balance }}</h6>
        </button>
      </div>

      <div class="dropdown d-none d-lg-inline-block ms-1">
        <button
          type="button"
          class="btn header-item noti-icon"
          data-toggle="fullscreen"
          (click)="fullscreen()"
        >
          <i class="bx bx-fullscreen"></i>
        </button>
      </div>

      <div
        class="dropdown d-inline-block"
        dropdown
        [autoClose]="false"
        *ngIf="default_chat"
        #dropdown="bs-dropdown"
      >
        <button
          type="button"
          class="btn header-item dropdown-toggle"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
          #menuButton
        >
          <span class="d-none d-sm-inline-block" dropdownToggle>
            {{ default_chat.name }}
            <i class="mdi mdi-chevron-down"></i>
          </span>
        </button>
        <div
          class="dropdown-menu dropdown-menu-end dropdown-menu-md"
          *dropdownMenu
          #menu
        >
          <a class="dropdown-item enablePointer">
            {{ default_chat?.name }}
            <div class="float-end ms-2">
              <i class="far fa-check-circle text-primary"></i>
            </div>
            <span *ngIf="default_chat.is_main == 1" class="float-end">
              Main
            </span>
          </a>

          <div *ngIf="chatList.length > 1">
            <div class="dropdown-divider"></div>
            <div
              class="search-box chat-search-box"
              style="margin-bottom: var(--bs-dropdown-divider-margin-y); padding: 0 var(--bs-dropdown-divider-margin-y);"
              id="searchBox"
            >
              <div class="position-relative">
                <input
                  type="text"
                  class="form-control"
                  placeholder="Search..."
                  id="searchChatsDrop"
                  autocomplete="off"
                  #search
                  (keyup)="channelsSearchs(search.value)"
                />
                <i class="bx bx-search-alt search-icon"></i>
              </div>
            </div>
            <ngx-simplebar
              style="position: relative; height: 190px"
              #scrollChatList
              name="scrollChatList"
            >
              <ng-container *ngFor="let chat of chatList">
                <a
                  *ngIf="chat.id != default_chat.id"
                  id="list"
                  class="dropdown-item enablePointer mb-2"
                  (click)="selectChat(chat.id)"
                >
                  {{ chat.name }}
                  <span *ngIf="chat.is_main == 1" class="float-end">Main</span>
                </a>
              </ng-container>
              <ng-container *ngIf="allChatsHidden">
                  <p class="m-0 text-muted text-center">
                    No results found
                  </p>
              </ng-container>
            </ngx-simplebar>
          </div>
        </div>
      </div>

      <div class="dropdown d-inline-block" dropdown>
        <button
          type="button"
          class="btn header-item noti-icon"
          id="page-header-notifications-dropdown"
          dropdownToggle
        >
          <i class="bx bx-bell bx-tada"></i>
          <!-- <span class="badge bg-danger rounded-pill">3</span> -->
        </button>
        <div
          class="dropdown-menu dropdown-menu-lg dropdown-menu-end p-0"
          aria-labelledby="page-header-notifications-dropdown"
          *dropdownMenu
        >
          <div class="p-3">
            <div class="row align-items-center">
              <div class="col">
                <h6 class="m-0">
                  {{ "HEADER.NOTIFICATIONS.TITLE" | translate }}
                </h6>
              </div>
              <div class="col-auto">
                <a href="javascript: void(0);" class="small">{{
                  "HEADER.NOTIFICATIONS.VIEW_ALL" | translate
                }}</a>
              </div>
            </div>
          </div>
          <ngx-simplebar style="position: relative; height: 230px">
            <a href="javascript: void(0);" class="text-reset notification-item">
              <div class="d-flex">
                <div class="avatar-xs me-3">
                  <span
                    class="avatar-title bg-primary rounded-circle font-size-16"
                  >
                    <i class="bx bx-cart"></i>
                  </span>
                </div>
                <div class="flex-grow-1">
                  <h6 class="mb-1">
                    {{ "HEADER.NOTIFICATIONS.FIRST.TITLE" | translate }}
                  </h6>
                  <div class="font-size-12 text-muted">
                    <p class="mb-1">
                      {{ "HEADER.NOTIFICATIONS.FIRST.TEXT" | translate }}
                    </p>
                    <p class="mb-0">
                      <i class="mdi mdi-clock-outline"></i>
                      {{ "HEADER.NOTIFICATIONS.FIRST.TIME" | translate }}
                    </p>
                  </div>
                </div>
              </div>
            </a>
            <a href="javascript: void(0);" class="text-reset notification-item">
              <div class="d-flex">
                <img
                  src="assets/images/users/avatar-3.jpg"
                  class="me-3 rounded-circle avatar-xs"
                  alt="user-pic"
                />
                <div class="flex-grow-1">
                  <h6 class="mb-1">
                    {{ "HEADER.NOTIFICATIONS.SECOND.TITLE" | translate }}
                  </h6>
                  <div class="font-size-12 text-muted">
                    <p class="mb-1">
                      {{ "HEADER.NOTIFICATIONS.SECOND.TEXT" | translate }}
                    </p>
                    <p class="mb-0">
                      <i class="mdi mdi-clock-outline"></i
                      >{{ "HEADER.NOTIFICATIONS.SECOND.TIME" | translate }}
                    </p>
                  </div>
                </div>
              </div>
            </a>
            <a href="javascript: void(0);" class="text-reset notification-item">
              <div class="d-flex">
                <div class="avatar-xs me-3">
                  <span
                    class="avatar-title bg-success rounded-circle font-size-16"
                  >
                    <i class="bx bx-badge-check"></i>
                  </span>
                </div>
                <div class="flex-grow-1">
                  <h6 class="mb-1">
                    {{ "HEADER.NOTIFICATIONS.THIRD.TITLE" | translate }}
                  </h6>
                  <div class="font-size-12 text-muted">
                    <p class="mb-1">
                      {{ "HEADER.NOTIFICATIONS.THIRD.TEXT" | translate }}
                    </p>
                    <p class="mb-0">
                      <i class="mdi mdi-clock-outline"></i
                      >{{ "HEADER.NOTIFICATIONS.THIRD.TIME" | translate }}
                    </p>
                  </div>
                </div>
              </div>
            </a>
            <a href="javascript: void(0);" class="text-reset notification-item">
              <div class="d-flex">
                <img
                  src="assets/images/users/avatar-4.jpg"
                  class="me-3 rounded-circle avatar-xs"
                  alt="user-pic"
                />
                <div class="flex-grow-1">
                  <h6 class="mb-1">
                    {{ "HEADER.NOTIFICATIONS.FOUR.TITLE" | translate }}
                  </h6>
                  <div class="font-size-12 text-muted">
                    <p class="mb-1">
                      {{ "HEADER.NOTIFICATIONS.FOUR.TEXT" | translate }}
                    </p>
                    <p class="mb-0">
                      <i class="mdi mdi-clock-outline"></i
                      >{{ "HEADER.NOTIFICATIONS.FOUR.TIME" | translate }}
                    </p>
                  </div>
                </div>
              </div>
            </a>
          </ngx-simplebar>
          <div class="p-2 border-top d-grid">
            <a
              class="btn btn-sm btn-link font-size-14 text-center"
              href="javascript:void(0)"
            >
              <i class="mdi mdi-arrow-right-circle me-1"></i>
              <span key="t-view-more">{{
                "HEADER.NOTIFICATIONS.LOAD_MORE" | translate
              }}</span>
            </a>
          </div>
        </div>
      </div>
      <div class="dropdown d-inline-block" dropdown>
        <button
          type="button"
          class="btn header-item"
          dropdownToggle
          id="page-header-user-dropdown"
        >
          <img
            class="rounded-circle header-profile-user"
            src="{{ profile_image }}"
            onerror="this.onerror=null; this.src='assets/images/users/account.webp'"
          />
          <span class="d-none d-xl-inline-block ms-1">{{ profile_name }}</span>
          <i class="mdi mdi-chevron-down d-none d-xl-inline-block"></i>
        </button>
        <div class="dropdown-menu dropdown-menu-end" *dropdownMenu>
          <a class="dropdown-item" routerLink="/account/profile"
            ><i class="bx bx-user font-size-16 align-middle me-1"></i>
            {{ "HEADER.LOGIN.PROFILE" | translate }}</a
          >
          <a class="dropdown-item enablePointer" (click)="routeRecharge()"
            ><i class="bx bx-wallet font-size-16 align-middle me-1"></i>
            {{ "HEADER.LOGIN.MY_WALLET" | translate }}</a
          >
          <a class="dropdown-item enablePointer" (click)="routeBillings()">
            <i class="bx bx-wallet font-size-16 align-middle me-1"></i>
            Billings
          </a>
          <div class="dropdown-divider"></div>
          <a
            class="dropdown-item text-danger"
            href="javascript: void(0);"
            (click)="logout()"
          >
            <i
              class="bx bx-power-off font-size-16 align-middle me-1 text-danger"
            ></i>
            {{ "HEADER.LOGIN.LOGOUT" | translate }}</a
          >
        </div>
      </div>

      <div class="dropdown d-inline-block">
        <button
          type="button"
          class="btn header-item noti-icon right-bar-toggle"
          (click)="toggleMode()"
        >
          <i class="bx bxs-moon"></i>
        </button>
      </div>

      <div class="dropdown d-inline-block">
        <button
          type="button"
          class="btn header-item noti-icon right-bar-toggle"
          (click)="toggleRightSidebar()"
        >
          <i class="bx bx-cog bx-spin"></i>
        </button>
      </div>
    </div>
  </div>
</header>
