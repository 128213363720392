import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from "@angular/core";
import { WebsocketService } from "src/app/core/services/websocket.service";
import { Router } from "@angular/router";
import {
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
} from "@angular/forms";
import { MiddlwareService } from "src/app/core/services/middleware.service";
import {
  AddBot,
  GetBotTemplates,
  GetUserList,
  ListCollectionItem,
} from "src/app/core/services/outbound";
import {
  user_role_color_map,
  user_role_map,
  user_search_placeholder_map,
  user_status_color_map,
  user_status_map,
} from "src/app/core/services/constants";
import { Bot } from "src/app/core/services/interface";

@Component({
  selector: "app-extensions-selector",
  templateUrl: "./extensions-selector.component.html",
  styleUrls: ["./extensions-selector.component.scss"],
  providers: [],
})

/**
 * Contacts domain-list component
 */
export class ExtensionsSelectorComponent implements OnInit, OnDestroy {
  @Input() mode = "list";
  @Input() single = false;
  @Input() selectedCollections = [];
  @Output() selectedNotifier = new EventEmitter<any>();
  @Output() cancelNotifier = new EventEmitter<boolean>(false);
  // bread crumb items
  breadCrumbItems: Array<{}>;
  page: Array<any> = [];
  eop = false;
  pageSize = 24;
  currentPage = 0;
  botsList: {};
  botListSubscription: any;
  monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "June",
    "July",
    "Aug",
    "Sept",
    "Oct",
    "Nov",
    "Dec",
  ];
  status_map = {};
  status_color_map = {};
  user_search_placeholder_map = {};
  user_role_map = {};
  user_role_color_map = {};
  payload = {};
  collectionListForm: UntypedFormGroup;
  submitted = false;
  dupe = false;
  criterionCount = 0;
  component_ref: any;
  search_message = "";
  countries = [];
  loading = false;
  verifySubscription: any;
  my_id: any;
  selectedCollectionsList = {};

  constructor(
    private ws: WebsocketService,
    private router: Router,
    private formBuilder: UntypedFormBuilder,
    private mdw: MiddlwareService,
    private ref: ChangeDetectorRef
  ) {}

  ngOnInit() {
    // console.log(this.router.url);
    this.status_map = user_status_map;
    this.status_color_map = user_status_color_map;
    this.user_role_map = user_role_map;
    this.user_role_color_map = user_role_color_map;
    this.user_search_placeholder_map = user_search_placeholder_map;

    // console.log(this.countries);
    this.component_ref = this.mdw.makeRef(16);
    this.currentPage = 1;
    this.botsList = {};
    // console.log(Object.keys(this.userList).length);

    this.collectionListForm = this.formBuilder.group({
      chat_id: new UntypedFormControl(null),
      created_date: new UntypedFormControl(null),
      search_type: new UntypedFormControl("name"),
      search_value: new UntypedFormControl(null),
    });

    this.botListSubscription = this.ws.botTemplatesContainer$.subscribe(
      (listtemplatesData) => {
        if (listtemplatesData) {
          this.loading = false;
          this.page = [];
          if (listtemplatesData.bot_templates.length == 0) {
            this.search_message = "No results matched your search criteria";
          }

          listtemplatesData.bot_templates.forEach((template: any) => {
            this.botsList[String(template.user_id)] = template;
          });
          this.page = Object.values(this.botsList);
        }
      }
    );

    this.verifySubscription = this.ws.verifyContainer$.subscribe(
      (verifyData) => {
        // console.log("verifyData"+ verifyData);
        if (verifyData) {
          this.loading = true;
          this.ws.processMethod(new GetBotTemplates(this.component_ref));
        }
      }
    );

    if (this.selectedCollections && this.selectedCollections.length > 0) {
      for (let i = 0; this.selectedCollections.length > i; i++) {
        this.selectedCollectionsList[this.selectedCollections[i].id] =
          this.selectedCollections[i];
      }
    }

    this.breadCrumbItems = [
      { label: "MENUITEMS.STOREMANGER.TEXT" },
      { label: "COLLECTIONS.COLLECTIONS", active: true },
    ];
  }

  openDetails(data: any) {
    this.router.navigate([
      "/" +
        data.account_id +
        "/" +
        data.ref_domain +
        "/" +
        data.service.product,
    ]);
  }

  // convenience getter for easy access to form fields
  get f() {
    return this.collectionListForm.controls;
  }

  onSubmit() {}

  checkUncheckAll(ev: any) {}

  editCollection(data) {
    if (this.single) {
      if (this.selectedCollectionsList[data.user_id]) {
        delete this.selectedCollectionsList[data.user_id];
      } else {
        this.selectedCollectionsList = {};
        this.selectedCollectionsList[data.user_id] = data;
      }
    } else {
      if (this.selectedCollectionsList[data.user_id]) {
        delete this.selectedCollectionsList[data.user_id];
      } else {
        this.selectedCollectionsList[data.user_id] = data;
      }
    }
  }

  insertEvent() {
    const selected: any = Object.values(this.selectedCollectionsList);
    console.log(selected);
    // this.selectedNotifier.emit(selected);

    this.ws.processMethod(new AddBot(selected[0].user_id, this.component_ref));
    this.cancelEvent();
  }

  cancelEvent() {
    this.cancelNotifier.emit(true);
  }

  isObjEmpty() {
    return Object.keys(this.selectedCollectionsList).length === 0;
  }

  isSelected(id: string) {
    return this.selectedCollectionsList[id] ? true : false;
  }

  ngOnDestroy(): void {
    // console.log("destroy");
    if (this.botListSubscription) {
      this.botListSubscription.unsubscribe();
    }
    if (this.verifySubscription) {
      this.verifySubscription.unsubscribe();
    }
  }
}
