<div class="card m-0 bg-transparent">
  <div class="card-body">
    <div class="row d-flex justify-content-between align-items-center mb-4">
      <div class="col-10 d-flex align-items-center">
        <h4
          class="card-title m-0 offcanvas-header-text d-flex align-items-center"
        >
          {{ "GENERALS.ENABLECONTEXTMENU" | translate }}
          <i
            class="mdi mdi-information-outline font-size-16 ms-1"
            data-bs-toggle="tooltip"
            data-bs-placement="top"
            title="You can show or hide the foooter's component at the bottom of your Channel Posts"
          ></i>
        </h4>
      </div>
      <div class="col-2 d-flex justify-content-end">
        <ui-switch
          [color]="offcanvasColors.activeColor"
          [switchColor]="offcanvasColors.switchColor"
          [defaultBgColor]="offcanvasColors.bgColor"
          [defaultBoColor]="offcanvasColors.borderColor"
          [checkedTextColor]="offcanvasColors.activeTextColor"
          [uncheckedTextColor]="offcanvasColors.offTextColor"
          uncheckedLabel="Off"
          [checked]="enableContextMenu"
          checkedLabel="On"
          size="small"
          class="me-1"
          (change)="toggleContextMenu($event)"
        ></ui-switch>
      </div>
    </div>
    <!-- <ng-container *ngFor="let item of tab?.footer?.action"> -->
    <div class="row d-flex justify-content-between align-items-center mb-3">
      <div class="col-6">
        <div class="form-check d-flex align-items-center">
          <input
            class="form-check-input offcanvas-border-primary offcanvas-primary me-2"
            type="checkbox"
            id="formLike"
            [checked]="shareEnabled"
            (change)="enableCheckbox($event, 'share')"
            [disabled]="!enableContextMenu"
            style="width: 18px; height: 18px"
          />
          <label class="offcanvas-header-text w-100 m-0" for="formLike">
            Share
            <i
              class="mdi mdi-information-outline font-size-16 ms-1"
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              title="You can show or hide the foooter's component at the bottom of your Channel Posts"
            ></i>
          </label>
        </div>
      </div>
      <div class="col-6 d-flex justify-content-end">
        <div
          class="btn-group m-0 offcanvas-border-primary border rounded-2 w-50"
        >
          <button
            type="button"
            [disabled]="!shareEnabled || !enableContextMenu"
            class="btn offcanvas-primary font-size-18 d-flex align-items-center justify-content-center"
            (click)="openSelector(selectorContext, 'share')"
          >
            <i
              class="fs-4"
              [class]="
                shareIcon?.type == 'mir'
                  ? 'material-icons-outlined'
                  : 'material-icons'
              "
              >{{ shareIcon?.id }}</i
            >
          </button>
          <button
            type="button"
            [disabled]="!shareEnabled || !enableContextMenu"
            class="btn offcanvas-primary dropdown-toggle dropdown-toggle-split border-0"
            (click)="openSelector(selectorContext, 'share')"
          >
            <i class="mdi mdi-chevron-down"></i>
          </button>
        </div>
      </div>
    </div>

    <div class="row d-flex justify-content-between align-items-center mb-3">
      <div class="col-6">
        <div class="form-check d-flex align-items-center">
          <input
            class="form-check-input offcanvas-border-primary offcanvas-primary me-2"
            type="checkbox"
            id="formReply"
            [checked]="forwardEnabled"
            [disabled]="!enableContextMenu"
            (change)="enableCheckbox($event, 'forward')"
            style="width: 18px; height: 18px"
          />
          <label class="offcanvas-header-text w-100 m-0" for="formReply">
            Forward
            <i
              class="mdi mdi-information-outline font-size-16 ms-1"
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              title="You can show or hide the foooter's component at the bottom of your Channel Posts"
            ></i>
          </label>
        </div>
      </div>

      <div class="col-6 d-flex justify-content-end">
        <div
          class="btn-group m-0 offcanvas-border-primary border rounded-2 w-50"
        >
          <button
            type="button"
            [disabled]="!forwardEnabled || !enableContextMenu"
            class="btn offcanvas-primary font-size-18 d-flex align-items-center justify-content-center"
            (click)="openSelector(selectorContext, 'forward')"
          >
            <i
              class="fs-4"
              [class]="
                forwardIcon?.type == 'mir'
                  ? 'material-icons-outlined'
                  : 'material-icons'
              "
              >{{ forwardIcon?.id }}</i
            >
          </button>
          <button
            type="button"
            [disabled]="!forwardEnabled || !enableContextMenu"
            class="btn offcanvas-primary dropdown-toggle dropdown-toggle-split border-0"
            (click)="openSelector(selectorContext, 'forward')"
          >
            <i class="mdi mdi-chevron-down"></i>
          </button>
        </div>
      </div>
    </div>
    <div class="row d-flex justify-content-between align-items-center mb-3">
      <div class="col-6">
        <div class="form-check d-flex align-items-center">
          <input
            class="form-check-input offcanvas-border-primary offcanvas-primary me-2"
            type="checkbox"
            id="formView"
            [checked]="copyEnabled"
            [disabled]="!enableContextMenu"
            (change)="enableCheckbox($event, 'copy')"
            style="width: 18px; height: 18px"
          />
          <label class="offcanvas-header-text w-100 m-0" for="formView">
            Copy
            <i
              class="mdi mdi-information-outline font-size-16 ms-1"
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              title="You can show or hide the foooter's component at the bottom of your Channel Posts"
            ></i>
          </label>
        </div>
      </div>

      <div class="col-6 d-flex justify-content-end">
        <div
          class="btn-group m-0 offcanvas-border-primary border rounded-2 w-50"
        >
          <button
            type="button"
            [disabled]="!copyEnabled || !enableContextMenu"
            class="btn offcanvas-primary font-size-18 d-flex align-items-center justify-content-center"
            (click)="openSelector(selectorContext, 'copy')"
          >
            <i
              class="fs-4"
              [class]="
                copyIcon?.type == 'mir'
                  ? 'material-icons-outlined'
                  : 'material-icons'
              "
              >{{ copyIcon?.id }}</i
            >
          </button>
          <button
            type="button"
            [disabled]="!copyEnabled || !enableContextMenu"
            class="btn offcanvas-primary dropdown-toggle dropdown-toggle-split border-0"
            (click)="openSelector(selectorContext, 'copy')"
          >
            <i class="mdi mdi-chevron-down"></i>
          </button>
        </div>
      </div>
    </div>
    <div class="row d-flex justify-content-between align-items-center mb-3">
      <div class="col-6">
        <div class="form-check d-flex align-items-center">
          <input
            class="form-check-input offcanvas-border-primary offcanvas-primary me-2"
            type="checkbox"
            id="formShare"
            [checked]="editEnabled"
            [disabled]="!enableContextMenu"
            (change)="enableCheckbox($event, 'edit')"
            style="width: 18px; height: 18px"
          />
          <label class="offcanvas-header-text w-100 m-0" for="formShare">
            Edit
            <i
              class="mdi mdi-information-outline font-size-16 ms-1"
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              title="You can show or hide the foooter's component at the bottom of your Channel Posts"
            ></i>
          </label>
        </div>
      </div>

      <div class="col-6 d-flex justify-content-end">
        <div
          class="btn-group m-0 offcanvas-border-primary border rounded-2 w-50"
        >
          <button
            type="button"
            [disabled]="!editEnabled || !enableContextMenu"
            class="btn offcanvas-primary font-size-18 d-flex align-items-center justify-content-center"
            (click)="openSelector(selectorContext, 'edit')"
          >
            <i
              class="fs-4"
              [class]="
                editIcon?.type == 'mir'
                  ? 'material-icons-outlined'
                  : 'material-icons'
              "
              >{{ editIcon?.id }}</i
            >
          </button>
          <button
            type="button"
            [disabled]="!editEnabled || !enableContextMenu"
            class="btn offcanvas-primary dropdown-toggle dropdown-toggle-split border-0"
            (click)="openSelector(selectorContext, 'edit')"
          >
            <i class="mdi mdi-chevron-down"></i>
          </button>
        </div>
      </div>
    </div>
    <div class="row d-flex justify-content-between align-items-center mb-3">
      <div class="col-6">
        <div class="form-check d-flex align-items-center">
          <input
            class="form-check-input offcanvas-border-primary offcanvas-primary me-2"
            type="checkbox"
            id="formShare"
            [checked]="saveGalleryEnabled"
            [disabled]="!enableContextMenu"
            (change)="enableCheckbox($event, 'save_to_gallery')"
            style="width: 18px; height: 18px"
          />
          <label class="offcanvas-header-text w-100 m-0" for="formShare">
            Save to Gallery
            <i
              class="mdi mdi-information-outline font-size-16 ms-1"
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              title="You can show or hide the foooter's component at the bottom of your Channel Posts"
            ></i>
          </label>
        </div>
      </div>

      <div class="col-6 d-flex justify-content-end">
        <div
          class="btn-group m-0 offcanvas-border-primary border rounded-2 w-50"
        >
          <button
            type="button"
            [disabled]="!saveGalleryEnabled || !enableContextMenu"
            class="btn offcanvas-primary font-size-18 d-flex align-items-center justify-content-center"
            (click)="openSelector(selectorContext, 'save_to_gallery')"
          >
            <i
              class="fs-4"
              [class]="
                saveGalleryIcon?.type == 'mir'
                  ? 'material-icons-outlined'
                  : 'material-icons'
              "
              >{{ saveGalleryIcon?.id }}</i
            >
          </button>
          <button
            type="button"
            [disabled]="!saveGalleryEnabled || !enableContextMenu"
            class="btn offcanvas-primary dropdown-toggle dropdown-toggle-split border-0"
            (click)="openSelector(selectorContext, 'save_to_gallery')"
          >
            <i class="mdi mdi-chevron-down"></i>
          </button>
        </div>
      </div>
    </div>
    <div class="row d-flex justify-content-between align-items-center mb-3">
      <div class="col-6">
        <div class="form-check d-flex align-items-center">
          <input
            class="form-check-input offcanvas-border-primary offcanvas-primary me-2"
            type="checkbox"
            id="formShare"
            [checked]="saveMusicEnabled"
            [disabled]="!enableContextMenu"
            (change)="enableCheckbox($event, 'save_to_music')"
            style="width: 18px; height: 18px"
          />
          <label class="offcanvas-header-text w-100 m-0" for="formShare">
            Save to Music
            <i
              class="mdi mdi-information-outline font-size-16 ms-1"
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              title="You can show or hide the foooter's component at the bottom of your Channel Posts"
            ></i>
          </label>
        </div>
      </div>

      <div class="col-6 d-flex justify-content-end">
        <div
          class="btn-group m-0 offcanvas-border-primary border rounded-2 w-50"
        >
          <button
            type="button"
            [disabled]="!saveMusicEnabled || !enableContextMenu"
            class="btn offcanvas-primary font-size-18 d-flex align-items-center justify-content-center"
            (click)="openSelector(selectorContext, 'save_to_music')"
          >
            <i
              class="fs-4"
              [class]="
                saveMusicIcon?.type == 'mir'
                  ? 'material-icons-outlined'
                  : 'material-icons'
              "
              >{{ saveMusicIcon?.id }}</i
            >
          </button>
          <button
            type="button"
            [disabled]="!saveMusicEnabled || !enableContextMenu"
            class="btn offcanvas-primary dropdown-toggle dropdown-toggle-split border-0"
            (click)="openSelector(selectorContext, 'save_to_music')"
          >
            <i class="mdi mdi-chevron-down"></i>
          </button>
        </div>
      </div>
    </div>
    <div class="row d-flex justify-content-between align-items-center mb-3">
      <div class="col-7">
        <div class="form-check d-flex align-items-center">
          <input
            class="form-check-input offcanvas-border-primary offcanvas-primary me-2"
            type="checkbox"
            id="formShare"
            [checked]="saveDownloadEnabled"
            [disabled]="!enableContextMenu"
            (change)="enableCheckbox($event, 'share')"
            style="width: 18px; height: 18px"
          />
          <label class="offcanvas-header-text w-100 m-0" for="formShare">
            Save to Downloads
            <i
              class="mdi mdi-information-outline font-size-16 ms-1"
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              title="You can show or hide the foooter's component at the bottom of your Channel Posts"
            ></i>
          </label>
        </div>
      </div>

      <div class="col-5 d-flex justify-content-end">
        <div
          class="btn-group m-0 offcanvas-border-primary border rounded-2 w-50"
        >
          <button
            type="button"
            [disabled]="!saveDownloadEnabled || !enableContextMenu"
            class="btn offcanvas-primary font-size-18 d-flex align-items-center justify-content-center"
            (click)="openSelector(selectorContext, 'save_to_downloads')"
          >
            <i
              style="max-width: 100%"
              class="fs-4"
              [class]="
                saveDownloadIcon?.type == 'mir'
                  ? 'material-icons-outlined'
                  : 'material-icons'
              "
              >{{ saveDownloadIcon?.id }}</i
            >
          </button>
          <button
            type="button"
            [disabled]="!saveDownloadEnabled || !enableContextMenu"
            class="btn offcanvas-primary dropdown-toggle dropdown-toggle-split border-0"
            (click)="openSelector(selectorContext, 'save_to_downloads')"
          >
            <i class="mdi mdi-chevron-down"></i>
          </button>
        </div>
      </div>
    </div>
    <div class="row d-flex justify-content-between align-items-center mb-3">
      <div class="col-6">
        <div class="form-check d-flex align-items-center">
          <input
            class="form-check-input offcanvas-border-primary offcanvas-primary me-2"
            type="checkbox"
            id="formShare"
            [checked]="reportEnabled"
            [disabled]="!enableContextMenu"
            (change)="enableCheckbox($event, 'abuse')"
            style="width: 18px; height: 18px"
          />
          <label class="offcanvas-header-text w-100 m-0" for="formShare">
            Report abuse
            <i
              class="mdi mdi-information-outline font-size-16 ms-1"
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              title="You can show or hide the foooter's component at the bottom of your Channel Posts"
            ></i>
          </label>
        </div>
      </div>

      <div class="col-6 d-flex justify-content-end">
        <div
          class="btn-group m-0 offcanvas-border-primary border rounded-2 w-50"
        >
          <button
            type="button"
            [disabled]="!reportEnabled || !enableContextMenu"
            class="btn offcanvas-primary font-size-18 d-flex align-items-center justify-content-center"
            (click)="openSelector(selectorContext, 'abuse')"
          >
            <i
              class="fs-4"
              [class]="
                reportIcon?.type == 'mir'
                  ? 'material-icons-outlined'
                  : 'material-icons'
              "
              >{{ reportIcon?.id }}</i
            >
          </button>
          <button
            type="button"
            [disabled]="!reportEnabled || !enableContextMenu"
            class="btn offcanvas-primary dropdown-toggle dropdown-toggle-split border-0"
            (click)="openSelector(selectorContext, 'abuse')"
          >
            <i class="mdi mdi-chevron-down"></i>
          </button>
        </div>
      </div>
    </div>
    <div class="row d-flex justify-content-between align-items-center mb-3">
      <div class="col-6">
        <div class="form-check d-flex align-items-center">
          <input
            class="form-check-input offcanvas-border-primary offcanvas-primary me-2"
            type="checkbox"

            [checked]="deleteEnabled"
            [disabled]="true"
            style="width: 18px; height: 18px;"
          />
          <label class="offcanvas-header-text w-100 m-0" >
            Delete
            <i
              class="mdi mdi-information-outline font-size-16 ms-1"
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              title="You can show or hide the foooter's component at the bottom of your Channel Posts"
            ></i>
          </label>
        </div>
      </div>

      <div class="col-6 d-flex justify-content-end">
        <div
        class="btn-group m-0 offcanvas-border-primary border rounded-2 w-50"
      >
        <button
          type="button"
          [disabled]="!deleteEnabled || !enableContextMenu"
          class="btn offcanvas-primary font-size-18 d-flex align-items-center justify-content-center w-50"  (click)="openSelector(selectorContext,'delete')">
          <i style="max-width: 100%;" class= "fs-4" [class]="deleteIcon?.type =='mir'? 'material-icons-outlined': 'material-icons'">{{deleteIcon?.id}}</i>

        </button>
        <button
          type="button"
          [disabled]="!deleteEnabled || !enableContextMenu"
          class="btn offcanvas-primary dropdown-toggle dropdown-toggle-split border-0" (click)="openSelector(selectorContext, 'delete')" >
          <i class="mdi mdi-chevron-down"></i>

        </button>
      </div>

    </div>
    </div>
    <!-- </ng-container> -->
  </div>
</div>
<ng-template #selectorContext let-modal>
  <div class="modal-header">
    <h5 class="modal-title" id="selector">
      Choose {{ selectedIconListTitle }} Icon
    </h5>
    <button
      type="button"
      class="btn-close"
      data-bs-dismiss="modal"
      aria-label="Close"
      (click)="modalRef?.hide()"
    ></button>
  </div>
  <div class="modal-body">
    <app-icons-selector
      (selectedIcon)="changeIcon($event)"
    ></app-icons-selector>
  </div>
</ng-template>
