<div class="card m-0 bg-transparent">
  <div class="card-body">
    <div
      class="row d-flex align-items-center justify-content-between align-items-center mb-3"
    >
      <div class="col-6">
        <label
          for="text-color"
          class="offcanvas-header-text w-100"
          style="text-align: left"
        >
          {{ "GENERALS.SELECTBOT" | translate }}
        </label>
        <div
          class="input-group offcanvas-border-primary border rounded-2"
          title="Using format option"
        >
          <input
            type="text"
            id="text-color"
            class="form-control input-lg offcanvas-primary border-0"
            [value]="chatId"
            disabled
            readOnly
          />
        </div>
      </div>
      <div class="col-6 d-flex align-items-center justify-content-end">
        <button
          type="button"
          class="btn offcanvas-primary dropdown-toggle dropdown-toggle-split border-0"
          (click)="openUsersSelector(botsSelector)"
        >
          {{ "GENERALS.SET" | translate }}
        </button>
      </div>
    </div>
  </div>
</div>

<ng-template #botsSelector let-modal>
  <div class="modal-header">
    <h5 class="modal-title" id="selector">{{ "USERS.ADDROLE" | translate }}</h5>
    <button
      type="button"
      class="btn-close"
      data-bs-dismiss="modal"
      aria-label="Close"
      (click)="modalRef.hide()"
    ></button>
  </div>
  <div class="modal-body p-0">
    <app-bots-selector
      [single]="true"
      [chatID]="mainChat"
      [selectedBots]="selectedBots"
      (selectedNotifier)="selectedUsersf($event)"
      (cancelNotifier)="modalRef.hide()"
    ></app-bots-selector>
  </div>
</ng-template>
