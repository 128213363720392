import { Pipe, PipeTransform } from "@angular/core";
import anchorme from "anchorme";

@Pipe({
  name: "multiLine",
})
export class MultiLinePipe implements PipeTransform {
  transform(value: string, numberOfLine?: number, bg?: boolean): string {
    if (value) {
      if (!numberOfLine) {
        if (value.length > 0) {
          return anchorme({
            input: value,
            // use some options
            options: {
              attributes: (string) => {
                const attributes = {
                  target: "_blank",
                };
                if (bg) {
                  attributes["class"] = "link-light text-decoration-underline";
                } else {
                  attributes["class"] = "link-primary text-decoration-underline";
                }

                return attributes;
              },
              exclude: (urlObj) => {
                // urlObj.encoded = urlObj.encoded.replace(/%25/g, '%');
                return false;
              },
            },
          }).replace(/\n/g, "<br>");
        }
      } else if (numberOfLine > 0) {
        if (value.length > 0) {
          const stringWithNewLineTag = value
            .replace(/\n/g, "<br>")
            .split("<br>");
          const numberOfLines = stringWithNewLineTag.length;
          if (numberOfLines > 1) {
            return `${stringWithNewLineTag[numberOfLine - 1]} ...`;
          }
          return `${stringWithNewLineTag[numberOfLine - 1]}`;
        }
      }
    }
    return "";
  }
}
